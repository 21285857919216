<template>
  <div class="container">
    <article>
      <h1>Instructions for signing your Power of Attorney - Financial (SA)</h1>
      <p>
        Thank you for choosing Safewill. Your Enduring Power of Attorney is almost complete - follow
        the steps below, provided by our legal advisers, to sign your Power of Attorney and have it
        witnessed.
      </p>
      <h4><em>Step 1 – Print your Enduring Power of Attorney</em></h4>
      <p>
        Print your Enduring Power of Attorney, read it carefully and ensure that you understand it
        completely. If you do not understand what you are signing, we recommend seeking independent
        legal advice.
      </p>
      <h4><em>Step 2 – Find a prescribed witness</em></h4>
      <p>
        Once you have understood your Enduring Power of Attorney, you will need to find a prescribed
        witness to sign the document and witness your signing of it. The Enduring Power of Attorney
        can only be witnessed by someone who you have not appointed as a donee and who is authorised
        by law to take Affidavits, including a:
      </p>
      <ul>
        <li>justice of the peace in South Australia; or</li>
        <li>a barrister or solicitor of the South Australian Supreme Court.</li>
      </ul>
      <h4><em>Step 3 – Signing your Enduring Power of Attorney</em></h4>
      <p>
        Sign using a blue or black pen. You should first sign the Enduring Power of Attorney in the
        presence of your witness. Make sure your witness is watching you sign and date the document
        before signing themselves. Signing the bottom of each page of the document is not mandatory,
        however we recommend doing so to protect against pages being substituted. After this, the
        document should be provided to your donees to accept their appointment as indicated in the
        document.
      </p>
      <h4><em>Step 4 – Keep your Enduring Power of Attorney safe</em></h4>
      <p>
        It is important to store your Enduring Power of Attorney in a safe place and provide your
        donee with a copy. You should also keep a copy with someone you trust, like your solicitor
        or accountant.
      </p>
      <h4><em>Note on Revocation</em></h4>
      <p>
        In South Australia, signing a new Enduring Power of Attorney does not automatically revoke
        an old Enduring Power of Attorney. As such, <strong>if you:</strong>
      </p>
      <ul>
        <li>
          have an existing Enduring Power of Attorney that you would like to revoke before making
          this Enduring Power of Attorney; <strong>or</strong>
        </li>
        <li>
          would like to change anything in this Enduring Power of Attorney document after it is
          signed;
        </li>
      </ul>
      <p>
        please follow the instructions on revocation included at the end of this Enduring Power of
        Attorney. Please also note that an Enduring Power of Attorney can only be revoked by the
        Donor while they have mental capacity.
      </p>
    </article>
    <AppointmentOfAttorneys />
    <article>
      <h2>Revocation of Existing Enduring Power of Attorney</h2>
      <p>
        If you would like to revoke your existing Enduring Power of Attorney, please follow these
        steps.
      </p>
      <h4><em>Step 1 - Find a witness</em></h4>
      <p>
        You (Donor) will need to find one person over the age of 18 to witness and sign the
        Revocation of Power of Attorney and witness your signing of it.
      </p>
      <p>The witness must be an independent adult.</p>
      <h4><em>Step 2 - Complete and sign the Revocation of Power of Attorney </em></h4>
      <p>
        A Revocation form can be found here:<br />
        <a href="https://landservices.com.au/__data/assets/word_doc/0012/5232/RP-Form_version-4.doc"
          >https://landservices.com.au/__data/assets/word_doc/0012/5232/RP-Form_version-4.doc</a
        >
      </p>
      <p>
        You must sign the Revocation of Power of Attorney in the presence of your witness. Make sure
        your witness is watching you sign and date the document. After this your witness must also
        sign where indicated.
      </p>
      <h4><em>Step 3 - Give written notice of the Revocation </em></h4>
      <p>
        After you have signed your Revocation of the Enduring Power of Attorney and it has been
        witnessed, you must, as soon as practicable, give notice of the Revocation. You can do this
        by writing a letter enclosing a copy of the completed and signed Revocation to:
      </p>
      <ul>
        <li>all of your attorney(s) whose appointment has been revoked; and</li>
        <li>anyone else who may be aware of or has dealings with the revoked Power of Attorney.</li>
      </ul>
      <p>
        If you fail to give notice, your attorney(s) may legally continue to make decisions on your
        behalf under the old power of attorney.
      </p>
      <h4><em>Step 4 - Registration</em></h4>
      <p>To finalise the revocation, <strong>you must lodge</strong>, at Land Services SA:</p>
      <ol>
        <li>the signed Revocation Form; and</li>
        <li>a copy of the existing Power of Attorney to be revoked as supporting documentation.</li>
      </ol>
      <p>
        To find the location of Land Services SA for lodging, please visit the Land Services SA
        website: <a href="https://landservices.com.au/">https://landservices.com.au/</a>
      </p>
      <p>Please note there may be fees associated with the lodgement.</p>
      <p>
        You should keep a copy of the completed and signed Revocation for your own record keeping
        purposes.
      </p>
    </article>
  </div>
</template>

<script lang="ts">
import AppointmentOfAttorneys from './components/financial/AppointmentOfAttorneys.vue';

export default {
  components: {
    AppointmentOfAttorneys,
  },
};
</script>
