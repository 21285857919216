import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { type: "1" }
const _hoisted_2 = { type: "a" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 2 }
const _hoisted_6 = { type: "a" }
const _hoisted_7 = ["start"]
const _hoisted_8 = ["start"]
const _hoisted_9 = {
  key: 0,
  type: "a"
}
const _hoisted_10 = { key: 1 }
const _hoisted_11 = ["start"]
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { key: 1 }
const _hoisted_14 = ["start"]
const _hoisted_15 = { class: "sign-container" }
const _hoisted_16 = { class: "sign-prefixed" }
const _hoisted_17 = { class: "sign-prefix" }
const _hoisted_18 = { class: "sign-prefixed" }
const _hoisted_19 = { class: "sign-prefix bold" }
const _hoisted_20 = { class: "sign" }
const _hoisted_21 = { class: "sign" }
const _hoisted_22 = {
  key: 0,
  class: "sign-container"
}
const _hoisted_23 = { class: "sign-prefixed" }
const _hoisted_24 = { class: "sign-prefix bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("article", null, [
    _createElementVNode("section", null, [
      _cache[2] || (_cache[2] = _createElementVNode("h3", null, "APPOINTMENT OF ATTORNEYS", -1)),
      _createElementVNode("ol", _hoisted_1, [
        _createElementVNode("li", null, [
          _cache[0] || (_cache[0] = _createTextVNode(" I, ")),
          _createElementVNode("strong", null, _toDisplayString($setup.ownerPerson?.fullName), 1),
          _createTextVNode(" " + _toDisplayString($setup.ownerPerson?.fullAddress.trim()) + ", ", 1),
          _cache[1] || (_cache[1] = _createElementVNode("strong", null, "appoint", -1)),
          _createTextVNode(" the following " + _toDisplayString($options.ifPlural('people', 'person', $setup.primaryAttorneys)) + " to be my " + _toDisplayString($options.plural('attorney', $setup.primaryAttorneys)) + " ", 1),
          _createElementVNode("ol", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.primaryAttorneys, (attorney) => {
              return (_openBlock(), _createElementBlock("li", {
                key: attorney.id
              }, [
                _createElementVNode("strong", null, _toDisplayString(attorney.directoryPerson.fullName), 1),
                _createTextVNode(" " + _toDisplayString(attorney.directoryPerson.identifiers), 1)
              ]))
            }), 128))
          ])
        ]),
        ($setup.hasMultipleAttorneys)
          ? (_openBlock(), _createElementBlock("li", _hoisted_3, " I would like my " + _toDisplayString($options.plural('attorney', $setup.primaryAttorneys)) + " to act " + _toDisplayString($options.mf('attorney_decisions', $setup.poa?.meta)) + ". ", 1))
          : _createCommentVNode("", true),
        ($setup.hasMultipleAttorneys && $setup.actJointly)
          ? (_openBlock(), _createElementBlock("li", _hoisted_4, " I " + _toDisplayString($setup.othersMayAct ? 'do not want' : 'want') + " the appointment to be terminated if one of my joint attorneys dies, resigns, or otherwise Vacates Office. ", 1))
          : _createCommentVNode("", true),
        ($setup.hasSecondaries)
          ? (_openBlock(), _createElementBlock("li", _hoisted_5, [
              _createTextVNode(" If the appointment of " + _toDisplayString($options.ifPlural('all of my attorneys', 'my attorney', $setup.secondaryAttorneys)) + " has terminated (due to death, resignation or other Vacation of Office), I appoint the following person to be my substitute attorney: ", 1),
              _createElementVNode("ol", _hoisted_6, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.secondaryAttorneys, (attorney) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: attorney.id
                  }, [
                    _createElementVNode("strong", null, _toDisplayString(attorney.directoryPerson.fullName), 1),
                    _createTextVNode(" " + _toDisplayString(attorney.directoryPerson.identifiers), 1)
                  ]))
                }), 128))
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("section", null, [
      _cache[4] || (_cache[4] = _createElementVNode("h2", null, "POWERS", -1)),
      _createElementVNode("ol", {
        type: "1",
        start: 2 + $setup.hasMultipleAttorneys + ($setup.hasMultipleAttorneys && $setup.actJointly) + $setup.hasSecondaries
      }, _cache[3] || (_cache[3] = [
        _createElementVNode("li", null, [
          _createElementVNode("p", null, [
            _createTextVNode(" My Attorney(s) may exercise the authority conferred by Part 2 of the "),
            _createElementVNode("em", null, "Powers of Attorney Act 2003"),
            _createTextVNode(" (NSW) to do anything on my behalf I may lawfully authorise an attorney to do. ")
          ]),
          _createElementVNode("p", null, " I give this power of attorney with the intention that it will continue to be effective if I lack capacity through loss of mental capacity after its execution. ")
        ], -1)
      ]), 8, _hoisted_7)
    ]),
    _createElementVNode("section", null, [
      _cache[7] || (_cache[7] = _createElementVNode("h2", null, "ADDITIONAL POWERS", -1)),
      _createElementVNode("ol", {
        type: "1",
        start: 3 + $setup.hasMultipleAttorneys + ($setup.hasMultipleAttorneys && $setup.actJointly) + $setup.hasSecondaries
      }, [
        _createElementVNode("li", null, [
          _cache[6] || (_cache[6] = _createTextVNode(" I authorise my attorney to confer benefits on the following persons to meet their reasonable living and medical expenses as provided by section 13 (2) of the Powers of Attorney Act 2003: ")),
          ($setup.hasInclusions)
            ? (_openBlock(), _createElementBlock("ol", _hoisted_9, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.inclusions, (inclusion) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: inclusion.id
                  }, [
                    _createElementVNode("strong", null, _toDisplayString(inclusion.directoryPerson.fullName), 1),
                    _createTextVNode(" " + _toDisplayString(inclusion.directoryPerson.identifiers), 1)
                  ]))
                }), 128))
              ]))
            : (_openBlock(), _createElementBlock("ul", _hoisted_10, _cache[5] || (_cache[5] = [
                _createElementVNode("li", null, "NIL", -1)
              ])))
        ])
      ], 8, _hoisted_8)
    ]),
    _createElementVNode("section", null, [
      _cache[9] || (_cache[9] = _createElementVNode("h2", null, "CONDITIONS AND LIMITATIONS", -1)),
      _createElementVNode("ol", {
        start: 4 + $setup.hasMultipleAttorneys + ($setup.hasMultipleAttorneys && $setup.actJointly) + $setup.hasSecondaries
      }, [
        _createElementVNode("li", null, [
          _cache[8] || (_cache[8] = _createTextVNode(" I place the following conditions and/or limitations on the authority of my attorney: ")),
          _createElementVNode("ul", null, [
            ($setup.hasLimitations)
              ? (_openBlock(), _createElementBlock("li", _hoisted_12, _toDisplayString($options.mf('limitations', $setup.poa?.meta)), 1))
              : (_openBlock(), _createElementBlock("li", _hoisted_13, "NIL"))
          ])
        ])
      ], 8, _hoisted_11)
    ]),
    _createElementVNode("section", null, [
      _cache[11] || (_cache[11] = _createElementVNode("h2", null, "COMMENCEMENT", -1)),
      _createElementVNode("ol", {
        start: 5 + $setup.hasMultipleAttorneys + ($setup.hasMultipleAttorneys && $setup.actJointly) + $setup.hasSecondaries
      }, _cache[10] || (_cache[10] = [
        _createElementVNode("li", null, " This Enduring Power of Attorney operates once a medical practitioner considers that I am unable to manage my affairs. ", -1)
      ]), 8, _hoisted_14)
    ]),
    _createElementVNode("section", null, [
      _cache[14] || (_cache[14] = _createElementVNode("h2", null, "ATTESTATION", -1)),
      _createElementVNode("div", _hoisted_15, [
        _createElementVNode("div", _hoisted_16, [
          _createElementVNode("div", _hoisted_17, _toDisplayString($setup.ownerPerson?.fullName), 1),
          _cache[12] || (_cache[12] = _createElementVNode("div", { class: "sign" }, "Name", -1))
        ]),
        _cache[13] || (_cache[13] = _createStaticVNode("<div class=\"sign\">Signature</div><div class=\"sign\">Date</div><div class=\"sign\">Witness Name</div><div class=\"sign\">Witness Address</div><div class=\"sign\">Witness Signature</div>", 5))
      ])
    ]),
    _cache[22] || (_cache[22] = _createStaticVNode("<section><h4>Certificate under section 19 of the Powers of Attorney Act 2003</h4><div class=\"sign-container\"><div class=\"sign-prefixed\"><div class=\"sign-prefix\">I,</div><div class=\"sign\">Witness Full Name</div></div><div class=\"sign-prefixed\"><div class=\"sign-prefix\">of</div><div class=\"sign\"> </div><div class=\"sign\">Witness Address</div></div></div><p>certify the following:</p><ul><li> I explained the effect of this power of attorney to the principal before it was signed, and the principal appeared to understand the effect of the power of attorney, </li><li>I am a prescribed witness,</li><li>I am not an attorney under this power of attorney, and</li><li>I have witnessed the signature of this power of attorney by the principal.</li></ul></section><section><h4>Witness Occupation</h4><ul class=\"checkboxes\"><li>Australian legal practitioner</li><li>Registrar of the Local Court</li><li> Licensed Conveyancer who has successfully completed a course of study approved by the Minister </li><li> NSW Trustee and Guardian employee who has successfully completed a course of study approved by the Minister </li><li> A trustee company whose employee who has successfully completed a course of study approved by the Minister </li><li> Legal Practitioner qualified in a country other than Australia who is instructed and employed independently of any legal practitioner appointed as an attorney under this power of attorney </li></ul><div class=\"sign-container\"><div class=\"sign\">Signature</div><div class=\"sign\">Date</div></div><p>Qualification [tick the appropriate category]:</p></section>", 2)),
    _createElementVNode("section", null, [
      _cache[19] || (_cache[19] = _createElementVNode("h4", null, "ATTORNEYS", -1)),
      _cache[20] || (_cache[20] = _createElementVNode("ol", { type: "a" }, [
        _createElementVNode("li", null, "I accept that I must always act in the principal’s best interests."),
        _createElementVNode("li", null, " I accept that as an attorney I must keep my own money and property separate from the principal’s money and property. "),
        _createElementVNode("li", null, " I accept that I should keep reasonable accounts and records of the principal’s money and property. "),
        _createElementVNode("li", null, " I accept that unless expressly authorised or specified in the ‘additional powers’ section by the principal, I cannot gain any benefit from being an attorney "),
        _createElementVNode("li", null, " I accept that I must always act honestly in all matters concerning the principal’s legal and financial affairs ")
      ], -1)),
      _cache[21] || (_cache[21] = _createElementVNode("p", null, [
        _createElementVNode("strong", null, [
          _createElementVNode("em", null, "Failure to do any of the above may incur civil and/or criminal penalties.")
        ])
      ], -1)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.primaryAttorneys, (attorney, i) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "sign-container",
          key: attorney.id
        }, [
          _createElementVNode("div", _hoisted_18, [
            _createElementVNode("div", _hoisted_19, [
              _createElementVNode("strong", null, _toDisplayString(attorney.directoryPerson.fullName), 1)
            ]),
            _createElementVNode("div", _hoisted_20, "Attorney " + _toDisplayString(i + 1) + " Name", 1)
          ]),
          _createElementVNode("div", _hoisted_21, "Attorney " + _toDisplayString(i + 1) + " Signature", 1),
          _cache[15] || (_cache[15] = _createElementVNode("div", { class: "sign" }, "Date", -1))
        ]))
      }), 128)),
      ($setup.hasSecondaries)
        ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
            _createElementVNode("div", _hoisted_23, [
              _createElementVNode("div", _hoisted_24, _toDisplayString($setup.secondaryAttorneys[0].directoryPerson.fullName), 1),
              _cache[16] || (_cache[16] = _createElementVNode("div", { class: "sign" }, "Substitute Attorney Name", -1))
            ]),
            _cache[17] || (_cache[17] = _createElementVNode("div", { class: "sign" }, "Substitute Attorney Signature", -1)),
            _cache[18] || (_cache[18] = _createElementVNode("div", { class: "sign" }, "Date", -1))
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}