import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, Suspense as _Suspense, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_App = _resolveComponent("App")!

  return (_openBlock(), _createBlock(_Suspense, null, {
    fallback: _withCtx(() => _cache[0] || (_cache[0] = [
      _createTextVNode("loading data...")
    ])),
    default: _withCtx(() => [
      _createVNode(_component_App)
    ]),
    _: 1
  }))
}