import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("article", null, [
    _cache[21] || (_cache[21] = _createElementVNode("h2", null, "ADVANCE CARE DIRECTIVE FORM", -1)),
    _createElementVNode("section", null, [
      _cache[1] || (_cache[1] = _createElementVNode("h3", null, "Part 1: Personal Details", -1)),
      _createElementVNode("ul", null, [
        _createElementVNode("li", null, [
          _cache[0] || (_cache[0] = _createTextVNode(" Name: ")),
          _createElementVNode("strong", null, _toDisplayString($setup.ownerPerson?.fullName), 1)
        ]),
        _createElementVNode("li", null, "Address: " + _toDisplayString($setup.ownerPerson?.fullAddress.replace('of ', '')), 1),
        ($setup.ownerPerson?.defaultPhoneNumber)
          ? (_openBlock(), _createElementBlock("li", _hoisted_1, " Phone number: " + _toDisplayString($setup.ownerPerson?.defaultPhoneNumber), 1))
          : _createCommentVNode("", true),
        _createElementVNode("li", null, "Date of birth: " + _toDisplayString($setup.ownerPerson?.dateOfBirthFormatted), 1)
      ])
    ]),
    _createElementVNode("section", null, [
      _createElementVNode("h3", null, "Part 2" + _toDisplayString($setup.hasMultipleAttorneys ? 'a' : '') + ": Appointing Substitute Decision-Makers", 1),
      _createElementVNode("ol", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.primaryAttorneys, (attorney, index) => {
          return (_openBlock(), _createElementBlock("li", {
            key: attorney.id
          }, [
            _createElementVNode("p", null, [
              _cache[2] || (_cache[2] = _createTextVNode(" I ")),
              _cache[3] || (_cache[3] = _createElementVNode("strong", null, "appoint", -1)),
              _cache[4] || (_cache[4] = _createTextVNode(": ")),
              _createElementVNode("strong", null, _toDisplayString(attorney.directoryPerson.fullName), 1),
              _createTextVNode(" " + _toDisplayString(attorney.directoryPerson.identifiers), 1)
            ]),
            _cache[8] || (_cache[8] = _createElementVNode("p", null, [
              _createElementVNode("em", null, "Your Substitute Decision-Maker must sign below:")
            ], -1)),
            _createElementVNode("ul", null, [
              _createElementVNode("li", null, [
                _cache[5] || (_cache[5] = _createTextVNode(" I, ")),
                _createElementVNode("strong", null, _toDisplayString(attorney.directoryPerson.fullName), 1),
                _cache[6] || (_cache[6] = _createTextVNode(" am over 18 years old, and I understand and accept my role and the responsibilities of being a Substitute Decision-Maker as set out in the Substitute Decision-Maker Guidelines. ")),
                _cache[7] || (_cache[7] = _createElementVNode("div", { class: "sign-container" }, [
                  _createElementVNode("div", { class: "sign" }, "Signature"),
                  _createElementVNode("div", { class: "sign" }, "Date")
                ], -1))
              ])
            ]),
            _createTextVNode(" " + _toDisplayString(index === $setup.primaryAttorneys?.length - 1 ? '' : 'AND'), 1)
          ]))
        }), 128))
      ])
    ]),
    ($setup.hasMultipleAttorneys)
      ? (_openBlock(), _createElementBlock("section", _hoisted_2, [
          _cache[9] || (_cache[9] = _createElementVNode("h3", null, "Part 2b: Conditions Of Appointment", -1)),
          _createElementVNode("p", null, " I want my Substitute Decision-Makers to make decisions " + _toDisplayString($options.mf('attorney_decisions', $setup.poa?.meta)
            .replace('jointly', 'together')
            .replace('severally', 'separately')) + ". ", 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("section", null, [
      _cache[10] || (_cache[10] = _createElementVNode("h3", null, "Part 3: What Is Important To Me – My Values and Wishes", -1)),
      _createElementVNode("p", null, _toDisplayString($setup.hasPreferences ? $options.mf('preferences', $setup.poa?.meta) : 'N/A'), 1)
    ]),
    _createElementVNode("section", null, [
      _cache[13] || (_cache[13] = _createElementVNode("h3", null, "Part 4: Binding Refusals Of Health Care", -1)),
      _createElementVNode("p", null, [
        _cache[11] || (_cache[11] = _createTextVNode(" I make the following binding refusal/s of particular health care:")),
        _cache[12] || (_cache[12] = _createElementVNode("br", null, null, -1)),
        _createTextVNode(" " + _toDisplayString($setup.hasLimitations ? $options.mf('limitations', $setup.poa?.meta) : 'N/A'), 1)
      ])
    ]),
    _cache[22] || (_cache[22] = _createElementVNode("section", null, [
      _createElementVNode("h3", null, "Part 5: Interpreter Statement"),
      _createElementVNode("p", null, "N/A")
    ], -1)),
    _createElementVNode("section", null, [
      _cache[18] || (_cache[18] = _createElementVNode("h3", null, "Part 6: Witnessing My Advance Care Directive", -1)),
      _createElementVNode("p", null, [
        _cache[14] || (_cache[14] = _createTextVNode(" I, ")),
        _createElementVNode("strong", null, _toDisplayString($setup.ownerPerson?.fullName), 1),
        _cache[15] || (_cache[15] = _createTextVNode(", do hereby give this Advance Care Directive of my own free will. "))
      ]),
      _cache[19] || (_cache[19] = _createStaticVNode("<p> I certify that I was given the Advance Care Directive Information Statement and that I understand the information contained in the Statement. </p><div class=\"sign-container\"><div class=\"sign\">Signature</div><div class=\"sign\">Date</div></div><h4>Witness statement</h4><div class=\"sign-container\"><div class=\"sign-prefixed\"><div class=\"sign-prefix\">I,</div><div class=\"sign\">Witness full name</div></div></div>", 4)),
      _createElementVNode("p", null, [
        _cache[16] || (_cache[16] = _createTextVNode(" have read and understood the Information for Witnesses guide and certify that I gave ")),
        _createElementVNode("strong", null, _toDisplayString($setup.ownerPerson?.fullName), 1),
        _cache[17] || (_cache[17] = _createTextVNode(" the Advance Care Directive Information Statement. In my opinion he/she appeared to understand the information and explanation given and did not appear to be acting under any form of duress or coercion. He/she signed this Advance Care Directive in my presence. "))
      ]),
      _cache[20] || (_cache[20] = _createStaticVNode("<div class=\"sign-container\"><div class=\"sign\">Authorised witness category</div><div class=\"sign\">Phone number</div><div class=\"sign\">Signature</div><div class=\"sign\">Date</div></div>", 1))
    ])
  ]))
}