<template>
  <div class="container">
    <article>
      <h1>Instructions for signing your Power of Attorney Medical - (QLD)</h1>
      <p>
        Thank you for choosing Safewill. Your Enduring Power of Attorney is almost complete - follow
        the steps below, provided by our legal advisers, to sign your Power of Attorney and have it
        witnessed.
      </p>
      <h4><em>Step 1 – Print your Enduring Power of Attorney</em></h4>
      <p>
        Print your Enduring Power of Attorney, read it carefully and ensure that you understand it
        completely. If you do not understand what you are signing, we recommend seeking independent
        legal advice.
      </p>
      <h4><em>Step 2 – Find a prescribed witness</em></h4>
      <p>
        Once you have understood your Enduring Power of Attorney, you will need to find at least one
        witness to watch you sign. Your witness must be someone who:
      </p>
      <ul>
        <li>
          <strong>is:</strong>
          <ul>
            <li>a justice of the peace;</li>
            <li>an Australian lawyer (eg a solicitor or a barrister);</li>
            <li>a notary public; or</li>
            <li>a commissioner for declarations; and</li>
          </ul>
        </li>
        <li>
          <strong>is not:</strong>
          <ul>
            <li>your attorney, your relative, or a relative of your attorney; or</li>
            <li>your paid carer or health-care provider.</li>
          </ul>
        </li>
      </ul>
      <p>
        The witness must confirm that the principal appeared to have the capacity necessary to make
        the Enduring Power of Attorney.
      </p>
      <h4><em>Step 3 – Signing your Enduring Power of Attorney</em></h4>
      <p>
        You should sign the Enduring Power of Attorney first, in the presence of your witness. Make
        sure your witness is watching you sign and date the document, before signing themselves and
        completing the number of document pages (where indicated). Signing the bottom of each page
        of the document is not mandatory, however we recommend doing so to protect against pages
        being substituted. You will then need to arrange for your attorney(s) to sign.
      </p>
      <h4><em>Step 4 – Keep your Enduring Power of Attorney safe</em></h4>
      <p>
        It is important to store your Enduring Power of Attorney in a safe place and provide your
        attorney(s) with a copy. You should also keep a copy with someone you trust like your
        solicitor or accountant.
      </p>
      <h4><em>Note on Revocation</em></h4>
      <p>
        In Queensland, signing a new Enduring Power of Attorney does not automatically revoke an old
        Enduring Power of Attorney. As such, <strong>if you</strong>:
      </p>
      <ul>
        <li>
          have an existing Enduring Power of Attorney that you would like to revoke before making
          this Enduring Power of Attorney; <strong>or</strong>
        </li>
        <li>
          would like to change anything in this Enduring Power of Attorney document after it is
          signed;
        </li>
      </ul>
      <p>
        please follow the instructions on revocation included at the end of this Enduring Power of
        Attorney. Please also note that an Enduring Power of Attorney can only be revoked by the
        Principal while they have mental capacity.
      </p>
    </article>
    <AppointmentOfAttorneys />
    <article>
      <section>
        <h2>SECTION 6: WHAT TO DO WITH YOUR COMPLETED POWER OF ATTORNEY</h2>
        <p>You are not required to register this enduring power of attorney anywhere.</p>
        <p>You should:</p>
        <ul>
          <li>keep the original in a safe place</li>
          <li>
            give a certified copy to your attorney(s), doctor, other health provider(s), bank or
            lawyer
          </li>
          <li>
            if your attorney(s) wish to deal with land in Queensland on your behalf, register your
            enduring power of attorney with the Queensland Titles Registry by lodging Form 16 –
            Request to register power of attorney together with a single-sided certified copy of the
            enduring power of attorney
          </li>
          <li>
            notify your close family and friends that you have made an enduring power of attorney
            and where to find the document
          </li>
          <li>review your enduring power of attorney if your personal circumstances change.</li>
        </ul>
        <div class="box">
          <h4>My Health Record</h4>
          <p>
            If you wish your document to be in My Health Record you can upload it via the My Health
            Record website at
            <a href="https://www.myhealthrecord.gov.au"> www.myhealthrecord.gov.au</a>. Your
            document will be valid regardless of whether it is uploaded.
          </p>
          <h4>Office of Advance Care Planning</h4>
          <p>
            You are able to have your enduring power of attorney uploaded to your Queensland Health
            electronic record. To do this, send a copy of your document to the Office of Advance
            Care Planning. This way it will be easily available to authorised clinicians involved in
            your care when it is required. A copy of your documents can be sent to the Office of
            Advance Care Planning at
            <a href="mailto:acp@health.qld.gov.au">acp@health.qld.gov.au</a>, PO Box 2274, Runcorn,
            Queensland 4113 or fax 1300 008 227.
          </p>
        </div>
      </section>
      <section>
        <h2>Revoking previous Enduring Power of Attorney</h2>
        <p>
          If you would like to revoke your existing Enduring Power of Attorney, please follow these
          steps.
        </p>
        <h4><em>Step 1 – Print your Enduring Power of Attorney</em></h4>
        <p>
          You (Principal) will need to find one person to witness and sign the Revocation of Power
          of Attorney and witness your signing of it.
        </p>
        <p>Your witness must be one of the following:</p>
        <ul>
          <li>Australian legal practitioner</li>
          <li>Justice of the peace</li>
          <li>Commissioner for declarations</li>
          <li>Notary public</li>
        </ul>
        <h4><em>Step 2 - Complete and sign the Revocation of Power of Attorney</em></h4>
        <p>
          A Revocation of a Power of Attorney form can be found here:<br />
          <a
            href="https://www.publications.qld.gov.au/dataset/28355095-5f80-441e-a0d6-785ea8f63ce7/resource/c7b0dc20-37a2-4803-8c3e-5ffdcfd2cdb6/download/revocationofenduringpowerofattorneyform6.pdf"
            >https://www.publications.qld.gov.au/dataset/28355095-5f80-441e-a0d6-785ea8f63ce7/resource/c7b0dc20-37a2-4803-8c3e-5ffdcfd2cdb6/download/revocationofenduringpowerofattorneyform6.pdf</a
          >
        </p>
        <p>
          You must sign the Revocation of Power of Attorney in the presence of your witness. Make
          sure your witness is watching you sign and date the document. After this your witness must
          also sign where indicated.
        </p>
        <h4><em>Step 3 - Give written notice of the Revocation </em></h4>
        <p>
          After you have signed your Revocation of the Enduring Power of Attorney and it has been
          witnessed, you must, as soon as practicable, give notice of the Revocation. You can do
          this by writing a letter enclosing a copy of the completed and signed Revocation to:
        </p>
        <ul>
          <li>all of your attorney(s) whose appointment has been revoked;, and</li>
          <li>
            anyone else who may be aware of or has dealings with the revoked Power of Attorney.
          </li>
        </ul>
        <p>
          If you fail to give notice, your attorney(s) may legally continue to make decisions on
          your behalf under the old Power of Attorney document.
        </p>
        <h4><em>Step 4 - Registration of Revocation</em></h4>
        <p>
          In Queensland, if you have not registered the Enduring Power of Attorney that you have
          just revoked, you do not need to register the Revocation of Enduring Power of Attorney.
        </p>
        <p>
          If you had previously registered your old Power of Attorney, you must lodge at Titles
          Queensland:
        </p>
        <ol>
          <li>The signed Revocation Form</li>
          <li>
            A completed Request to Register Revocation of Power of Attorney Form 16 which can be
            found here:<br /><a
              href="https://www.titlesqld.com.au/wp-content/uploads/2021/05/titles-form-16-version4.pdf"
              >https://www.titlesqld.com.au/wp-content/uploads/2021/05/titles-form-16-version4.pdf</a
            >
          </li>
        </ol>
        <p>
          To find the location of Titles Queensland for lodging, please visit the Titles Queensland
          website at:<br /><a href="https://www.titlesqld.com.au/contact/"
            >https://www.titlesqld.com.au/contact/</a
          >.
        </p>
        <p>Please note there may be fees associated with the lodgement.</p>
        <p>
          You should keep a copy of the completed and signed Revocation for your own record keeping
          purposes.
        </p>
      </section>
    </article>
  </div>
</template>

<script lang="ts">
import AppointmentOfAttorneys from './components/medical/AppointmentOfAttorneys.vue';

export default {
  components: {
    AppointmentOfAttorneys,
  },
};
</script>
