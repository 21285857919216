<template>
  <div class="container">
    <article>
      <h1>Instructions for signing your Advance Care Directive - (SA)</h1>
      <p>
        Thank you for choosing Safewill. Your Advance Care Directive is almost complete - follow the
        steps below, provided by our legal advisers, to sign your Advance Care Directive and have it
        witnessed.
      </p>
      <h4>
        <em>Step 1 – Print your Advance Care Directive</em>
      </h4>
      <p>
        Print your Advance Care Directive, read it carefully and ensure that you understand it
        completely. If you do not understand what you are signing, we recommend seeking independent
        legal advice.
      </p>
      <h4><em>Step 2 – Find a suitable witness</em></h4>
      <p>
        Once you have understood your Advance Care Directive, you will need to find a prescribed
        witness to sign the document and witness your signing of it. The Advance Care Directive can
        only be witnessed by someone who:
      </p>
      <ul>
        <li>
          <strong>is not:</strong>
          <ul>
            <li>your relative;</li>
            <li>someone being appointed as an attorney (or a relative of your attorney); or</li>
            <li>your care worker or accommodation provider; and</li>
          </ul>
        </li>
        <li><strong>is</strong> a competent adult; and</li>
        <li>
          an authorised witness, which includes:
          <ul>
            <li>registered professionals such as teachers, nurses, doctors or pharmacists</li>
            <li>lawyers or Justices of the Peace (JP)</li>
            <li>
              Local, State or Commonwealth Government employees with more than 5 years continuous
              service
            </li>
            <li>bank managers or police officers with more than 5 years continuous service</li>
            <li>ministers or religion or marriage celebrants</li>
            <li>accountants</li>
            <li>veterinary surgeons</li>
          </ul>
        </li>
      </ul>
      <p>
        Your witness is required to ensure you have a copy of the advance care directive information
        statement (attached at the back of your Advance Care Directive) and sign the witness
        statement within your Advance Care Directive.
      </p>
      <h4>
        <em>Step 3 – Signing your Advance Care Directive</em>
      </h4>
      <p>
        Sign using a blue or black pen. You should first give the Advance Care Directive to your
        substitute decision maker(s) to sign and accept their appointment, along with the guidelines
        for substitute decision-makers attached to the back of your Advance Care Directive . After
        this, you must sign the Advance Care Directive in the presence of your witness. Then your
        witness must sign the document where indicated as well. Signing the bottom of each page of
        the document is not mandatory, however we recommend doing so to protect against pages being
        substituted.
      </p>
      <h4>
        <em>Step 4 – Keep your Advance Care Directive safe</em>
      </h4>
      <p>
        It is important to store your Advance Care Directive in a safe place and provide your
        substitute decision-make(s) with a copy. You should also keep a copy with someone you trust
        like your solicitor or health practitioner.
      </p>
      <h4><em>Note on Revocation</em></h4>
      <p>
        In South Australia, when a new Advance Care Directive has been signed and witnessed as
        required by the Advance Care Directives Act 2013, any previous Advance Care Directive will
        automatically be revoked.
      </p>
      <p>
        If you have an existing Advance Care Directive, and you are signing a new Advance Care
        Directive, you must, as soon as practicable:
      </p>
      <ol>
        <li>
          advise each substitute decision-maker appointed under the old Advance Care Directive of
          the revocation of the old Advance Care Directive; and
        </li>
        <li>
          give certified copies of the new Advance Care Directive to any other person who has been
          given a copy of the old/previous Advance Care Directive including family, health
          practitioners and hospitals.
        </li>
      </ol>
      <p>
        If you have an existing Advance Care Directive and are unclear about the effect of signing a
        new Advance Care Directive, we recommend seeking legal advice.
      </p>
      <p>
        If you wish to make changes to this Advance Care Directive, you must make a completely new
        Advance Care Directive and have it witnessed again (following the above procedure to tell
        each decision maker that the Advance Care Directive has been revoked).
      </p>
    </article>
    <AppointmentOfAttorneys />
    <article>
      <section>
        <h3>INFORMATION STATEMENT</h3>
        <p>
          Your witness will ask you to read this Information Statement, and will then ask you a
          number of questions to make sure that you understand what you are doing by making an
          Advance Care Directive, and it is your choice to write one.
        </p>
      </section>
      <section>
        <h4>What is an Advance Care Directive?</h4>
        <p>
          An Advance Care Directive is a legal form that allows people over the age of 18 years to:
        </p>
        <ul>
          <li>
            write down their wishes, preferences and instructions for future health care, end of
            life, living arrangements and personal matters and/or
          </li>
          <li>
            appoint one or more Substitute Decision-Makers to make these decisions on their behalf
            when they are unable to do so themselves. It cannot be used to make financial decisions.
          </li>
        </ul>
        <p>
          If you have written a refusal of health care, it must be followed if relevant to the
          circumstances at the time. All other information written in your Advance Care Directive is
          advisory and should be used as a guide to decision-making by your Substitute
          Decision-Maker(s), your health practitioners or anyone else making decisions on your
          behalf, e.g. persons responsible (close family/friends).
        </p>
        <p>
          It is your choice whether or not to have an Advance Care Directive. No one can force you
          to have one or to write things you do not want. These are offences under the law. You can
          change your Advance Care Directive at any time while you are still able by completing a
          new Advance Care Directive Form. Your new Advance Care Directive Form will replace all
          other documents you may have completed previously, including an Enduring Power of
          Guardianship, Medical Power of Attorney or Anticipatory Direction.
        </p>
      </section>
      <section>
        <h4>When will it be used?</h4>
        <p>
          Your Advance Care Directive only takes effect (can only be used) if you are unable to make
          your own decisions, whether temporarily or permanently.
        </p>
        <p>Your decision-making is impaired if you cannot:</p>
        <ul>
          <li>understand information about the decision</li>
          <li>understand and appreciate the risks and benefits of the choices</li>
          <li>remember the information for a short time and</li>
          <li>tell someone what the decision is and why you have made the decision.</li>
        </ul>
        <p>
          This means you are unable to make the decision and someone else will need to make the
          decision for you.
        </p>
      </section>
      <section>
        <h4>Who will make decisions for you if you cannot?</h4>
        <p>
          It is your choice whether you appoint one or more Substitute Decision-Makers. If you have
          appointed one or more Substitute Decision-Makers, they will be legally able to make
          decisions for you about your health care, living arrangements and other personal matters
          when you are unable to. You can specify the types of decisions you want them to make in
          Part 2b: Conditions of Appointment of your Advance Care Directive.
        </p>
        <p>
          If you do not appoint any Substitute Decision-Makers others close to you may be asked to
          make decisions for you if you are unable to (Person Responsible). They must follow any
          relevant wishes or instructions you have written in your Advance Care Directive. Anyone
          making a decision for you will need to make a decision they think you would have made in
          the same circumstances. A Substitute Decision-Maker needs to “stand in your shoes”.
        </p>
      </section>
      <section>
        <h4>Refusals of health care</h4>
        <p>
          You may have written in your Advance Care Directive that you do not want certain types of
          health care, also known as a refusal of health care. It is important to make sure you have
          written down when or under what circumstances any refusals of health care apply.
        </p>
        <p>
          If you have refused specific health care in your Advance Care Directive, your Substitute
          Decision-Maker(s), Person Responsible and your health practitioner must follow that
          refusal if it is relevant to the current circumstances.
        </p>
        <p>
          This means that your health practitioner will not be able to give you the health care or
          treatment you have refused.
        </p>
        <p>
          If you refuse health care but do not write down when the refusal applies, it will apply at
          all times when you cannot make the decision.
        </p>
        <p>
          A health practitioner can only override a refusal of health care if there is evidence to
          suggest you have changed your mind but did not update your Advance Care Directive, or the
          health practitioner believes you didn’t mean the refusal of health care to apply in the
          current circumstance.
        </p>
        <p>
          If this happens they will need consent from your Substitute Decision-Makers, if you have
          any, or a Person Responsible, to provide any health care. You cannot refuse compulsory
          mental health treatment as listed in a community or involuntary treatment order if you
          have one.
        </p>
      </section>
      <section>
        <h4>How will others know I have an Advance Care Directive?</h4>
        <p>It is recommended that you:</p>
        <ol>
          <li>
            Complete the Wallet Card available at
            <a href="https://www.advancecaredirectives.sa.gov.au"
              >www.advancecaredirectives.sa.gov.au</a
            >
          </li>
          <li>
            Give a certified copy to any appointed Substitute Decision-Makers, your doctor, your
            health service where you regularly attend, and others close to you.
          </li>
          <li>Keep a certified copy with you and where you can easily find it.</li>
          <li>
            Fill out the Emergency Medical Information Booklet (EMIB) and display it with your Form
            on your fridge (<a href="https://www.emib.org.au">www.emib.org.au</a>).
          </li>
          <li>
            Add it to your Electronic Health Record if you have one (<a
              href="https://www.ehealth.gov.au"
              >www.ehealth.gov.au</a
            >).
          </li>
        </ol>
      </section>
      <section>
        <h4>More information</h4>
        <p>
          If you would like more information please read the Advance Care Directives Guide provided
          with this Form or online at
          <a href="https://www.advancecaredirectives.sa.gov.au"
            >www.advancecaredirectives.sa.gov.au</a
          >. This information statement has been translated into 15 different languages and can be
          found on the Advance Care Directive website
        </p>
      </section>
    </article>
    <article>
      <section>
        <h3>SUBSTITUTE DECISION-MAKER GUIDELINES</h3>
        <h4>
          Read these guidelines before you agree to be appointed as a Substitute Decision-Maker, and
          keep it for future reference
        </h4>
        <p>
          By signing the Advance Care Directive Form you are stating that you agree to be the
          person’s Substitute Decision-Maker and that you understand your role and responsibilities.
        </p>
        <p>
          Before you sign, make sure you understand what types of decisions you will be able to
          make, how the person wants you to make those decisions for them, and that you are able to
          be a Substitute Decision-Maker.
        </p>
        <p>
          Persons who cannot be appointed as a Substitute Decision-Maker include the person’s
          doctor, nurse, or paid professional carer.
        </p>
        <p>
          Family members or friends who are paid Carers’ Allowance by Centrelink can be appointed as
          Substitute Decision-Makers.
        </p>
        <p>
          After you are appointed you should keep a certified copy of the completed, signed Advance
          Care Directive where you can easily find it.
        </p>
        <p>
          You should try to have regular discussions with the person who appointed you in case
          circumstances change for them.
        </p>
      </section>
      <section>
        <h4>What is the role of a Substitute Decision-Maker?</h4>
        <p>
          As a Substitute Decision-Maker you must try to make a decision you believe the person
          would have made for themselves in the same situation.
        </p>
        <p>
          As a Substitute Decision-Maker you can make all the decisions the person wanted you to
          make, but you cannot:
        </p>
        <ul>
          <li>Make a decision which would be illegal, such as requesting voluntary euthanasia.</li>
          <li>Refuse food and water to be given to them by mouth.</li>
          <li>Refuse medicine for pain or distress (for example palliative care).</li>
          <li>
            Make legal or financial decisions (unless you have also been appointed as an Enduring
            Power of Attorney for financial matters). When contacted and asked to make a decision,
            you must:
          </li>
          <li>
            Only make a decision during periods when the person who appointed you cannot make their
            own decision. This may be temporary or permanent.
          </li>
          <li>Support that person to make their own decision if they are able to.</li>
          <li>
            Produce an original or certified copy of the person’s Advance Care Directive Form or
            advise if it can be accessed in an electronic record.
          </li>
          <li>
            Only make decisions which you have been appointed to make under Part 2b Conditions of
            Appointment
          </li>
          <li>
            Try to contact any other Substitute Decision-Maker who has been appointed to make the
            same types of decisions as you.
          </li>
          <li>
            Only make a decision on your own if no other Substitute Decision-Maker with the same
            decision-making responsibility as you cannot be contacted, or the decision is urgent.
          </li>
          <li>Inform any other Substitute Decision-Maker(s) of the decisions you make.</li>
          <li>
            Try to make a decision you believe the person would have made in the same circumstance.
            For guidance when making decisions, look at the Decision-Making Pathway over the page or
            for more detail visit www.advancecaredirectives.sa.gov.au
          </li>
        </ul>
      </section>
      <section>
        <h4>3 things to remember as a Substitute Decision-Maker</h4>
        <ol>
          <li>
            You can only make decisions when the person who appointed you cannot make their own
            decision/s.
          </li>
          <li>
            You must stand in the person’s shoes and try to make decisions you believe the person
            would have made for themselves.
          </li>
          <li>
            Keep a certified copy of the completed and signed Advance Care Directive where you can
            easily find it.
          </li>
        </ol>
      </section>
      <section>
        <h4>
          A guide to decision-making for Substitute Decision Makers: how to stand in the person’s
          shoes
        </h4>
        <ol>
          <li>
            The Substitute Decision-Maker may assess the person’s capacity to make this decision
            (see How to assess decision making capacity fact sheet).
            <ul>
              <li>
                if substitute decision is required, then appointed substitute decision-maker
                proceeds to step 2
              </li>
            </ul>
          </li>
          <li>
            Establish whether preferences relevant to the decision have been previously expressed in
            an Advance Care Directive or in previous discussions.
          </li>
          <li>
            For health-related decisions, consider the advice of health professionals about
            treatment or health care options and likely outcomes in light of the person’s wishes:
            <ul>
              <li>interventions considered overly burdensome or intrusive</li>
              <li>outcomes of care to avoid. (Part 3 and 4 of Form)</li>
            </ul>
          </li>
          <li>
            Comply with specific refusals of medical treatments or health care and interventions if
            intended to apply to the current circumstances. (Part 4 of Form)
          </li>
          <li>
            Consider other preferences and directions in the Advance Care Directive relevant to the
            current decision. (eg Part 3 of Form)
          </li>
          <li>
            If no specific relevant preferences and directions, consult with others close to the
            person to determine any relevant previously expressed views and social or relationship
            factors he or she would consider in decision-making.
          </li>
          <li>
            Consider the person’s known values, life goals and cultural, linguistic and religious
            preferences and make the decision that the person would make if he or she had access to
            current information and advice.
          </li>
          <li>
            Where several options meet these decision-making criteria, choose the least restrictive
            option that best ensures the person’s overall care and well-being.
          </li>
          <li>
            For residential decisions, consider the adequacy of existing informal arrangements for
            the person’s care and the desirability of not disturbing those arrangements.
          </li>
          <li>
            If there is no evidence of what the person would have decided, make the decision that
            best upholds the person’s health, well-being and values.
          </li>
        </ol>
      </section>
      <section>
        <h3>INFORMATION FOR WITNESSES</h3>
        <h4>Key points to know before you witness an Advance Care Directive.</h4>
        <p>
          Check that you fit one of the authorised witness categories. The full list is available on
          page 3 of 3.
        </p>
        <ul>
          <li>
            You must be independent of the person you are witnessing for, and cannot be:
            <ul>
              <li>a beneficiary in their Will – for example a family member</li>
              <li>appointed as their Substitute Decision-Maker or</li>
              <li>
                their health practitioner or paid professional carer. If there is a chance you will
                be the person’s health practitioner in the future you should not witness their
                Advance Care Directive
              </li>
            </ul>
          </li>
        </ul>
        <p>It is your choice whether or not you witness a person’s Advance Care Directive.</p>
        <ul>
          <li>
            To be valid, an Advance Care Directive must be completed on the official Advance Care
            Directive Form. It may be completed in handwriting or electronic text.
          </li>
          <li>
            Do not witness the Advance Care Directive until it has been finalised, including signed
            by any Substitute Decision-Makers (you do not need to witness the acceptance).
          </li>
          <li>It is not your role to check the content of the person’s Advance Care Directive.</li>
          <li>
            If you think the person is not competent to complete an Advance Care Directive, you can
            request they provide medical documentation which states that they are.
          </li>
        </ul>
        <h4>To fulfil your witness obligations you must:</h4>
        <ol>
          <li>Follow the correct witnessing process (see next page).</li>
          <li>
            Make sure the person has a copy and has read the Advance Care Directive Information
            Statement. You may need to read it to the person if they are visually impaired.
            Translated versions in 15 languages are also available on the website.
          </li>
          <li>
            Verify that the person appeared to understand the Advance Care Directive Information
            Statement and that they did not appear to be acting under any form of duress or
            coercion.
          </li>
          <li>
            If you also have to interpret the document, please read the Information for Interpreters
            document.
          </li>
        </ol>
        <h4>Penalties</h4>
        <p>
          The Advance Care Directives Act 2013 (SA) contains penalties for making false or
          misleading statements, as well as penalties for dishonesty, undue influence, or inducing
          another to give an Advance Care Directive. Maximum penalties are $20 000 or imprisonment
          for two years.
        </p>
        <h4>Process for correct witnessing</h4>
        <ol>
          <li>Confirm that the identity of the person matches the details on the Form.</li>
          <li>Give the person the Advance Care Directive Information Statement.</li>
          <li>
            Speak with the person alone so you can assess if they are voluntarily giving the Advance
            Care Directive and to limit the possibility of coercion by others.
          </li>
          <li>
            Once the person has read the Advance Care Directive Information Statement ask the
            questions below to make sure you are satisfied the person appears to understand the
            Information Statement and that they do not appear to be acting under duress or coercion.
            What is an Advance Care Directive? When will your Advance Care Directive be used? What
            types of decisions will it cover? Who will have to follow your Advance Care Directive?
            Why have you decided to complete an Advance Care Directive? Have you appointed any
            Substitute Decision-Makers? Why did you choose them? What decisions will they be able to
            make? When will they be able to make decisions for you?
          </li>
          <li>
            If the person has appointed any Substitute Decision-Makers, make sure they have signed
            the Form and accepted their appointment. This must be done before you witness the
            document. You do not need to witness the acceptance.
          </li>
          <li>
            Check whether there are any alterations to the Form (including white-out). You and the
            person completing the Form should initial and date any alterations. Make sure any blank
            sections have a large “Z” drawn across them.
          </li>
          <li>
            If you are satisfied that the person appears to understand the Advance Care Directive
            Information Statement and that they do not appear to be acting under duress or coercion,
            ask the person to sign the Form in front of you. If they are physically unable to sign
            due to an injury, illness or disability, another person can sign this on their behalf.
            This person should not be the appointed Substitute Decision-Maker. You must make a note
            of this in the signing section by adding “He/She, due to an illness, injury or
            disability was unable to personally sign this Form, but instructed (name of person) to
            sign on their behalf in my presence.”
          </li>
          <li>
            Fill in the Witness Statement in Part 6 of the Advance Care Directive Form. Record your
            name, occupation and contact details and then sign the Form. Both you and the person
            must initial and date each page of the Advance Care Directive in the boxes provided.
          </li>
          <li>
            Make multiple certified copies of the document after it has been signed by all parties,
            and witnessed. Please see page 15 of the Guide or visit the website for information
            about certifying copies of the original Advance Care Directive Form.
          </li>
        </ol>
        <h4>Training</h4>
        Online training for all witnesses is available from:
        <ul>
          <li>
            Justices of Peace Training Organisation<br />
            Go to: <a href="http://sa.jpto.org.au/">http://sa.jpto.org.au/</a>
          </li>
          <li>
            TAFE<br />
            Email: <a href="advancecare.online@tafesa.edu.au"></a>
          </li>
        </ul>
        <h4>LIST OF AUTHORISED WITNESSES</h4>
        <p>The following persons, or classes of persons, are authorised witnesses:</p>
        <ul>
          <li>Registered professionals such as teachers, nurses, doctors or pharmacists</li>
          <li>Lawyers or Justices of the Peace (JP)</li>
          <li>
            Local, State or Commonwealth Government employees with more than 5 years continuous
            service
          </li>
          <li>Bank managers or police officers with more than 5 years continuous service</li>
          <li>Ministers of religion or marriage celebrants</li>
          <li>Agents of Australian Post with 5 or more years continuous service</li>
          <li>Australian Consular Officers or Diplomatic Officers</li>
          <li>Bailiffs; sheriffs; sheriff’s officers</li>
          <li>Chief executive officers of Commonwealth Courts</li>
          <li>Commissioners for taking affidavits and/or declarations</li>
          <li>
            Employees of the Australian Trade Commission, or of the Commonwealth who are outside
            Australia
          </li>
          <li>Fellows of the National Tax and Accountants’ Association</li>
          <li>Statutory Office Holders</li>
          <li>
            Clerks, Judges, Magistrates or Masters of Court or Court Registrars or Deputy Registrars
          </li>
          <li>
            Members of: Governance Institute of Australia; Engineers Australia; Association of
            Taxation and Management Accountants; Australasian Institute of Mining and Metallurgy
          </li>
          <li>
            Australian Defence Force Officer, non-commissioned officer or a warrant officer with 5
            or more years of continuous service
          </li>
          <li>
            Members of the Institute of Chartered Accountants in Australia; the CPA Australia
            (Certified Public Accountants) or the Institute of Public Accountants
          </li>
          <li>Members of Parliaments; or local, State or Territory government authorities</li>
          <li>Notary public</li>
          <li>Patent attorneys or trade mark attorneys</li>
          <li>Veterinary surgeons.</li>
        </ul>
        <p>
          Remember, you must be independent of the person who has asked you to witness their Advance
          Care Directive. This means that as a witness you cannot be:
        </p>
        <ul>
          <li>a beneficiary in their Will – for example a family member</li>
          <li>appointed as their Substitute Decision-Maker or</li>
          <li>their health practitioner or paid professional carer.</li>
        </ul>
      </section>
    </article>
  </div>
</template>

<script lang="ts">
import AppointmentOfAttorneys from './components/medical/AppointmentOfAttorneys.vue';

export default {
  components: {
    AppointmentOfAttorneys,
  },
};
</script>
