<template>
  <article>
    <section>
      <h2>APPOINTMENT OF ATTORNEYS</h2>
      <ol>
        <li>
          I, <strong>{{ ownerPerson?.fullName }}</strong> {{ ownerPerson?.fullAddress }},
          <strong>appoint</strong>:
          <ol type="a">
            <li :key="attorney.id" v-for="attorney of primaryAttorneys">
              <strong>{{ attorney.directoryPerson.fullName }}</strong>
              {{ attorney.directoryPerson.identifiers }}
            </li>
          </ol>
          to be my
          <template v-if="hasMultipleAttorneys">
            {{ mf('attorney_decisions', poa?.meta).replaceAll('ly', '').replace('or', 'and') }}
          </template>
          {{ plural('attorney', primaryAttorneys) }}.
        </li>
        <li v-if="hasSecondaries">
          I <strong>appoint</strong>&nbsp;
          <strong>{{ secondaryAttorneys[0].directoryPerson.fullName }}</strong>
          {{ secondaryAttorneys[0].directoryPerson.identifiers }} as
          <strong>alternative attorney</strong> for:
          <ul>
            <li :key="attorney.id" v-for="attorney of primaryAttorneys">
              <strong>{{ attorney.directoryPerson.fullName }}</strong>
              {{ attorney.directoryPerson.identifiers }}
            </li>
          </ul>
        </li>
      </ol>
    </section>
    <section>
      <h3>AUTHORISATION</h3>
      <ol :start="2 + hasSecondaries">
        <li>
          I <strong>authorise</strong> my attorney(s) to do anything on my behalf that I can
          lawfully do by an attorney for
          <ul>
            <li><strike>personal matters only</strike></li>
            <li><strong>financial matters only</strong></li>
            <li><strike>the following specified matters</strike></li>
          </ul>
        </li>
        <li v-if="hasInclusions">
          I <strong>authorise</strong> my attorney(s) to provide for the needs of the following
          people as is reasonable:
          <ol type="a">
            <li :key="inclusion.id" v-for="inclusion of inclusions">
              <strong>{{ inclusion.directoryPerson.fullName }}</strong>
              {{ inclusion.directoryPerson.identifiers }}
            </li>
          </ol>
        </li>
      </ol>
    </section>
    <section>
      <h3>COMMENCEMENT</h3>
      <ol :start="3 + hasSecondaries + hasInclusions">
        <li>
          The powers under this Enduring Power of Attorney for financial matters are exercisable
          when I cease to have decision making capacity for the matter(s).
        </li>
      </ol>
    </section>
    <section>
      <h3>CONDITIONS AND INSTRUCTIONS</h3>
      <ol :start="4 + hasSecondaries + hasInclusions">
        <li>
          The exercise of power under this enduring power of attorney is subject to the following
          conditions or instructions:
          <ul>
            <li v-if="hasLimitations">{{ mf('limitations', poa?.meta) }}</li>
            <li v-else>NIL</li>
          </ul>
        </li>
      </ol>
    </section>
    <section>
      <h3>EXECUTION</h3>
      <ol :start="5 + hasSecondaries + hasInclusions">
        <li>
          <h4>PRINCIPAL</h4>
          <div class="sign-container">
            <div class="sign-prefixed">
              <div class="sign-prefix">
                {{ ownerPerson?.fullName }}
              </div>
              <div class="sign">Name</div>
            </div>
            <div class="sign">Signature</div>
            <div class="sign">Date</div>
          </div>
        </li>
        <li>
          <h4>CERTIFICATE OF WITNESSES</h4>
          <p><strong>Witnessed</strong> by:</p>
          <div class="sign-container">
            <div class="sign">Name of first witness:</div>
            <div class="sign">Address of first witness:</div>
          </div>
          <div class="sign-container">
            <div class="sign">Name of second witness:</div>
            <div class="sign">Address of second witness:</div>
          </div>
          <p><strong>Each witness certifies</strong> that:</p>
          <p>
            the principal appeared to sign this instrument freely and voluntarily in my presence;
            and
          </p>
          <ul>
            <li>
              at that time, the principal appeared to me to have decision making capacity in
              relation to the making of this enduring power of attorney; and
            </li>
            <li>I am not an attorney under this enduring power of attorney; and</li>
            <li>
              I am not a relative of the principal or an attorney under this enduring power of
              attorney; and
            </li>
            <li>I am not a care worker or accommodation provider for the principal.</li>
          </ul>
          <h4>Signed</h4>
          <div class="sign-container">
            <div class="sign">First witness (signature)</div>
            <div class="sign">
              Qualification
              <em
                >[if first witness is acting as medical practitioner or person authorised to witness
                affidavits]</em
              >
            </div>
          </div>
          <div class="sign-container">
            <div class="sign">Second witness (signature)</div>
            <div class="sign">
              Qualification
              <em
                >[if second witness is acting as medical practitioner or person authorised to
                witness affidavits]</em
              >
            </div>
          </div>
          <div class="sign-container">
            <div class="sign">Date</div>
          </div>
        </li>
        <li>
          <h4>STATEMENT OF ACCEPTANCE OF APPOINTMENT - ATTORNEYS</h4>
          <section :key="attorney.id" v-for="attorney of primaryAttorneys">
            <p>
              Name of attorney:
              <strong>{{ attorney.directoryPerson.fullName }}</strong>
              <br />
              Address of attorney:
              {{ attorney.directoryPerson.fullAddress }}
            </p>
            <p>
              <strong>I accept my appointment as attorney</strong> under this enduring power of
              attorney and state that:
            </p>
            <ul>
              <li>
                I am eligible under Part 3 of the
                <strong>Powers of Attorney Act 2014</strong> to act as an attorney under an enduring
                power of attorney; and
              </li>
              <li>
                I understand the obligations of an attorney under an enduring power of attorney and
                under the
                <strong>Powers of Attorney Act 2014</strong> and the consequences of failing to
                comply with those obligations; and
              </li>
              <li>
                I undertake to act in accordance with the provisions of the
                <strong>Powers of Attorney Act 2014</strong> that relate to enduring powers of
                attorney.
              </li>
            </ul>
            <p><em>Cross out the statement that does not apply:</em></p>
            <ul>
              <li>
                I have disclosed to the principal that I have been convicted or found guilty of an
                offence involving dishonesty
              </li>
              <li>I have not been convicted or found guilty of an offence involving dishonesty</li>
            </ul>
            <div class="sign-container">
              <div class="sign">Signature</div>
              <div class="sign">Date</div>
            </div>
            <p><strong>Witnessed</strong> by:</p>
            <div class="sign-container">
              <div class="sign">Name of witness</div>
              <div class="sign">Address of witness</div>
            </div>
            <p>I witnessed the signing of the statement of acceptance by the attorney.</p>
            <h4>Signed:</h4>
            <div class="sign-container">
              <div class="sign">Signature of witness</div>
              <div class="sign">Date</div>
            </div>
            <p>
              Note: Each attorney must sign a statement of acceptance and it must be witnessed
              separately in the enduring power of attorney.
            </p>
          </section>
        </li>
        <li v-if="hasSecondaries">
          <h4>STATEMENT OF ACCEPTANCE OF APPOINTMENT – ALTERNATIVE ATTORNEY</h4>
          <p>
            Name of alternative attorney:
            <strong>{{ secondaryAttorneys[0].directoryPerson.fullName }}</strong>
            <br />
            Address of alternative attorney:
            {{ secondaryAttorneys[0].directoryPerson.fullAddress }}
          </p>
          <p>
            I accept my appointment as an alternative attorney under this enduring power of attorney
            and state that:
          </p>
          <ul>
            <li>
              I am eligible under Part 3 of the
              <strong>Powers of Attorney Act 2014</strong> to act as an attorney under an enduring
              power of attorney; and
            </li>
            <li>
              I understand the obligations of an attorney under an enduring power of attorney and
              under the
              <strong>Powers of Attorney Act 2014</strong> and the consequences of failing to comply
              with those obligations; and
            </li>
            <li>
              I undertake to act in accordance with the provisions of the
              <strong>Powers of Attorney Act 2014</strong> that relate to enduring powers of
              attorney; and
            </li>
            <li>
              I understand the circumstances in which the alternative attorney is authorised to act
              under the
              <strong>Powers of Attorney Act 2014</strong>; and
            </li>
            <li>
              I am prepared to act in the place of the attorney for whom I am appointed, if still
              eligible to act as attorney, when authorised to do so under the
              <strong>Powers of Attorney Act 2014</strong>.
            </li>
          </ul>
          <p><em>Cross out the statement that does not apply:</em></p>
          <ul>
            <li>
              I have disclosed to the principal that I have been convicted or found guilty of an
              offence involving dishonesty
            </li>
            <li>I have not been convicted or found guilty of an offence involving dishonesty</li>
          </ul>
          <div class="sign-container">
            <div class="sign">Signature</div>
            <div class="sign">Date</div>
          </div>
          <section>
            <p><strong>Witnessed</strong> by:</p>
            <div class="sign-container">
              <div class="sign">Name of witness</div>
              <div class="sign">Address of witness</div>
            </div>
            <p>
              I witnessed the signing of the statement of acceptance by the alternative attorney.
            </p>
            <h4>Signed:</h4>
            <div class="sign-container">
              <div class="sign">Signature of witness</div>
              <div class="sign">Date</div>
            </div>
          </section>
        </li>
      </ol>
    </section>
  </article>
</template>

<script lang="ts">
import poa from '@/hooks/poa';
import { mf, plural, ifPlural } from '@/utils/format';
import { financialPoaKey, ownerPersonKey } from '@/types/commonTypes';
import { inject } from 'vue';

export default {
  setup() {
    const ownerPerson = inject(ownerPersonKey);
    const financialPoa = inject(financialPoaKey);

    const {
      actJointly,
      attorneys,
      hasInclusions,
      hasLimitations,
      hasMultipleAttorneys,
      inclusions,
      hasSecondaries,
      othersMayAct,
      primaryAttorneys,
      secondaryAttorneys,
    } = poa(financialPoa?.value);

    return {
      actJointly,
      attorneys,
      hasInclusions,
      hasLimitations,
      hasMultipleAttorneys,
      inclusions,
      hasSecondaries,
      othersMayAct,
      primaryAttorneys,
      secondaryAttorneys,
      ownerPerson,
      poa: financialPoa,
    };
  },
  methods: {
    mf,
    plural,
    ifPlural,
  },
};
</script>
