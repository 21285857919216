import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { type: "a" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 2 }
const _hoisted_5 = { key: 3 }
const _hoisted_6 = { class: "sign-container" }
const _hoisted_7 = { class: "sign-prefixed" }
const _hoisted_8 = { class: "sign-prefix" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("article", null, [
    _cache[15] || (_cache[15] = _createElementVNode("div", { class: "text-center" }, [
      _createElementVNode("h2", null, "ADVANCE PERSONAL PLAN (FINANCIAL)")
    ], -1)),
    _cache[16] || (_cache[16] = _createElementVNode("h3", null, "SECTION A: PERSONAL DETAILS", -1)),
    _createElementVNode("section", null, [
      _createElementVNode("p", null, [
        _cache[0] || (_cache[0] = _createTextVNode(" I, ")),
        _createElementVNode("strong", null, _toDisplayString($setup.ownerPerson?.fullName), 1),
        _createTextVNode(" " + _toDisplayString($setup.ownerPerson?.fullAddress) + ", born on " + _toDisplayString($setup.ownerPerson?.dateOfBirthFormatted) + ", being over the age of eighteen years, who has decision-making capacity and who does not have a guardian appointed under the ", 1),
        _cache[1] || (_cache[1] = _createElementVNode("em", null, "Guardianship of Adults Act", -1)),
        _cache[2] || (_cache[2] = _createTextVNode(", make this Advance Personal Plan after careful consideration, voluntarily and without coercion or undue influence. "))
      ]),
      _createElementVNode("p", null, " If at any time I am unable to take part in decisions about my care or welfare (including health care) or property or financial affairs, let this document stand as evidence of my health decisions, my views, wishes and beliefs and/or who I nominate as my decision " + _toDisplayString($options.plural('maker', $setup.primaryAttorneys)) + ". ", 1),
      _cache[3] || (_cache[3] = _createElementVNode("p", null, " I request that all who are responsible for my care respect the decisions and directions given in this document. ", -1))
    ]),
    _cache[17] || (_cache[17] = _createElementVNode("section", null, [
      _createElementVNode("h3", null, "SECTION B: ADVANCE CARE STATEMENT"),
      _createElementVNode("p", null, "N/A")
    ], -1)),
    _cache[18] || (_cache[18] = _createElementVNode("section", null, [
      _createElementVNode("h3", null, "SECTION C: ADVANCE CONSENT DECISION"),
      _createElementVNode("p", null, "N/A")
    ], -1)),
    _createElementVNode("section", null, [
      _createElementVNode("h3", null, " SECTION D: APPOINTMENT OF DECISION " + _toDisplayString($options.plural('maker', $setup.primaryAttorneys).toUpperCase()), 1),
      _createElementVNode("ol", null, [
        _createElementVNode("li", null, [
          _cache[4] || (_cache[4] = _createTextVNode(" Appointment of a decision maker is made by me, the Adult, ")),
          _createElementVNode("strong", null, _toDisplayString($setup.ownerPerson?.fullName), 1),
          _createTextVNode(" " + _toDisplayString($setup.ownerPerson?.fullAddress) + ". ", 1)
        ]),
        _createElementVNode("li", null, [
          _createElementVNode("ol", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.primaryAttorneys, (attorney) => {
              return (_openBlock(), _createElementBlock("li", {
                key: attorney.id
              }, [
                _cache[5] || (_cache[5] = _createTextVNode(" To ")),
                _cache[6] || (_cache[6] = _createElementVNode("strong", null, "appoint", -1)),
                _createTextVNode(" as my decision " + _toDisplayString($options.plural('maker', $setup.primaryAttorneys)) + " for financial matters (including dealing in property):", 1),
                _cache[7] || (_cache[7] = _createElementVNode("br", null, null, -1)),
                _createElementVNode("strong", null, _toDisplayString(attorney.directoryPerson.fullName), 1),
                _createTextVNode(" " + _toDisplayString(attorney.directoryPerson.identifiers), 1)
              ]))
            }), 128))
          ])
        ]),
        ($setup.hasMultipleAttorneys)
          ? (_openBlock(), _createElementBlock("li", _hoisted_2, " I would like my decision makers to act " + _toDisplayString($options.mf('attorney_decisions', $setup.poa?.meta)) + ". ", 1))
          : _createCommentVNode("", true),
        ($setup.hasMultipleAttorneys && $setup.actJointly)
          ? (_openBlock(), _createElementBlock("li", _hoisted_3, " I " + _toDisplayString($setup.othersMayAct ? 'do not' : '') + " want the appointment to be terminated if one of my joint decision makers dies, resigns, or otherwise cannot act. ", 1))
          : _createCommentVNode("", true),
        ($setup.hasLimitations)
          ? (_openBlock(), _createElementBlock("li", _hoisted_4, [
              _createTextVNode(" My decision " + _toDisplayString($options.plural('maker', $setup.primaryAttorneys)) + " shall be subject to the following limitations:", 1),
              _cache[8] || (_cache[8] = _createElementVNode("br", null, null, -1)),
              _createTextVNode(" " + _toDisplayString($options.mf('limitations', $setup.poa?.meta)), 1)
            ]))
          : _createCommentVNode("", true),
        ($setup.hasSecondaries)
          ? (_openBlock(), _createElementBlock("li", _hoisted_5, [
              _cache[9] || (_cache[9] = _createTextVNode(" If the appointment of all my decision makers ceases to be in force (due to death, resignation, loss of capacity or vacation of office), I would like ")),
              _createElementVNode("strong", null, _toDisplayString($setup.secondaryAttorneys[0].directoryPerson.fullName), 1),
              _createTextVNode(" " + _toDisplayString($setup.secondaryAttorneys[0].directoryPerson.identifiers) + " to be appointed to act as a secondary decision maker in such circumstances. ", 1)
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("section", null, [
      _cache[13] || (_cache[13] = _createElementVNode("h3", null, "SECTION E: SIGNING AND WITNESSING", -1)),
      _cache[14] || (_cache[14] = _createElementVNode("h4", null, "ADULT MAKING THE ADVANCE PERSONAL PLAN", -1)),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, _toDisplayString($setup.ownerPerson?.fullName), 1),
          _cache[10] || (_cache[10] = _createElementVNode("div", { class: "sign" }, "Name", -1))
        ]),
        _cache[11] || (_cache[11] = _createElementVNode("div", { class: "sign" }, "Signature", -1)),
        _cache[12] || (_cache[12] = _createElementVNode("div", { class: "sign" }, "Date", -1))
      ])
    ]),
    _cache[19] || (_cache[19] = _createStaticVNode("<section><div class=\"sign-container\"><div class=\"sign-prefixed\"><div class=\"sign-prefix\">I,</div><div class=\"sign\">Witness full name</div></div><div class=\"sign-prefixed\"><div class=\"sign-prefix\">of,</div><div class=\"sign\"> </div><div class=\"sign\">Witness address</div></div><div class=\"sign-prefixed\"><div class=\"sign-prefix\">A qualified witness,</div><div class=\"sign\">State qualifications as authorised witness</div></div></div><p> certify that the person making this document is who they purport to be, has attained the age of eighteen years, appears to understand the nature and effect of the Advance Personal Plan, appears to be acting voluntarily without coercion or other undue influence and that the plan was signed by the adult making it, or by their representative, in my presence. </p><div class=\"sign-container\"><div class=\"sign\">Signature</div><div class=\"sign\">Date</div></div></section>", 1))
  ]))
}