<template>
  <div class="container">
    <article>
      <h1>Instructions for signing your Enduring Guardianship - (NSW)</h1>
      <p>
        Thank you for choosing Safewill. Your Enduring Guardianship is almost complete - follow the
        steps below, provided by our legal advisers, to sign your Enduring Guardianship and have it
        witnessed.
      </p>
      <h4><em>Step 1 – Print your Enduring Guardianship</em></h4>
      <p>
        Print your Enduring Guardianship, read it carefully and ensure that you understand it
        completely. If you do not understand what you are signing, we recommend seeking independent
        legal advice.
      </p>
      <h4><em>Step 2 – Find a person to act as witness</em></h4>
      <p>
        Once you have understood your Enduring Guardianship, you will need to find at least one
        person to witness you and your appointed guardian(s) signing the document (and to sign the
        document themselves). An eligible witness can only be someone who is:
      </p>
      <ul>
        <li>an Australian or overseas-registered legal practitioner;</li>
        <li>a registrar of the NSW Local Court; or</li>
        <li>an approved employee of NSW Trustee & Guardian.</li>
      </ul>
      <p>
        Your witness is required to certify that both you and your appointed guardian(s) executed
        the document voluntarily and appeared to understand the effect of the Enduring Guardianship
        appointment. You and your appointed guardian(s) can sign the attestation page at the same
        time, in front of the same witness or at different times with a different witness.
      </p>
      <h4><em>Step 3 – Signing your Enduring Guardianship</em></h4>
      <p>
        Sign using a blue or black pen. You should first sign the Enduring Guardianship in the
        presence of your witness, making sure they are watching you sign and date the document
        before signing themselves. Signing the bottom of each page of the document is not mandatory,
        however we recommend doing so to protect against pages being substituted. You should then
        provide the document to your appointed guardian(s) to sign in front of their witness.
      </p>
      <h4><em>Step 4 – Keep your Enduring Guardianship safe</em></h4>
      <p>
        It is important to store your Enduring Guardianship in a safe place and provide your
        appointed guardian(s) with a copy and instructions for how the original document may be
        accessed. You should also keep a copy with someone you trust like your solicitor or
        accountant.
      </p>
      <h4><em>Note on Revocation</em></h4>
      <p>
        In NSW, signing a new Enduring Guardianship does not automatically revoke an old Enduring
        Guardianship. As such <strong>if you</strong>:
      </p>
      <ul>
        <li>
          have an existing Enduring Guardianship that you would like to revoke before making this
          Enduring Guardianship; <strong>or</strong>
        </li>
        <li>
          would like to change anything in this Enduring Guardianship document after it is signed;
        </li>
      </ul>
      <p>
        please follow the instructions on revocation included at the end of this Enduring
        Guardianship. Please also note that an Enduring Guardian can only be revoked by the
        Principal while they have mental capacity.
      </p>
    </article>
    <AppointmentOfAttorneys />
    <article>
      <h2>Important information</h2>
      <ul>
        <li>
          An Enduring Guardianship appointment is an important document. It allows someone else to
          make medical and lifestyle decisions on your behalf. You should get legal or medical
          advice (or both) before you sign it.
        </li>
        <li>
          It is important that you trust the person you appoint as your Enduring Guardian to make
          appropriate lifestyle decisions on your behalf. It is recommended you inform this person
          of your wishes about lifestyle decisions and involve them in discussions about your views
          or goals. If these change, it is important to let your Enduring Guardian know.
        </li>
        <li>
          An Enduring Guardian can only make lifestyle decisions such as health decisions. You
          should make a Power of Attorney if you want someone to make financial decisions on your
          behalf if you lose capacity.
        </li>
        <li>
          If you appoint more than one Enduring Guardian, you should indicate whether the Enduring
          Guardians are to act jointly, severally or jointly and severally. Enduring Guardians who
          are appointed jointly are only able to make decisions if they all agree about the
          decision. Enduring Guardians who are appointed severally or jointly and severally are able
          to make decisions independently of each other.
        </li>
        <li>
          If you appoint a substitute Enduring Guardian, they will only have authority to act as
          your guardian if the first appointed enduring guardian/s dies, resigns or becomes
          incapacitated.
        </li>
        <li>
          Each Enduring Guardian must sign their acceptance of the appointment for it to be
          effective.
        </li>
        <li>
          If someone signs the appointment on your behalf, they must be at least 18 years old. They
          must not be the person being appointed as an Enduring Guardian. They cannot also witness
          the execution of the appointment.
        </li>
        <li>
          If you marry after you appoint an Enduring Guardian then the appointment will
          automatically be revoked (unless you married your Enduring Guardian).
        </li>
        <li>
          Your Enduring Guardian can resign at any time, by giving you notice in writing. If you
          have lost capacity to make decisions at that time then your Enduring Guardian can only
          resign with the approval of the NSW Civil & Administrative Tribunal.
        </li>
        <li>
          Enduring Guardianship appointments are not automatically accessible on any public
          register. Therefore, it is important that key people are aware of the appointment so they
          can contact the Enduring Guardian if required. You should provide a copy of the Enduring
          Guardianship appointment to your Enduring Guardian and keep a copy in a safe place. You
          should also let close friends or family know about it and give a copy to your solicitor,
          doctor and health service provider.
        </li>
      </ul>
    </article>
    <article>
      <h2>Revocation of existing Enduring Guardian</h2>
      <p>
        If you would like to revoke your existing Enduring Guardian, please follow these steps as
        provided by our legal advisers.
      </p>
      <h4><em>Step 1 - Find an eligible witness</em></h4>
      <p>
        You (principal) will need to find one person to witness and sign the Revocation of Enduring
        Guardian and witness your signing of it.
      </p>
      <p>Who qualifies as an eligible witness?</p>
      <ul>
        <li>Australian legal practitioner</li>
        <li>Registrar of the Local Court</li>
        <li>Overseas-registered foreign lawyer</li>
        <li>Justice of the peace</li>
        <li>Approved employee of NSW Trustee and Guardian or the Office of the Public Guardian</li>
      </ul>
      <h4><em>Step 2 - Complete and sign the Revocation of Enduring Guardian - Form 2</em></h4>
      <p>
        The Revocation of Appointment of Enduring Guardian and the Certificate of Witness can be
        found at the end of this document.
      </p>
      <p>
        You must sign the Revocation of Appointment of Enduring Guardian in the presence of your
        witness. Make sure your witness is watching you sign and date the document. After this your
        witness must also sign the Certificate of Witness.
      </p>
      <p>
        Please note that revocation will not be effective unless you use the above Form 2 which is
        prescribed by the Guardianship Regulation 2010 (NSW).
      </p>
      <h4><em>Step 3 - Give written notice of the Revocation </em></h4>
      <p>
        After you have signed your Revocation of Enduring Guardian and it has been witnessed, you
        must, as soon as practicable, give notice of the Revocation. You can do this by writing a
        letter enclosing a copy of the completed and signed Revocation of Enduring Guardian to:
      </p>
      <ul>
        <li>all of your enduring guardian(s) whose appointment has been revoked; and</li>
        <li>
          anyone else who may be aware of or has dealings with the revoked Enduring Guardian
          document.
        </li>
      </ul>
      <p>
        If you fail to give notice, your enduring guardian(s) may legally continue to make decisions
        on your behalf under the old Enduring Guardian appointment.
      </p>
    </article>
    <Revocation />
  </div>
</template>

<script lang="ts">
import AppointmentOfAttorneys from './components/medical/AppointmentOfAttorneys.vue';
import Revocation from './components/medical/Revocation.vue';

export default {
  components: {
    AppointmentOfAttorneys,
    Revocation,
  },
};
</script>
