<template>
  <article>
    <div class="text-center">
      <h1>ENDURING POWER OF ATTORNEY</h1>
      <p><em>Form 2 Power of Attorney Act 1998</em></p>
    </div>
    <section>
      <h3>SECTION 1: YOUR PERSONAL DETAILS</h3>
      <ul>
        <li>
          Full name: <strong>{{ ownerPerson?.fullName }}</strong>
        </li>
        <li>Address: {{ ownerPerson?.fullAddress.replace('of', '') }}</li>
        <li v-if="ownerPerson?.defaultPhoneNumber">
          Phone number: {{ ownerPerson?.defaultPhoneNumber }}
        </li>
        <li>Email: {{ ownerPerson?.defaultEmailAddress }}</li>
      </ul>
    </section>
    <section>
      <h3>SECTION 2: YOUR VIEWS, WISHES AND PREFERENCES</h3>
      <h4>
        This is what I want my {{ plural('attorney', primaryAttorneys) }} to know about when making
        decisions for me:
      </h4>
      <p>N/A</p>
    </section>
    <section>
      <h3>
        SECTION 3: YOUR
        {{ plural('ATTORNEY', attorneys).toUpperCase() }}
      </h3>
      <ol type="a">
        <li>
          <strong>
            I appoint the
            {{ plural('person', attorneys) }}
            listed below as my
            {{ plural('attorney', attorneys) }}:
          </strong>
          {{ ifPlural('(in no particular order)', '', attorneys) }}
          <ul :key="attorney.id" v-for="attorney of attorneys">
            <li>
              Full name:
              <strong>{{ attorney.directoryPerson.fullName }}</strong>
              <ul>
                <li>Address: {{ attorney.directoryPerson.identifiers.replace('of', '') }}</li>
                <li v-if="attorney.directoryPerson.defaultEmailAddress">
                  Email: {{ attorney.directoryPerson.defaultEmailAddress }}
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <strong>
            I authorise my {{ plural('attorney', primaryAttorneys) }} to exercise power for:
          </strong>
          <ul class="checkboxes">
            <li><strike>personal (including health) matters only</strike></li>
            <li class="checked">
              <strong> financial matters only </strong>
            </li>
            <li>
              <strike>personal (including health) matters and financial matters.</strike>
            </li>
          </ul>
        </li>
        <li>
          <strong>
            I authorise my
            {{ plural('attorney', attorneys) }}
            to exercise power for financial matters
          </strong>
          when I do not have capacity to make decisions for financial matters.
        </li>
        <li v-if="hasMultipleAttorneys && !hasSecondaries">
          <strong>
            I authorise my attorneys to make decisions
            {{ mf('attorney_decisions', poa?.meta) }} </strong
          >.
        </li>
        <li v-else-if="hasMultipleAttorneys && hasSecondaries">
          <strong>I have appointed </strong>
          <template :key="attorney.id" v-for="(attorney, i) of primaryAttorneys">
            <strong>{{ attorney.directoryPerson.fullName }}</strong>
            {{ i === primaryAttorneys?.length - 1 ? '' : ' and ' }}
          </template>
          as my {{ plural('attorney', primaryAttorneys) }} for financial matters to act
          {{ mf('attorney_decisions', poa?.meta) }}. If all of my attorneys are unable to act due to
          death or lack of capacity or vacation of office,
          <strong>I appoint {{ secondaryAttorneys[0].directoryPerson.fullName }}</strong>
          as my successive attorney.
        </li>
        <li v-else-if="!hasMultipleAttorneys && hasSecondaries">
          <strong>I have appointed </strong>
          <template :key="attorney.id" v-for="(attorney, i) of primaryAttorneys">
            <strong>{{ attorney.directoryPerson.fullName }}</strong>
            {{ i === primaryAttorneys?.length - 1 ? '' : ' and ' }}
          </template>
          as my {{ plural('attorney', primaryAttorneys) }} for financial matters. If my
          {{ plural('attorney', primaryAttorneys) }}
          {{ ifPlural('are', 'is', primaryAttorneys) }} unable to act due to death or lack of
          capacity or vacation of office,
          <strong>I appoint {{ secondaryAttorneys[0].directoryPerson.fullName }}</strong>
          as my successive attorney.
        </li>
        <li>
          <strong>
            The general terms and instructions for my
            {{ plural('attorney', attorneys) }}
            {{ ifPlural('are', 'is', attorneys) }}
            as follows:
          </strong>
          <br />
          <template v-if="hasLimitations">
            {{ mf('limitations', poa?.meta) }}
          </template>
          <template v-else> N/A </template>
        </li>
      </ol>
    </section>
    <section>
      <h3>SECTION 4: DECLARATION AND SIGNATURES</h3>
      <h4>PRINCIPAL'S SIGNATURE</h4>
      <ol>
        <li>
          <strong>By signing this document, I confirm that:</strong>
          <ul>
            <li>I am making this enduring power of attorney freely and voluntary.</li>
          </ul>
          <strong>AND</strong>
          <ul>
            <li>
              I understand the nature and effect of this enduring power of attorney, including:
              <ul>
                <li>
                  that I may specify or limit my
                  {{ plural('attorney', attorneys) }}{{ ifPlural("'", "'s", attorneys) }}
                  power and instruct my
                  {{ plural('attorney', attorneys) }}
                  about the exercise of the power
                </li>
                <li>
                  when the power given to my
                  {{ plural('attorney', attorneys) }}
                  begins
                </li>
                <li>
                  that once the power for a matter begins, my
                  {{ plural('attorney', attorneys) }}
                  will have full control and power to make decisions about the matter, subject to
                  any terms or information included in this enduring power of attorney
                </li>
                <li>
                  that I may revoke this enduring power of attorney at any time if I am capable of
                  making another enduring power of attorney giving the same power
                </li>
                <li>
                  that the power I am giving to my
                  {{ plural('attorney', attorneys) }}
                  continues even if I do not have capacity to make decisions about the matter
                </li>
                <li>
                  that if I am not capable of revoking the enduring power of attorney, I am unable
                  to effectively oversee the use of the power given to my
                  {{ plural('attorney', attorneys) }}
                  by this document.
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ol>
    </section>
    <section>
      <h3>ONLY SIGN THIS PART IN FRONT OF AN ELIGIBLE WITNESS</h3>
      <h4>Principal’s Signature:</h4>
      <div class="sign-container">
        <div class="sign-prefixed">
          <div class="sign-prefix">
            {{ ownerPerson?.fullName }}
          </div>
          <div class="sign">Name</div>
        </div>
        <div class="sign">Signature</div>
        <div class="sign">Date</div>
      </div>
      <h4>Witness Signature:</h4>
      <div class="sign-container">
        <div class="sign">Signature</div>
        <div class="sign">Date</div>
      </div>
    </section>
    <section>
      <h4>WITNESS CERTIFICATE</h4>
      <ol start="2">
        <li>
          <p>
            <strong>By signing this document, I certify that</strong> the principal signed this
            enduring power of attorney in my presence and I am a (tick one box only):
          </p>
          <ul class="checkboxes">
            <li>justice of the peace (JP)</li>
            <li>commissioner for declarations</li>
            <li>lawyer</li>
            <li>notary public</li>
          </ul>
          <p>I am not</p>
          <ul>
            <li>the person signing the document for the principal</li>
            <li>an attorney of the principal</li>
            <li>a relation of the principal or relation of an attorney of the principal</li>
            <li>
              if this enduring power of attorney appoints an attorney for personal matters
              (including health matters), a paid carer or health provider of the principal.
            </li>
          </ul>
          <p>
            At the time of making this enduring power of attorney the principal appears to me to
            have the capacity to make the enduring power of attorney. The principal appears to:
          </p>
          <ul>
            <li>understand the nature and effect of this enduring power of attorney</li>
            <li>be capable of making the enduring power of attorney freely and voluntarily.</li>
          </ul>
          <p>
            This document has
            <sub class="underline"></sub>
            pages.
          </p>
          <div class="sign-container">
            <div class="sign">Witness Signature</div>
            <div class="sign">Witness Name</div>
            <div class="sign">Date</div>
          </div>
        </li>
      </ol>
    </section>
    <section>
      <h3>
        SECTION 5:
        {{ plural('ATTORNEY', attorneys).toUpperCase() }}{{ ifPlural("'", "'S", attorneys) }}
        ACCEPTANCE
      </h3>
      <p>
        In signing this enduring power of attorney I accept the appointment in accordance with the
        terms of this enduring power of attorney and confirm that:
      </p>
      <ul>
        <li>
          I have <strong>read</strong> this enduring power of attorney and I understand that I
          <strong>must</strong> make decisions and exercise power in accordance with this enduring
          power of attorney, the <em>Powers of Attorney Act 1998</em> and the
          <em>Guardianship and Administration Act 2000</em>.
        </li>
        <li>
          I understand:
          <ul>
            <li>
              in exercising my powers I must apply the general principles and if I exercise powers
              for health care matters, the health care principles under the
              <em>Powers of Attorney Act 1998</em> and the
              <em>Guardianship and Administration Act 2000</em>
            </li>
            <li>
              the obligations of an attorney under an enduring power of attorney and the
              consequences of failing to comply with those obligations.
            </li>
          </ul>
        </li>
        <li>
          I declare that:
          <ul>
            <li>I have capacity for the matter that I am appointed for</li>
            <li>I am 18 years or older</li>
            <li>
              I am not a paid carer for the principal and have not been a paid carer for the
              principal within the previous three (3) years
            </li>
            <li>I am not a health provider for the principal</li>
            <li>
              I am not a service provider for a residential service where the principal is a
              resident
            </li>
            <li>
              if I am appointed for financial matters, I am not bankrupt or taking advantage of the
              laws of bankruptcy as a debtor under the Bankruptcy Act 1966 (Cwlth) or a similar law
              of a foreign jurisdiction.
            </li>
          </ul>
        </li>
      </ul>
      <div class="sign-container" :key="attorney.id" v-for="attorney of primaryAttorneys">
        <div class="sign-prefixed">
          <div class="sign-prefix">
            {{ attorney.directoryPerson.fullName }}
          </div>
          <div class="sign">Name</div>
        </div>
        <div class="sign">Signature</div>
        <div class="sign">Date</div>
      </div>
      <div class="sign-container" :key="attorney.id" v-for="attorney of secondaryAttorneys">
        <div class="sign-prefixed">
          <div class="sign-prefix">
            {{ attorney.directoryPerson.fullName }}
          </div>
          <div class="sign">Name</div>
        </div>
        <div class="sign">Signature</div>
        <div class="sign">Date</div>
      </div>
    </section>
  </article>
</template>

<script lang="ts">
import { inject } from 'vue';
import poa from '@/hooks/poa';
import { mf, plural, ifPlural } from '@/utils/format';
import { financialPoaKey, ownerPersonKey } from '@/types/commonTypes';

export default {
  setup() {
    const ownerPerson = inject(ownerPersonKey);
    const financialPoa = inject(financialPoaKey);

    const {
      actJointly,
      attorneys,
      hasInclusions,
      hasLimitations,
      hasMultipleAttorneys,
      inclusions,
      hasSecondaries,
      othersMayAct,
      primaryAttorneys,
      secondaryAttorneys,
    } = poa(financialPoa?.value);

    return {
      actJointly,
      attorneys,
      hasInclusions,
      hasLimitations,
      hasMultipleAttorneys,
      inclusions,
      hasSecondaries,
      othersMayAct,
      primaryAttorneys,
      secondaryAttorneys,
      ownerPerson,
      poa: financialPoa,
    };
  },
  methods: {
    mf,
    plural,
    ifPlural,
  },
};
</script>
