<template>
  <article>
    <div class="text-center">
      <h2>ADVANCE PERSONAL PLAN (FINANCIAL)</h2>
    </div>
    <h3>SECTION A: PERSONAL DETAILS</h3>
    <section>
      <p>
        I, <strong>{{ ownerPerson?.fullName }}</strong> {{ ownerPerson?.fullAddress }}, born on
        {{ ownerPerson?.dateOfBirthFormatted }}, being over the age of eighteen years, who has
        decision-making capacity and who does not have a guardian appointed under the
        <em>Guardianship of Adults Act</em>, make this Advance Personal Plan after careful
        consideration, voluntarily and without coercion or undue influence.
      </p>
      <p>
        If at any time I am unable to take part in decisions about my care or welfare (including
        health care) or property or financial affairs, let this document stand as evidence of my
        health decisions, my views, wishes and beliefs and/or who I nominate as my decision
        {{ plural('maker', primaryAttorneys) }}.
      </p>
      <p>
        I request that all who are responsible for my care respect the decisions and directions
        given in this document.
      </p>
    </section>
    <section>
      <h3>SECTION B: ADVANCE CARE STATEMENT</h3>
      <p>N/A</p>
    </section>
    <section>
      <h3>SECTION C: ADVANCE CONSENT DECISION</h3>
      <p>N/A</p>
    </section>
    <section>
      <h3>
        SECTION D: APPOINTMENT OF DECISION
        {{ plural('maker', primaryAttorneys).toUpperCase() }}
      </h3>
      <ol>
        <li>
          Appointment of a decision maker is made by me, the Adult,
          <strong>{{ ownerPerson?.fullName }}</strong> {{ ownerPerson?.fullAddress }}.
        </li>
        <li>
          <ol type="a">
            <li :key="attorney.id" v-for="attorney of primaryAttorneys">
              To <strong>appoint</strong> as my decision {{ plural('maker', primaryAttorneys) }} for
              financial matters (including dealing in property):<br />
              <strong>{{ attorney.directoryPerson.fullName }}</strong>
              {{ attorney.directoryPerson.identifiers }}
            </li>
          </ol>
        </li>
        <li v-if="hasMultipleAttorneys">
          I would like my decision makers to act
          {{ mf('attorney_decisions', poa?.meta) }}.
        </li>
        <li v-if="hasMultipleAttorneys && actJointly">
          I {{ othersMayAct ? 'do not' : '' }} want the appointment to be terminated if one of my
          joint decision makers dies, resigns, or otherwise cannot act.
        </li>
        <li v-if="hasLimitations">
          My decision {{ plural('maker', primaryAttorneys) }} shall be subject to the following
          limitations:<br />
          {{ mf('limitations', poa?.meta) }}
        </li>
        <li v-if="hasSecondaries">
          If the appointment of all my decision makers ceases to be in force (due to death,
          resignation, loss of capacity or vacation of office), I would like
          <strong>{{ secondaryAttorneys[0].directoryPerson.fullName }}</strong>
          {{ secondaryAttorneys[0].directoryPerson.identifiers }} to be appointed to act as a
          secondary decision maker in such circumstances.
        </li>
      </ol>
    </section>
    <section>
      <h3>SECTION E: SIGNING AND WITNESSING</h3>
      <h4>ADULT MAKING THE ADVANCE PERSONAL PLAN</h4>
      <div class="sign-container">
        <div class="sign-prefixed">
          <div class="sign-prefix">
            {{ ownerPerson?.fullName }}
          </div>
          <div class="sign">Name</div>
        </div>
        <div class="sign">Signature</div>
        <div class="sign">Date</div>
      </div>
    </section>
    <section>
      <div class="sign-container">
        <div class="sign-prefixed">
          <div class="sign-prefix">I,</div>
          <div class="sign">Witness full name</div>
        </div>
        <div class="sign-prefixed">
          <div class="sign-prefix">of,</div>
          <div class="sign">&nbsp;</div>
          <div class="sign">Witness address</div>
        </div>
        <div class="sign-prefixed">
          <div class="sign-prefix">A qualified witness,</div>
          <div class="sign">State qualifications as authorised witness</div>
        </div>
      </div>
      <p>
        certify that the person making this document is who they purport to be, has attained the age
        of eighteen years, appears to understand the nature and effect of the Advance Personal Plan,
        appears to be acting voluntarily without coercion or other undue influence and that the plan
        was signed by the adult making it, or by their representative, in my presence.
      </p>
      <div class="sign-container">
        <div class="sign">Signature</div>
        <div class="sign">Date</div>
      </div>
    </section>
  </article>
</template>

<script lang="ts">
import poa from '@/hooks/poa';
import { mf, plural, ifPlural } from '@/utils/format';
import { financialPoaKey, ownerPersonKey } from '@/types/commonTypes';
import { inject } from 'vue';

export default {
  setup() {
    const ownerPerson = inject(ownerPersonKey);
    const financialPoa = inject(financialPoaKey);

    const {
      actJointly,
      attorneys,
      hasInclusions,
      hasLimitations,
      hasMultipleAttorneys,
      inclusions,
      hasSecondaries,
      othersMayAct,
      primaryAttorneys,
      secondaryAttorneys,
    } = poa(financialPoa?.value);

    return {
      actJointly,
      attorneys,
      hasInclusions,
      hasLimitations,
      hasMultipleAttorneys,
      inclusions,
      hasSecondaries,
      othersMayAct,
      primaryAttorneys,
      secondaryAttorneys,
      ownerPerson,
      poa: financialPoa,
    };
  },
  methods: {
    mf,
    plural,
    ifPlural,
  },
};
</script>
