import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { type: "1" }
const _hoisted_2 = { type: "a" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  type: "1",
  start: "2"
}
const _hoisted_5 = { type: "a" }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = ["start"]
const _hoisted_8 = ["start"]
const _hoisted_9 = ["start"]
const _hoisted_10 = { class: "checkboxes" }
const _hoisted_11 = ["start"]
const _hoisted_12 = { class: "checkboxes" }
const _hoisted_13 = {
  key: 0,
  class: "checked"
}
const _hoisted_14 = { key: 1 }
const _hoisted_15 = ["start"]
const _hoisted_16 = ["start"]
const _hoisted_17 = { class: "sign-container" }
const _hoisted_18 = { class: "sign-prefixed" }
const _hoisted_19 = { class: "sign-prefix" }
const _hoisted_20 = ["start"]
const _hoisted_21 = { class: "sign-prefixed" }
const _hoisted_22 = { class: "sign-prefix" }
const _hoisted_23 = { class: "sign-prefixed" }
const _hoisted_24 = { class: "sign-prefix" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_strike = _resolveComponent("strike")!

  return (_openBlock(), _createElementBlock("article", null, [
    _cache[36] || (_cache[36] = _createElementVNode("div", { class: "text-center" }, [
      _createElementVNode("h1", null, "ENDURING POWER OF ATTORNEY"),
      _createElementVNode("p", null, [
        _createElementVNode("em", null, "Powers of Attorney Act 2006")
      ])
    ], -1)),
    _createElementVNode("section", null, [
      _cache[2] || (_cache[2] = _createElementVNode("h3", null, "APPOINTOR AND ATTORNEY", -1)),
      _createElementVNode("ol", _hoisted_1, [
        _createElementVNode("li", null, [
          _cache[0] || (_cache[0] = _createTextVNode(" I, ")),
          _createElementVNode("strong", null, _toDisplayString($setup.ownerPerson?.fullName), 1),
          _createTextVNode(" " + _toDisplayString($setup.ownerPerson?.fullAddress) + " (Appointor/Principal), ", 1),
          _cache[1] || (_cache[1] = _createElementVNode("strong", null, "appoint", -1)),
          _createTextVNode(" the following as my " + _toDisplayString($options.plural('attorney', $setup.primaryAttorneys)) + ": ", 1),
          _createElementVNode("ol", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.primaryAttorneys, (attorney) => {
              return (_openBlock(), _createElementBlock("li", {
                key: attorney.id
              }, [
                _createElementVNode("strong", null, _toDisplayString(attorney.directoryPerson.fullName), 1),
                _createTextVNode(" " + _toDisplayString(attorney.directoryPerson.identifiers), 1)
              ]))
            }), 128))
          ])
        ])
      ])
    ]),
    ($setup.hasSecondaries)
      ? (_openBlock(), _createElementBlock("section", _hoisted_3, [
          _cache[3] || (_cache[3] = _createElementVNode("h3", null, "SUBSTITUTE ATTORNEY", -1)),
          _createElementVNode("ol", _hoisted_4, [
            _createElementVNode("li", null, [
              _createTextVNode(" If " + _toDisplayString($options.ifPlural('all of my attorneys are ', 'my attorney is', $setup.primaryAttorneys)) + " unwilling or unable to accept their appointment or to continue in their appointment, I appoint the following as my " + _toDisplayString($options.plural('attorney', $setup.primaryAttorneys)) + ": ", 1),
              _createElementVNode("ol", _hoisted_5, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.secondaryAttorneys, (attorney) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: attorney.id
                  }, [
                    _createElementVNode("strong", null, _toDisplayString(attorney.directoryPerson.fullName), 1),
                    _createTextVNode(" " + _toDisplayString(attorney.directoryPerson.identifiers), 1)
                  ]))
                }), 128))
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    ($setup.hasMultipleAttorneys)
      ? (_openBlock(), _createElementBlock("section", _hoisted_6, [
          _cache[4] || (_cache[4] = _createElementVNode("h3", null, "MULTIPLE ATTORNEYS", -1)),
          _createElementVNode("ol", {
            type: "1",
            start: 1 + $setup.hasSecondaries + $setup.hasMultipleAttorneys
          }, [
            _createElementVNode("li", null, " I appoint my attorneys to act " + _toDisplayString($options.mf('attorney_decisions', $setup.poa?.meta)
              .replace('jointly', 'together')
              .replace('or', 'and')
              .replace('severally', 'separately')) + ". ", 1)
          ], 8, _hoisted_7)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("section", null, [
      _cache[5] || (_cache[5] = _createElementVNode("h3", null, "AUTHORITY TO SOMEONE ELSE", -1)),
      _createElementVNode("ol", {
        type: "1",
        start: 2 + $setup.hasSecondaries + $setup.hasMultipleAttorneys
      }, [
        _createElementVNode("li", null, " My " + _toDisplayString($options.plural('attorney', $setup.primaryAttorneys)) + " must not authorise anyone else to exercise my " + _toDisplayString($options.plural('attorney', $setup.primaryAttorneys)) + _toDisplayString($options.ifPlural("'", "'s", $setup.primaryAttorneys)) + " powers. ", 1)
      ], 8, _hoisted_8)
    ]),
    _createElementVNode("section", null, [
      _cache[10] || (_cache[10] = _createElementVNode("h3", null, "FUNCTIONS", -1)),
      _createElementVNode("ol", {
        type: "1",
        start: 3 + $setup.hasSecondaries + $setup.hasMultipleAttorneys
      }, [
        _createElementVNode("li", null, [
          _createTextVNode(" I authorise my " + _toDisplayString($options.plural('attorney', $setup.primaryAttorneys)) + " to do, on my behalf, anything that I can lawfully do in relation to the matters specified below ", 1),
          _createElementVNode("ul", _hoisted_10, [
            _cache[9] || (_cache[9] = _createElementVNode("li", { class: "checked" }, "property matters (includes financial matters)", -1)),
            _createElementVNode("li", null, [
              _createVNode(_component_strike, null, {
                default: _withCtx(() => _cache[6] || (_cache[6] = [
                  _createTextVNode("personal care matters")
                ])),
                _: 1
              })
            ]),
            _createElementVNode("li", null, [
              _createVNode(_component_strike, null, {
                default: _withCtx(() => _cache[7] || (_cache[7] = [
                  _createTextVNode("health care matters")
                ])),
                _: 1
              })
            ]),
            _createElementVNode("li", null, [
              _createVNode(_component_strike, null, {
                default: _withCtx(() => _cache[8] || (_cache[8] = [
                  _createTextVNode("medical research matters (This authorisation must be carried out in accordance with part 4.3A of the "),
                  _createElementVNode("em", null, "Powers of Attorney Act 2006", -1),
                  _createTextVNode(")")
                ])),
                _: 1
              })
            ])
          ])
        ])
      ], 8, _hoisted_9)
    ]),
    _createElementVNode("section", null, [
      _cache[17] || (_cache[17] = _createElementVNode("h3", null, "DIRECTIONS, LIMITATIONS AND CONDITIONS", -1)),
      _createElementVNode("ol", {
        type: "1",
        start: 4 + $setup.hasSecondaries + $setup.hasMultipleAttorneys
      }, [
        _createElementVNode("li", null, [
          _createTextVNode(" My " + _toDisplayString($options.plural('attorney', $setup.primaryAttorneys)) + " shall only exercise power under the section above, subject to the following directions, limitations and conditions: ", 1),
          _createElementVNode("ul", _hoisted_12, [
            ($setup.hasLimitations)
              ? (_openBlock(), _createElementBlock("li", _hoisted_13, [
                  _cache[11] || (_cache[11] = _createTextVNode(" property matters (includes financial matters)")),
                  _cache[12] || (_cache[12] = _createElementVNode("br", null, null, -1)),
                  _createTextVNode(" " + _toDisplayString($options.mf('limitations', $setup.poa?.meta)), 1)
                ]))
              : (_openBlock(), _createElementBlock("li", _hoisted_14, [
                  _createVNode(_component_strike, null, {
                    default: _withCtx(() => _cache[13] || (_cache[13] = [
                      _createTextVNode("property matters (includes financial matters)")
                    ])),
                    _: 1
                  })
                ])),
            _createElementVNode("li", null, [
              _createVNode(_component_strike, null, {
                default: _withCtx(() => _cache[14] || (_cache[14] = [
                  _createTextVNode("personal care matters")
                ])),
                _: 1
              })
            ]),
            _createElementVNode("li", null, [
              _createVNode(_component_strike, null, {
                default: _withCtx(() => _cache[15] || (_cache[15] = [
                  _createTextVNode("health care matters")
                ])),
                _: 1
              })
            ]),
            _createElementVNode("li", null, [
              _createVNode(_component_strike, null, {
                default: _withCtx(() => _cache[16] || (_cache[16] = [
                  _createTextVNode("medical research matters")
                ])),
                _: 1
              })
            ])
          ])
        ])
      ], 8, _hoisted_11)
    ]),
    _createElementVNode("section", null, [
      _cache[19] || (_cache[19] = _createElementVNode("h3", null, "COMMENCEMENT", -1)),
      _createElementVNode("ol", {
        type: "1",
        start: 5 + $setup.hasSecondaries + $setup.hasMultipleAttorneys
      }, _cache[18] || (_cache[18] = [
        _createElementVNode("li", null, " My attorneys' power in relation to my property (including financial) matters comes into effect only when I become a person with impaired decision-making capacity. ", -1)
      ]), 8, _hoisted_15)
    ]),
    _createElementVNode("section", null, [
      _cache[24] || (_cache[24] = _createElementVNode("h3", null, "STATEMENT OF UNDERSTANDING", -1)),
      _createElementVNode("ol", {
        type: "1",
        start: 6 + $setup.hasSecondaries + $setup.hasMultipleAttorneys
      }, _cache[20] || (_cache[20] = [
        _createElementVNode("li", null, [
          _createElementVNode("strong", null, "PRINCIPAL")
        ], -1)
      ]), 8, _hoisted_16),
      _createElementVNode("p", null, " I fully understand that, by making this enduring power of attorney, I authorise my " + _toDisplayString($options.plural('attorney', $setup.primaryAttorneys)) + " to act on my behalf in accordance with the terms set out in this enduring power of attorney. I also understand the nature and effect of making an enduring power of attorney as set out in Schedule 3 to this document. ", 1),
      _createElementVNode("div", _hoisted_17, [
        _createElementVNode("div", _hoisted_18, [
          _createElementVNode("div", _hoisted_19, _toDisplayString($setup.ownerPerson?.fullName), 1),
          _cache[21] || (_cache[21] = _createElementVNode("div", { class: "sign" }, "Name", -1))
        ]),
        _cache[22] || (_cache[22] = _createElementVNode("div", { class: "sign" }, "Signature", -1)),
        _cache[23] || (_cache[23] = _createElementVNode("div", { class: "sign" }, "Date", -1))
      ])
    ]),
    _createElementVNode("section", null, [
      _cache[26] || (_cache[26] = _createElementVNode("h3", null, "CERTIFICATE OF WITNESSES", -1)),
      _createElementVNode("ol", {
        type: "1",
        start: 7 + $setup.hasSecondaries + $setup.hasMultipleAttorneys
      }, _cache[25] || (_cache[25] = [
        _createElementVNode("li", null, [
          _createElementVNode("strong", null, "WITNESS")
        ], -1)
      ]), 8, _hoisted_20),
      _cache[27] || (_cache[27] = _createStaticVNode("<p> (Note: Refer to the signing instructions for information about who can be a witness. The witnesses must sign in the presence of each other and the appointor.) </p><h4>WITNESS 1</h4><p>I, witness 1 described below:</p><ol type=\"i\"><li>am an adult;</li><li>am authorised to witness the signing of a statutory declaration;</li><li>am not appointed as attorney under this enduring power of attorney; and</li><li>did not sign this enduring power of attorney for the principal.</li></ol><p>I certify that:</p><ol type=\"a\"><li> the principal signed this enduring power of attorney voluntarily in my presence; and </li><li> at the time the principal signed this enduring power of attorney, the principal appeared to me to understand the nature and effect of making it as set out in Schedule 3 to this document. </li></ol><div class=\"sign-container\"><div class=\"sign\">Signature of witness 1</div><div class=\"sign\">Name</div><div class=\"sign\">Qualification</div><div class=\"sign\">Address</div><div class=\"sign\">Date</div></div>", 7))
    ]),
    _cache[37] || (_cache[37] = _createStaticVNode("<section><h4>WITNESS 2</h4><p>I, witness 2 described below:</p><ol type=\"i\"><li>am an adult;</li><li>am authorised to witness the signing of a statutory declaration;</li><li>am not appointed as attorney under this enduring power of attorney; and</li><li>did not sign this enduring power of attorney for the principal.</li></ol><p>I certify that:</p><ol type=\"a\"><li> the principal signed this enduring power of attorney voluntarily in my presence; and </li><li> at the time the principal signed this enduring power of attorney, the principal appeared to me to understand the nature and effect of making it as set out in Schedule 3 to this document. </li></ol><div class=\"sign-container\"><div class=\"sign\">Signature of witness 2</div><div class=\"sign\">Name</div><div class=\"sign\">Qualification</div><div class=\"sign\">Address</div><div class=\"sign\">Date</div></div></section>", 1)),
    _createElementVNode("section", null, [
      _createElementVNode("h3", null, "ACCEPTANCE BY " + _toDisplayString($options.plural('ATTORNEY', $setup.primaryAttorneys).toUpperCase()) + " OF APPOINTMENT", 1),
      _cache[34] || (_cache[34] = _createElementVNode("p", null, " I have read this enduring power of attorney which appoints me as attorney for the principal. I understand that by signing this acceptance of my appointment, I undertake the responsibility of exercising the powers which I have been given, including the responsibilities and obligations set out in Schedule 2 to this document. ", -1)),
      _cache[35] || (_cache[35] = _createElementVNode("p", null, "I accept my appointment as Attorney:", -1)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.primaryAttorneys, (attorney) => {
        return (_openBlock(), _createElementBlock("div", {
          key: attorney.id,
          class: "sign-container"
        }, [
          _createElementVNode("div", _hoisted_21, [
            _createElementVNode("div", _hoisted_22, _toDisplayString(attorney.directoryPerson.fullName), 1),
            _cache[28] || (_cache[28] = _createElementVNode("div", { class: "sign" }, "Name", -1))
          ]),
          _cache[29] || (_cache[29] = _createElementVNode("div", { class: "sign" }, "Signature", -1)),
          _cache[30] || (_cache[30] = _createElementVNode("div", { class: "sign" }, "Date", -1))
        ]))
      }), 128)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.secondaryAttorneys, (attorney) => {
        return (_openBlock(), _createElementBlock("div", {
          key: attorney.id,
          class: "sign-container"
        }, [
          _createElementVNode("div", _hoisted_23, [
            _createElementVNode("div", _hoisted_24, _toDisplayString(attorney.directoryPerson.fullName), 1),
            _cache[31] || (_cache[31] = _createElementVNode("div", { class: "sign" }, "Name", -1))
          ]),
          _cache[32] || (_cache[32] = _createElementVNode("div", { class: "sign" }, "Signature", -1)),
          _cache[33] || (_cache[33] = _createElementVNode("div", { class: "sign" }, "Date", -1))
        ]))
      }), 128))
    ])
  ]))
}