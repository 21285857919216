<template>
  <div class="container">
    <article>
      <h1>Instructions for signing your Power of Attorney - Financial (NSW)</h1>
      <p>
        Thank you for choosing Safewill. Your Enduring Power of Attorney is almost complete - follow
        the steps below, provided by our legal advisers, to sign your Power of Attorney and have it
        witnessed.
      </p>
      <h4><em>Step 1 - Print your Enduring Power of Attorney</em></h4>
      <p>
        Print your Enduring Power of Attorney, read it carefully and ensure that you understand it
        completely. If you do not understand what you are signing, we recommend seeking independent
        legal advice.
      </p>
      <h4><em>Step 2 - Find a witness</em></h4>
      <p>
        Once you have understood your Enduring Power of Attorney, you will need to find at least one
        person to witness and sign the document and witness your signing of it. The Enduring Power
        of Attorney can only be witnessed by someone who you have not appointed as an attorney and
        who is one of the following:
      </p>
      <ul>
        <li>A registrar of the NSW Local Court</li>
        <li>An Australian legal practitioner (eg a solicitor or barrister)</li>
        <li>
          A licensed Conveyancer or an employee of NSW Trustee & Guardian or a Private Trustee
          company, in each case who has completed an approved course under the Powers of Attorney
          Act
        </li>
        <li>
          A legal practitioner qualified in a country other than Australia, instructed independently
          of any legal practitioner appointed as an attorney under this instrument
        </li>
      </ul>
      <p>
        Your witness is required to confirm that they explained and you appeared to understand the
        effect of the Enduring Power of Attorney before it was signed. The witness must complete the
        certificate included in the Enduring Power of Attorney below.
      </p>
      <h4><em>Step 3 – Signing your Enduring Power of Attorney</em></h4>
      <p>
        Sign using a blue or black pen. You should first sign the Enduring Power of Attorney in the
        presence of your witness before providing the document to your attorney(s) to sign. Make
        sure your witness is watching you sign and date the document. After this your witness must
        also sign where indicated. Signing the bottom of each page of the document is not mandatory,
        however we recommend doing so to protect against pages being substituted. After this, you
        will need to provide the document to your attorneys to sign and accept their appointment.
      </p>
      <h4><em>Step 4 – Keep your Enduring Power of Attorney</em></h4>
      <p>
        It is important to store your Enduring Power of Attorney in a safe place and provide your
        attorney with a copy. You should also keep a copy with someone you trust like your solicitor
        or accountant.
      </p>
      <h4><em>Note on Revocation</em></h4>
      <p>
        In NSW, signing a new Enduring Power of Attorney does not automatically revoke an old
        Enduring Power of Attorney. As such <strong>if you</strong>:
      </p>
      <ul>
        <li>
          have an existing Enduring Power of Attorney that you would like to revoke before making
          this Enduring Power of Attorney; <strong>or</strong>
        </li>
        <li>
          would like to change anything in this Enduring Power of Attorney document after it is
          signed;
        </li>
      </ul>
      <p>
        please follow the instructions on revocation included at the end of this Enduring Power of
        Attorney. Please also note that an Enduring Power of Attorney can only be revoked by the
        Principal while they have mental capacity.
      </p>
    </article>
    <article>
      <h2>Form 2 - Enduring Power of Attorney</h2>
      <h3>BACKGROUND INFORMATION</h3>
      <p>
        An enduring power of attorney is a legal document that allows you (the principal) to
        nominate one or more persons (referred to as attorneys) to act on your behalf. An enduring
        power of attorney gives the attorney the authority to manage your legal and financial
        affairs, including buying and selling real estate, shares and other assets, operating your
        bank accounts and spending money on your behalf.
      </p>
      <p>
        The attorney’s power continues even if for any reason you lose your mental capacity to
        manage your own affairs. Once you lose your mental capacity you cannot revoke this power of
        attorney. If you want the power of attorney to cease if you lose your mental capacity, use
        the general power of attorney form. An attorney under an enduring power of attorney cannot
        make decisions about your lifestyle or health. These decisions can only be made by a
        guardian (whether an enduring guardian appointed by you or a guardian appointed by the Civil
        and Administrative Tribunal or the Supreme Court).
      </p>
      <p>
        The prescribed witness certificate in this form must be completed. Before acting as your
        attorney, the attorney (including any substitute attorney) must sign the acceptance in this
        form.
      </p>
      <p>
        Please read the Important information set out at the end of this document. It includes notes
        to assist in completing this document and more fully explains the role and responsibilities
        of an attorney.
      </p>
    </article>
    <AppointmentOfAttorneys />
    <article>
      <h2>Important Information</h2>
      <ul>
        <li>
          A power of attorney is an important and powerful legal document. You should get legal
          advice before you sign it.
        </li>
        <li>
          It is important that you trust the person you are appointing as attorney to make financial
          decisions on your behalf. Your attorney must be over 18 years old and must not be bankrupt
          or insolvent. If your financial affairs are complicated, you should appoint an attorney
          who has the skills to deal with complex financial arrangements.
        </li>
        <li>
          A power of attorney cannot be used for health or lifestyle decisions. You should appoint
          an enduring guardian under the Guardianship Act 1987 if you want a particular person to
          make these decisions. For further information, contact the Civil and Administrative
          Tribunal or NSW Trustee and Guardian.
        </li>
        <li>
          This power of attorney is for use in New South Wales only. If you need a power of attorney
          for interstate or overseas, you may need to make a power of attorney under their laws. The
          laws of some other States and Territories in Australia may give effect to this power of
          attorney. However, you should not assume this will be the case. You should confirm whether
          the laws of the State or Territory concerned will in fact recognise this power of
          attorney.
        </li>
        <li>
          Your attorney must keep the attorney’s own money and property separate from your money and
          property, unless you are joint owners, or operate joint bank accounts. Your attorney
          should keep reasonable accounts and records about your money and property. The cost of
          providing and maintaining these records by the attorney may be recoverable from you.
        </li>
        <li>
          If your attorney is signing certain documents that affect real estate, the power of
          attorney must be registered at Land and Property Information. Please contact LPI on 1300
          052 637 to see whether the power of attorney must be registered.
        </li>
        <li>
          An attorney must always act in your best interest. If your attorney does not follow your
          directions or does not act in your best interest, you should consider revoking the power
          of attorney. You will only be able to do so while you retain your mental capacity. If you
          revoke the power of attorney you should notify the attorney, preferably in writing, that
          they are no longer your attorney. The attorney must stop acting immediately once they have
          knowledge of the revocation.
        </li>
        <li>
          This power of attorney does not automatically revoke earlier powers of attorney made by
          you. If you have made an earlier power of attorney that you do not want to continue, you
          must revoke the earlier power of attorney. It is advisable that you notify the attorney,
          preferably in writing, of the revocation, if you have not already done so. You should also
          give notice of the revocation to anyone who is aware of the earlier power of attorney,
          such as a bank.
        </li>
      </ul>
    </article>
    <article>
      <h2>Revocation of Existing Enduring Power of Attorney</h2>
      <p>
        If you would like to revoke an existing signed Enduring Power of Attorney, please follow
        these steps.
      </p>
      <h4><em>Step 1 - Find a witness</em></h4>
      <p>
        You (Principal) will need to find one person to witness and sign the Revocation of Enduring
        Power of Attorney and witness your signing of it. Your witness must be over the age of 18.
      </p>
      <p>
        The witness <strong>does not</strong> have to be a justice of the peace or solicitor or
        other ‘prescribed witness’.
      </p>
      <h4><em>Step 2 - Complete and sign the Revocation of Power of Attorney</em></h4>
      <p>
        The Revocation form can be found here:<br />
        <a
          href="https://www.nswlrs.com.au/getattachment/8d1b179a-526c-41b6-a0ac-2ee3943c791c/attachment.aspx"
          >https://www.nswlrs.com.au/getattachment/8d1b179a-526c-41b6-a0ac-2ee3943c791c/attachment.aspx</a
        >
      </p>
      <p>
        You must sign the Revocation of Enduring Power of Attorney in the presence of your witness.
        Make sure your witness is watching you sign and date the document. After this your witness
        must also sign where indicated.
      </p>
      <h4><em>Step 3 - Give written notice of the Revocation</em></h4>
      <p>
        After you have signed your Revocation of Power of Attorney and it has been witnessed, you
        must, as soon as practicable, give notice of the Revocation. You can do this by writing a
        letter enclosing a copy of the completed and signed Revocation to:
      </p>
      <ul>
        <li>all of your attorney(s) whose appointment has been revoked; and</li>
        <li>
          anyone else who may be aware of or has dealings with the revoked Power of Attorney
          document.
        </li>
      </ul>
      <p>
        Tip: If you fail to give notice, your attorney(s) may legally continue to make decisions on
        your behalf under the old power of attorney document.
      </p>
      <h4><em>Step 4 - Registration</em></h4>
      <p>
        In NSW, if you have not registered the Enduring Power of Attorney that you have just
        revoked, you do not need to register the Revocation form
      </p>
      <p>
        However, if you registered the Power of Attorney, you must lodge at the NSW Land Registry
        Services:
      </p>
      <ol>
        <li>the completed and signed Revocation form; and</li>
        <li>a completed and signed Deed Index Particulars form.</li>
      </ol>
      <p>
        To find the location of the NSW Land Registry for lodging, please visit the NSW Land
        Registry Services website:
        <a href="http://www.nswlrs.com.au/">http://www.nswlrs.com.au/</a>. Please note there may be
        fees associated with the lodgement.
      </p>
      <p>
        Tip: You should keep a copy of the completed and signed Revocation for your own record
        keeping purposes.
      </p>
    </article>
  </div>
</template>

<script lang="ts">
import AppointmentOfAttorneys from './components/financial/AppointmentOfAttorneys.vue';

export default {
  components: {
    AppointmentOfAttorneys,
  },
};
</script>
