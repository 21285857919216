<template>
  <article>
    <section>
      <h3>APPOINTMENT OF ATTORNEYS</h3>
      <ol type="1">
        <li>
          I, <strong>{{ ownerPerson?.fullName }}</strong> {{ ownerPerson?.fullAddress.trim() }},
          <strong>appoint</strong> the following
          {{ ifPlural('people', 'person', primaryAttorneys) }} to be my
          {{ plural('attorney', primaryAttorneys) }}
          <ol type="a">
            <li :key="attorney.id" v-for="attorney of primaryAttorneys">
              <strong>{{ attorney.directoryPerson.fullName }}</strong>
              {{ attorney.directoryPerson.identifiers }}
            </li>
          </ol>
        </li>
        <li v-if="hasMultipleAttorneys">
          I would like my {{ plural('attorney', primaryAttorneys) }} to act
          {{ mf('attorney_decisions', poa?.meta) }}.
        </li>
        <li v-if="hasMultipleAttorneys && actJointly">
          I {{ othersMayAct ? 'do not want' : 'want' }} the appointment to be terminated if one of
          my joint attorneys dies, resigns, or otherwise Vacates Office.
        </li>
        <li v-if="hasSecondaries">
          If the appointment of
          {{ ifPlural('all of my attorneys', 'my attorney', secondaryAttorneys) }}
          has terminated (due to death, resignation or other Vacation of Office), I appoint the
          following person to be my substitute attorney:
          <ol type="a">
            <li :key="attorney.id" v-for="attorney of secondaryAttorneys">
              <strong>{{ attorney.directoryPerson.fullName }}</strong>
              {{ attorney.directoryPerson.identifiers }}
            </li>
          </ol>
        </li>
      </ol>
    </section>
    <section>
      <h2>POWERS</h2>
      <ol
        type="1"
        :start="2 + hasMultipleAttorneys + (hasMultipleAttorneys && actJointly) + hasSecondaries"
      >
        <li>
          <p>
            My Attorney(s) may exercise the authority conferred by Part 2 of the
            <em>Powers of Attorney Act 2003</em> (NSW) to do anything on my behalf I may lawfully
            authorise an attorney to do.
          </p>
          <p>
            I give this power of attorney with the intention that it will continue to be effective
            if I lack capacity through loss of mental capacity after its execution.
          </p>
        </li>
      </ol>
    </section>
    <section>
      <h2>ADDITIONAL POWERS</h2>
      <ol
        type="1"
        :start="3 + hasMultipleAttorneys + (hasMultipleAttorneys && actJointly) + hasSecondaries"
      >
        <li>
          I authorise my attorney to confer benefits on the following persons to meet their
          reasonable living and medical expenses as provided by section 13 (2) of the Powers of
          Attorney Act 2003:
          <ol type="a" v-if="hasInclusions">
            <li :key="inclusion.id" v-for="inclusion of inclusions">
              <strong>{{ inclusion.directoryPerson.fullName }}</strong>
              {{ inclusion.directoryPerson.identifiers }}
            </li>
          </ol>
          <ul v-else>
            <li>NIL</li>
          </ul>
        </li>
      </ol>
    </section>
    <section>
      <h2>CONDITIONS AND LIMITATIONS</h2>
      <ol :start="4 + hasMultipleAttorneys + (hasMultipleAttorneys && actJointly) + hasSecondaries">
        <li>
          I place the following conditions and/or limitations on the authority of my attorney:
          <ul>
            <template v-if="hasLimitations">
              <li>{{ mf('limitations', poa?.meta) }}</li>
            </template>
            <template v-else>
              <li>NIL</li>
            </template>
          </ul>
        </li>
      </ol>
    </section>
    <section>
      <h2>COMMENCEMENT</h2>
      <ol :start="5 + hasMultipleAttorneys + (hasMultipleAttorneys && actJointly) + hasSecondaries">
        <li>
          This Enduring Power of Attorney operates once a medical practitioner considers that I am
          unable to manage my affairs.
        </li>
      </ol>
    </section>
    <section>
      <h2>ATTESTATION</h2>
      <div class="sign-container">
        <div class="sign-prefixed">
          <div class="sign-prefix">
            {{ ownerPerson?.fullName }}
          </div>
          <div class="sign">Name</div>
        </div>
        <div class="sign">Signature</div>
        <div class="sign">Date</div>
        <div class="sign">Witness Name</div>
        <div class="sign">Witness Address</div>
        <div class="sign">Witness Signature</div>
      </div>
    </section>
    <section>
      <h4>Certificate under section 19 of the Powers of Attorney Act 2003</h4>
      <div class="sign-container">
        <div class="sign-prefixed">
          <div class="sign-prefix">I,</div>
          <div class="sign">Witness Full Name</div>
        </div>
        <div class="sign-prefixed">
          <div class="sign-prefix">of</div>
          <div class="sign">&nbsp;</div>
          <div class="sign">Witness Address</div>
        </div>
      </div>
      <p>certify the following:</p>
      <ul>
        <li>
          I explained the effect of this power of attorney to the principal before it was signed,
          and the principal appeared to understand the effect of the power of attorney,
        </li>
        <li>I am a prescribed witness,</li>
        <li>I am not an attorney under this power of attorney, and</li>
        <li>I have witnessed the signature of this power of attorney by the principal.</li>
      </ul>
    </section>
    <section>
      <h4>Witness Occupation</h4>
      <ul class="checkboxes">
        <li>Australian legal practitioner</li>
        <li>Registrar of the Local Court</li>
        <li>
          Licensed Conveyancer who has successfully completed a course of study approved by the
          Minister
        </li>
        <li>
          NSW Trustee and Guardian employee who has successfully completed a course of study
          approved by the Minister
        </li>
        <li>
          A trustee company whose employee who has successfully completed a course of study approved
          by the Minister
        </li>
        <li>
          Legal Practitioner qualified in a country other than Australia who is instructed and
          employed independently of any legal practitioner appointed as an attorney under this power
          of attorney
        </li>
      </ul>
      <div class="sign-container">
        <div class="sign">Signature</div>
        <div class="sign">Date</div>
      </div>
      <p>Qualification [tick the appropriate category]:</p>
    </section>
    <section>
      <h4>ATTORNEYS</h4>
      <ol type="a">
        <li>I accept that I must always act in the principal’s best interests.</li>
        <li>
          I accept that as an attorney I must keep my own money and property separate from the
          principal’s money and property.
        </li>
        <li>
          I accept that I should keep reasonable accounts and records of the principal’s money and
          property.
        </li>
        <li>
          I accept that unless expressly authorised or specified in the ‘additional powers’ section
          by the principal, I cannot gain any benefit from being an attorney
        </li>
        <li>
          I accept that I must always act honestly in all matters concerning the principal’s legal
          and financial affairs
        </li>
      </ol>
      <p>
        <strong
          ><em
            >Failure to do any of the above may incur civil and/or criminal penalties.</em
          ></strong
        >
      </p>
      <div class="sign-container" :key="attorney.id" v-for="(attorney, i) of primaryAttorneys">
        <div class="sign-prefixed">
          <div class="sign-prefix bold">
            <strong>{{ attorney.directoryPerson.fullName }}</strong>
          </div>
          <div class="sign">Attorney {{ i + 1 }} Name</div>
        </div>
        <div class="sign">Attorney {{ i + 1 }} Signature</div>
        <div class="sign">Date</div>
      </div>
      <div class="sign-container" v-if="hasSecondaries">
        <div class="sign-prefixed">
          <div class="sign-prefix bold">
            {{ secondaryAttorneys[0].directoryPerson.fullName }}
          </div>
          <div class="sign">Substitute Attorney Name</div>
        </div>
        <div class="sign">Substitute Attorney Signature</div>
        <div class="sign">Date</div>
      </div>
    </section>
  </article>
</template>

<script lang="ts">
import poa from '@/hooks/poa';
import { mf, plural, ifPlural } from '@/utils/format';
import { financialPoaKey, ownerPersonKey } from '@/types/commonTypes';
import { inject } from 'vue';

export default {
  setup() {
    const ownerPerson = inject(ownerPersonKey);
    const financialPoa = inject(financialPoaKey);

    const {
      actJointly,
      attorneys,
      hasInclusions,
      hasLimitations,
      hasMultipleAttorneys,
      inclusions,
      hasSecondaries,
      othersMayAct,
      primaryAttorneys,
      secondaryAttorneys,
    } = poa(financialPoa?.value);

    return {
      actJointly,
      attorneys,
      hasInclusions,
      hasLimitations,
      hasMultipleAttorneys,
      inclusions,
      hasSecondaries,
      othersMayAct,
      primaryAttorneys,
      secondaryAttorneys,
      ownerPerson,
      poa: financialPoa,
    };
  },
  methods: {
    mf,
    plural,
    ifPlural,
  },
};
</script>
