import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { type: "a" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["start"]
const _hoisted_4 = ["start"]
const _hoisted_5 = { type: "a" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { key: 2 }
const _hoisted_9 = ["start"]
const _hoisted_10 = { class: "sign-container" }
const _hoisted_11 = { class: "sign" }
const _hoisted_12 = ["start"]
const _hoisted_13 = { class: "sign" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("article", null, [
    _cache[15] || (_cache[15] = _createElementVNode("h1", null, "ENDURING POWER OF ATTORNEY", -1)),
    _cache[16] || (_cache[16] = _createElementVNode("p", null, "(Pursuant to section 6 of the Powers of Attorney and Agency Act 1984)", -1)),
    _createElementVNode("section", null, [
      _createElementVNode("h3", null, "APPOINTMENT OF " + _toDisplayString($options.plural('Donee', $setup.primaryAttorneys).toUpperCase()), 1),
      _createElementVNode("ol", null, [
        _createElementVNode("li", null, [
          _cache[0] || (_cache[0] = _createTextVNode(" I, ")),
          _createElementVNode("strong", null, _toDisplayString($setup.ownerPerson?.fullName), 1),
          _createTextVNode(" " + _toDisplayString($setup.ownerPerson?.fullAddress) + ", do hereby nominate, constitute and appoint the following " + _toDisplayString($options.ifPlural('people', 'person', $setup.primaryAttorneys)) + " to be my " + _toDisplayString($options.plural('Donee', $setup.primaryAttorneys)) + ": ", 1),
          _createElementVNode("ol", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.primaryAttorneys, (attorney) => {
              return (_openBlock(), _createElementBlock("li", {
                key: attorney.id
              }, [
                _createElementVNode("strong", null, _toDisplayString(attorney.directoryPerson.fullName), 1),
                _createTextVNode(" " + _toDisplayString(attorney.directoryPerson.identifiers), 1)
              ]))
            }), 128))
          ])
        ]),
        ($setup.hasMultipleAttorneys)
          ? (_openBlock(), _createElementBlock("li", _hoisted_2, " I would like my Donees to act " + _toDisplayString($options.mf('attorney_decisions', $setup.poa?.meta).replace('or', 'and')) + ". ", 1))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("section", null, [
      _cache[2] || (_cache[2] = _createElementVNode("h3", null, "COMMENCEMENT", -1)),
      _createElementVNode("ol", {
        start: 2 + $setup.hasMultipleAttorneys
      }, _cache[1] || (_cache[1] = [
        _createElementVNode("li", null, " I declare this power to become effective only in the event of my suffering any subsequent legal incapacity. ", -1)
      ]), 8, _hoisted_3)
    ]),
    _createElementVNode("section", null, [
      _cache[6] || (_cache[6] = _createElementVNode("h3", null, "AUTHORISATION", -1)),
      _createElementVNode("ol", {
        start: 3 + $setup.hasMultipleAttorneys
      }, [
        _cache[5] || (_cache[5] = _createElementVNode("li", null, " I authorise my attorneys to do on my behalf anything I can legally do by an attorney. ", -1)),
        _createElementVNode("li", null, [
          _cache[4] || (_cache[4] = _createTextVNode(" This authority is subject to the following conditions, limitations, or exclusions. ")),
          _createElementVNode("ol", _hoisted_5, [
            ($setup.hasInclusions)
              ? (_openBlock(), _createElementBlock("li", _hoisted_6, [
                  _cache[3] || (_cache[3] = _createTextVNode(" I authorise my Donees to provide for the reasonable living and medical expenses for the following people: ")),
                  _createElementVNode("ul", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.inclusions, (inclusion) => {
                      return (_openBlock(), _createElementBlock("li", {
                        key: inclusion.id
                      }, [
                        _createElementVNode("strong", null, _toDisplayString(inclusion.directoryPerson.fullName), 1),
                        _createTextVNode(" " + _toDisplayString(inclusion.directoryPerson.identifiers), 1)
                      ]))
                    }), 128))
                  ])
                ]))
              : _createCommentVNode("", true),
            ($setup.hasMultipleAttorneys && $setup.actJointly)
              ? (_openBlock(), _createElementBlock("li", _hoisted_7, " I " + _toDisplayString($setup.othersMayAct ? 'do not want' : 'want') + " the appointment under this document to be terminated if one of my joint Donees dies, loses capacity or otherwise resigns. ", 1))
              : _createCommentVNode("", true),
            ($setup.hasLimitations)
              ? (_openBlock(), _createElementBlock("li", _hoisted_8, _toDisplayString($options.mf('limitations', $setup.poa?.meta)), 1))
              : _createCommentVNode("", true)
          ])
        ])
      ], 8, _hoisted_4)
    ]),
    _createElementVNode("section", null, [
      _cache[12] || (_cache[12] = _createElementVNode("h3", null, "EXECUTION", -1)),
      _createElementVNode("ol", {
        start: 5 + $setup.hasMultipleAttorneys
      }, [
        _createElementVNode("li", null, [
          _cache[7] || (_cache[7] = _createElementVNode("p", null, "IN WITNESS WHEREOF, I the Donor have hereto set my hand and seal:", -1)),
          _cache[8] || (_cache[8] = _createElementVNode("div", { class: "sign-container" }, [
            _createElementVNode("div", { class: "sign" }, "Date")
          ], -1)),
          _cache[9] || (_cache[9] = _createElementVNode("p", null, "SIGNED BY THE DONOR AS A DEED", -1)),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, "Signature of " + _toDisplayString($setup.ownerPerson?.fullName), 1)
          ]),
          _cache[10] || (_cache[10] = _createElementVNode("p", null, "IN THE PRESENCE OF:", -1)),
          _cache[11] || (_cache[11] = _createElementVNode("div", { class: "sign-container" }, [
            _createElementVNode("div", { class: "sign" }, "Signature of witness authorised at law to take affidavits"),
            _createElementVNode("div", { class: "sign" }, "Witness name")
          ], -1))
        ])
      ], 8, _hoisted_9)
    ]),
    _createElementVNode("section", null, [
      _cache[14] || (_cache[14] = _createElementVNode("h3", null, "FORM OF ACCEPTANCE", -1)),
      _createElementVNode("ol", {
        start: 6 + $setup.hasMultipleAttorneys
      }, [
        _createElementVNode("li", null, [
          _createTextVNode(_toDisplayString($options.ifPlural('We', 'I', $setup.primaryAttorneys)) + ", ", 1),
          _createElementVNode("ul", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.primaryAttorneys, (attorney) => {
              return (_openBlock(), _createElementBlock("li", {
                key: attorney.id
              }, [
                _createElementVNode("strong", null, _toDisplayString(attorney.directoryPerson.fullName), 1),
                _createTextVNode(" " + _toDisplayString(attorney.directoryPerson.identifiers), 1)
              ]))
            }), 128))
          ]),
          _createTextVNode(" the " + _toDisplayString($options.plural('person', $setup.primaryAttorneys)) + " appointed to be the " + _toDisplayString($options.plural('donee', $setup.primaryAttorneys)) + " of the power of attorney created by the instrument on which this acceptance is endorsed accept the appointment and acknowledge: ", 1),
          _createElementVNode("ol", null, [
            _createElementVNode("li", null, " that the power of attorney is an enduring power of attorney and as such may be exercised by " + _toDisplayString($options.ifPlural('us', 'me', $setup.primaryAttorneys)) + " in the event of any subsequent legal incapacity of the donor; and ", 1),
            _createElementVNode("li", null, " that " + _toDisplayString($options.ifPlural('we', 'I', $setup.primaryAttorneys)) + " will, by accepting this power of attorney, be subject to the requirements of the Powers of Attorney and Agency Act 1984. ", 1)
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.primaryAttorneys, (attorney) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "sign-container",
              key: attorney.id
            }, [
              _createElementVNode("div", _hoisted_13, " Signed by " + _toDisplayString(attorney.directoryPerson.fullName) + " " + _toDisplayString(attorney.directoryPerson.identifiers), 1),
              _cache[13] || (_cache[13] = _createElementVNode("div", { class: "sign" }, "Date", -1))
            ]))
          }), 128))
        ])
      ], 8, _hoisted_12)
    ])
  ]))
}