import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "sign-container" }
const _hoisted_3 = { class: "sign-prefixed" }
const _hoisted_4 = { class: "sign-prefix" }
const _hoisted_5 = { class: "sign-container" }
const _hoisted_6 = { class: "sign-prefixed" }
const _hoisted_7 = { class: "sign-prefix" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { class: "sign-container" }
const _hoisted_10 = { class: "sign-prefixed" }
const _hoisted_11 = { class: "sign-prefix" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("article", null, [
    _createElementVNode("section", null, [
      _createElementVNode("h2", null, " APPOINTMENT OF MEDICAL TREATMENT DECISION " + _toDisplayString($options.plural('MAKER', $setup.primaryAttorneys).toUpperCase()), 1),
      _cache[0] || (_cache[0] = _createElementVNode("p", null, [
        _createTextVNode(" made under the "),
        _createElementVNode("em", null, "Medical Treatment Planning and Decisions Act 2016"),
        _createTextVNode(" (Vic.) ")
      ], -1)),
      _cache[1] || (_cache[1] = _createElementVNode("p", null, " Your medical treatment decision maker has legal authority to make medical treatment decisions on your behalf, if you do not have decision-making capacity to make the decision. Your medical treatment decision maker is the first person you list below who is reasonably available, and willing and able to make the decision. Only adults can appoint a medical treatment decision maker. ", -1))
    ]),
    _createElementVNode("section", null, [
      _cache[3] || (_cache[3] = _createElementVNode("h3", null, "PART 1: Personal details", -1)),
      _createElementVNode("ul", null, [
        _createElementVNode("li", null, [
          _cache[2] || (_cache[2] = _createTextVNode(" Your full name: ")),
          _createElementVNode("strong", null, _toDisplayString($setup.ownerPerson?.fullName), 1)
        ]),
        _createElementVNode("li", null, "Date of birth: " + _toDisplayString($setup.ownerPerson?.dateOfBirthFormatted), 1),
        _createElementVNode("li", null, "Address: " + _toDisplayString($setup.ownerPerson?.fullAddress.replace('of ', '')), 1),
        ($setup.ownerPerson?.defaultPhoneNumber)
          ? (_openBlock(), _createElementBlock("li", _hoisted_1, " Phone number: " + _toDisplayString($setup.ownerPerson?.defaultPhoneNumber), 1))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("section", null, [
      _cache[9] || (_cache[9] = _createElementVNode("h3", null, "PART 2: Medical treatment decision maker details", -1)),
      _cache[10] || (_cache[10] = _createElementVNode("p", null, [
        _createTextVNode(" I "),
        _createElementVNode("strong", null, "revoke"),
        _createTextVNode(" any other previous appointment of a medical treatment decision maker however described. ")
      ], -1)),
      _createElementVNode("p", null, [
        _cache[4] || (_cache[4] = _createTextVNode(" I ")),
        _cache[5] || (_cache[5] = _createElementVNode("strong", null, "appoint", -1)),
        _createTextVNode(" as my medical treatment " + _toDisplayString($options.plural('decision-maker', $setup.attorneys)) + ": ", 1)
      ]),
      _cache[11] || (_cache[11] = _createElementVNode("h4", null, "Medical treatment decision maker 1", -1)),
      _createElementVNode("ul", null, [
        _createElementVNode("li", null, [
          _cache[6] || (_cache[6] = _createTextVNode(" Full name: ")),
          _createElementVNode("strong", null, _toDisplayString($setup.primaryAttorneys[0].directoryPerson.fullName), 1)
        ]),
        _createElementVNode("li", null, " Date of birth: " + _toDisplayString($setup.primaryAttorneys[0].directoryPerson.dateOfBirthFormatted), 1),
        _createElementVNode("li", null, " Address: " + _toDisplayString($setup.primaryAttorneys[0].directoryPerson.fullAddress.replace('of ', '')), 1)
      ]),
      ($setup.hasSecondaries)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _cache[8] || (_cache[8] = _createElementVNode("h4", null, "Medical treatment decision maker 2", -1)),
            _createElementVNode("ul", null, [
              _createElementVNode("li", null, [
                _cache[7] || (_cache[7] = _createTextVNode(" Full name: ")),
                _createElementVNode("strong", null, _toDisplayString($setup.secondaryAttorneys[0].directoryPerson.fullName), 1)
              ]),
              _createElementVNode("li", null, " Date of birth: " + _toDisplayString($setup.secondaryAttorneys[0].directoryPerson.dateOfBirthFormatted), 1),
              _createElementVNode("li", null, " Address: " + _toDisplayString($setup.secondaryAttorneys[0].directoryPerson.fullAddress.replace('of ', '')), 1)
            ])
          ], 64))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("section", null, [
      _cache[12] || (_cache[12] = _createElementVNode("h3", null, "PART 3: Any limitations or conditions", -1)),
      _createElementVNode("p", null, _toDisplayString($setup.hasLimitations ? $options.mf('limitations', $setup.poa?.meta) : 'N/A'), 1),
      _createElementVNode("p", null, " I would " + _toDisplayString($setup.hasLimitations ? 'also' : '') + " like my appointed decision maker(s) to consider the following preferences: ", 1),
      _createElementVNode("p", null, _toDisplayString($setup.hasPreferences ? $options.mf('preferences', $setup.poa?.meta) : 'N/A'), 1)
    ]),
    _createElementVNode("section", null, [
      _cache[16] || (_cache[16] = _createElementVNode("h3", null, "PART 4: Witnessing", -1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, _toDisplayString($setup.ownerPerson?.fullName), 1),
          _cache[13] || (_cache[13] = _createElementVNode("div", { class: "sign" }, "Name", -1))
        ]),
        _cache[14] || (_cache[14] = _createElementVNode("div", { class: "sign" }, "Signature", -1)),
        _cache[15] || (_cache[15] = _createElementVNode("div", { class: "sign" }, "Date", -1))
      ]),
      _cache[17] || (_cache[17] = _createStaticVNode("<h4>WITNESS CERTIFICATES</h4><p>Each witness certifies that:</p><ul><li> at the time of signing the document, the person making this appointment appears to have decision-making capacity and appears to understand the nature and consequences of making the appointment and revoking any previous appointment; and </li><li> at the time of signing the document, the person making this appointment appeared to sign the document freely and voluntarily; and </li><li> the person signed the document in my presence and in the presence of a second witness; and </li><li>I am not the person’s medical treatment decision maker under this appointment.</li></ul><h4>Witness 1 – Authorised witness:</h4><div class=\"sign-container\"><div class=\"sign\">Name of authorised witness</div><div class=\"sign\">Qualification of authorised witness</div><div class=\"sign\">Signature of authorised witness</div><div class=\"sign\">Date</div></div><h4>Witness 2 – Adult witness:</h4><div class=\"sign-container\"><div class=\"sign\">Name of adult witness</div><div class=\"sign\">Signature of adult witness</div><div class=\"sign\">Date</div></div>", 7))
    ]),
    _cache[34] || (_cache[34] = _createElementVNode("section", null, [
      _createElementVNode("h3", null, "PART 5: Interpreter statement"),
      _createElementVNode("p", null, "N/A")
    ], -1)),
    _createElementVNode("section", null, [
      _cache[21] || (_cache[21] = _createElementVNode("h3", null, "PART 6: Statement of acceptance", -1)),
      _cache[22] || (_cache[22] = _createElementVNode("h4", null, "Medical treatment decision maker 1", -1)),
      _cache[23] || (_cache[23] = _createElementVNode("p", null, "I accept my appointment as medical treatment decision maker and state that:", -1)),
      _cache[24] || (_cache[24] = _createElementVNode("ul", null, [
        _createElementVNode("li", null, "I understand the obligations of an appointed medical treatment decision maker; and"),
        _createElementVNode("li", null, " I undertake to act in accordance with any known preferences and values of the person making the appointment; and "),
        _createElementVNode("li", null, " I undertake to promote the personal and social wellbeing of the person making the appointment, having regard to the need to respect the person’s individuality; and "),
        _createElementVNode("li", null, " I have read and understand any advance care directive that the person has given before, or at the same time as, this appointment. ")
      ], -1)),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, _toDisplayString($setup.primaryAttorneys[0].directoryPerson.fullName), 1),
          _cache[18] || (_cache[18] = _createElementVNode("div", { class: "sign" }, "Name of medical treatment decision maker", -1))
        ]),
        _cache[19] || (_cache[19] = _createElementVNode("div", { class: "sign" }, "Signature", -1)),
        _cache[20] || (_cache[20] = _createElementVNode("div", { class: "sign" }, "Date", -1))
      ])
    ]),
    _cache[35] || (_cache[35] = _createElementVNode("section", null, [
      _createElementVNode("h4", null, "Witnessed by an adult"),
      _createElementVNode("p", null, "I certify that I witnessed the signing of this statement of acceptance."),
      _createElementVNode("div", { class: "sign-container" }, [
        _createElementVNode("div", { class: "sign" }, "Name of adult witness"),
        _createElementVNode("div", { class: "sign" }, "Signature of adult witness"),
        _createElementVNode("div", { class: "sign" }, "Date")
      ])
    ], -1)),
    ($setup.hasSecondaries)
      ? (_openBlock(), _createElementBlock("section", _hoisted_8, [
          _cache[28] || (_cache[28] = _createElementVNode("h4", null, "Medical treatment decision maker 2", -1)),
          _cache[29] || (_cache[29] = _createElementVNode("p", null, "I accept my appointment as medical treatment decision maker and state that:", -1)),
          _cache[30] || (_cache[30] = _createElementVNode("ul", null, [
            _createElementVNode("li", null, "I understand the obligations of an appointed medical treatment decision maker; and"),
            _createElementVNode("li", null, " I undertake to act in accordance with any known preferences and values of the person making the appointment; and "),
            _createElementVNode("li", null, " I undertake to promote the personal and social wellbeing of the person making the appointment, having regard to the need to respect the person’s individuality; and "),
            _createElementVNode("li", null, " I have read and understand any advance care directive that the person has given before, or at the same time as, this appointment. ")
          ], -1)),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, _toDisplayString($setup.secondaryAttorneys[0].directoryPerson.fullName), 1),
              _cache[25] || (_cache[25] = _createElementVNode("div", { class: "sign" }, "Name of medical treatment decision maker", -1))
            ]),
            _cache[26] || (_cache[26] = _createElementVNode("div", { class: "sign" }, "Signature", -1)),
            _cache[27] || (_cache[27] = _createElementVNode("div", { class: "sign" }, "Date", -1))
          ]),
          _cache[31] || (_cache[31] = _createElementVNode("h4", null, "Witnessed by an adult", -1)),
          _cache[32] || (_cache[32] = _createElementVNode("p", null, "I certify that I witnessed the signing of this statement of acceptance.", -1)),
          _cache[33] || (_cache[33] = _createElementVNode("div", { class: "sign-container" }, [
            _createElementVNode("div", { class: "sign" }, "Name of adult witness"),
            _createElementVNode("div", { class: "sign" }, "Signature of adult witness"),
            _createElementVNode("div", { class: "sign" }, "Date")
          ], -1))
        ]))
      : _createCommentVNode("", true)
  ]))
}