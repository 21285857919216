import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "sign-container" }
const _hoisted_2 = { class: "sign-prefixed" }
const _hoisted_3 = { class: "sign-prefix" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("article", null, [
    _cache[3] || (_cache[3] = _createElementVNode("div", { class: "text-center" }, [
      _createElementVNode("h4", null, "SCHEDULE 1"),
      _createElementVNode("h2", null, "REVOCATION OF AN ENDURING POWER OF ATTORNEY")
    ], -1)),
    _cache[4] || (_cache[4] = _createElementVNode("p", null, " I revoke all of my previous enduring powers of attorney to the extent that they deal with personal care matters and health care matters. ", -1)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, _toDisplayString($setup.ownerPerson?.fullName), 1),
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "sign" }, "Name", -1))
      ]),
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "sign" }, "Signature", -1)),
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "sign" }, "Date", -1))
    ]),
    _cache[5] || (_cache[5] = _createElementVNode("p", null, [
      _createElementVNode("strong", null, [
        _createElementVNode("em", null, " If this Schedule 1 is executed, the principal must take reasonable steps to tell all attorneys affected by the revocation. ")
      ])
    ], -1)),
    _cache[6] || (_cache[6] = _createElementVNode("p", null, [
      _createElementVNode("strong", null, [
        _createElementVNode("em", null, " If the Power of Attorney was registered, this revocation must also be registered. Please see the instructions at the end of this Power of Attorney to register the revocation. ")
      ])
    ], -1))
  ]))
}