<template>
  <article>
    <section>
      <h2 class="text-center">Enduring Power of Guardianship</h2>
      <p>
        This Enduring Power of Guardianship is made under the
        <em>Guardianship and Administration Act 1990</em> Part 9A on the
        <span class="underline"></span> of <span class="wide-underline"></span> 20
        <span class="underline"></span>, by <strong>{{ ownerPerson?.fullName }}</strong>
        {{ ownerPerson?.fullAddress }}, {{ ownerPerson?.dateOfBirthFormatted }}. This Enduring Power
        of Guardianship has effect, subject to its terms, at any time I am unable to make reasonable
        judgments in respect of matters relating to my person.
      </p>
    </section>
    <section>
      <h3>
        APPOINTMENT OF ENDURING
        {{ plural('GUARDIAN', primaryAttorneys).toUpperCase() }}
      </h3>
      <ol>
        <li v-if="!hasMultipleAttorneys">
          <h4>SOLE ENDURING GUARDIAN:</h4>
          <p>
            I appoint
            <strong>{{ primaryAttorneys[0].directoryPerson.fullName }}</strong>
            {{ primaryAttorneys[0].directoryPerson.identifiers }} to be my Enduring Guardian.
          </p>
        </li>
        <li v-else>
          <h4>JOINT ENDURING GUARDIANS</h4>
          <p>
            I appoint
            <span :key="attorney.id" v-for="(attorney, index) of primaryAttorneys">
              <strong>{{ attorney.directoryPerson.fullName }}</strong>
              {{ attorney.directoryPerson.identifiers }}
              {{ index !== primaryAttorneys?.length - 1 ? 'and ' : '' }}
            </span>
            to be my joint Enduring Guardians.
          </p>
        </li>
      </ol>
    </section>
    <section v-if="hasSecondaries">
      <h3>
        APPOINTMENT OF SUBSTITUTE ENDURING
        {{ plural('GUARDIAN', primaryAttorneys).toUpperCase() }}
      </h3>
      <ol start="2">
        <li>
          I appoint
          <strong>{{ secondaryAttorneys[0].directoryPerson.fullName }}</strong>
          {{ secondaryAttorneys[0].directoryPerson.identifiers }} to be my Substitute Enduring
          Guardian in substitution of
          {{ ifPlural('either', '', primaryAttorneys) }}
          <template :key="attorney.id" v-for="(attorney, index) of primaryAttorneys">
            <strong>{{ attorney.directoryPerson.fullName }}</strong>
            {{ index !== primaryAttorneys?.length - 1 ? 'or ' : '' }}</template
          >.
        </li>
        <li>
          <p>
            My substitute enduring guardian is to be my enduring guardian in the following
            circumstances:
          </p>
          <ol type="a">
            <li v-if="!hasMultipleAttorneys">
              if my guardian
              <strong>{{ primaryAttorneys[0].directoryPerson.fullName }}</strong>
              dies or becomes incapacitated, my substitute enduring guardian
              <strong>{{ secondaryAttorneys[0].directoryPerson.fullName }}</strong>
              is to be my enduring guardian.
            </li>
            <li v-else>
              if one of my joint enduring guardians
              <span :key="attorney.id" v-for="(attorney, index) of primaryAttorneys">
                <strong>{{ attorney.directoryPerson.fullName }}</strong>
                {{ index !== primaryAttorneys?.length - 1 ? 'or ' : '' }}
              </span>
              dies or becomes incapacitated, the remaining enduring guardian and my substitute
              enduring guardian
              <strong>{{ secondaryAttorneys[0].directoryPerson.fullName }}</strong>
              are to be my joint enduring guardians.
            </li>
          </ol>
        </li>
      </ol>
    </section>
    <section v-if="hasMultipleAttorneys">
      <h3>DEATH OF JOINT ENDURING GUARDIAN</h3>
      <ol :start="2 + 2 * hasSecondaries">
        <li>
          If one or more of my joint enduring guardians die, I
          {{ othersMayAct ? 'want' : 'do not want' }} the surviving enduring guardian(s) to act.
        </li>
      </ol>
    </section>
    <section>
      <h3>
        FUNCTIONS OF ENDURING
        {{ plural('GUARDIAN', primaryAttorneys).toUpperCase() }}
      </h3>
      <ol :start="2 + hasMultipleAttorneys + 2 * hasSecondaries">
        <li>
          I authorise my enduring guardian(s) to perform in relation to me all of the functions of
          an enduring guardian, including making all decisions about my health care and lifestyle{{
            hasLimitations ? ', subject to any limitations set out below:' : '.'
          }}
          <ul v-if="hasLimitations">
            <li>{{ mf('limitations', poa?.meta) }}</li>
          </ul>
        </li>
      </ol>
    </section>
    <section>
      <h3>
        CIRCUMSTANCES IN WHICH ENDURING
        {{ plural('GUARDIAN', primaryAttorneys).toUpperCase() }} MAY ACT:
      </h3>
      <ol :start="3 + hasMultipleAttorneys + 2 * hasSecondaries">
        <li>N/A</li>
      </ol>
    </section>
    <section>
      <h3>
        DIRECTIONS ABOUT HOW ENDURING
        {{ plural('GUARDIAN', primaryAttorneys).toUpperCase() }} TO PERFORM FUNCTIONS
      </h3>
      <ol :start="4 + hasMultipleAttorneys + 2 * hasSecondaries">
        <li>
          My enduring {{ plural('guardian', primaryAttorneys) }}
          {{ ifPlural('are', 'is', primaryAttorneys) }} to perform
          {{ ifPlural('their', 'his/her', primaryAttorneys) }}
          functions in accordance with the following directions:
          <ul>
            <template v-if="hasPreferences">
              <li>{{ mf('preferences', poa?.meta) }}</li>
            </template>
            <template v-else>
              <li>N/A</li>
            </template>
          </ul>
        </li>
      </ol>
    </section>
    <section>
      <h3>ATTESTATION</h3>
      <p>
        Signed by: <strong>{{ ownerPerson?.fullName }}</strong>
      </p>
      <div class="sign-container">
        <div class="sign">Signature</div>
        <div class="sign">Date</div>
      </div>
      <h4>Witnessed by a person authorised to witness Statutory Declarations:</h4>
      <div class="sign-container">
        <div class="sign">Authorised witness's signature:</div>
        <div class="sign">Authorised witness's full name:</div>
        <div class="sign">Authorised witness's address:</div>
        <div class="sign">Authorised witness's qualification:</div>
        <div class="sign">Date</div>
      </div>
      <h4>and by another person:</h4>
      <div class="sign-container">
        <div class="sign">Other witness's signature:</div>
        <div class="sign">Other witness's full name:</div>
        <div class="sign">Other witness's address:</div>
        <div class="sign">Date</div>
      </div>
    </section>
    <template :key="attorney.id" v-for="attorney of primaryAttorneys">
      <section>
        <h3>ACCEPTANCE OF APPOINTMENT AS ENDURING GUARDIAN</h3>
        <p>
          I, <strong>{{ attorney.directoryPerson.fullName }}</strong> accept the appointment as an
          enduring guardian.
        </p>
        <div class="sign-container">
          <div class="sign">Signature</div>
          <div class="sign">Date</div>
        </div>
        <p>
          <strong>Witnessed by a person authorised to witness Statutory Declarations:</strong>
        </p>
        <div class="sign-container">
          <div class="sign">Authorised witness's signature:</div>
          <div class="sign">Authorised witness's full name:</div>
          <div class="sign">Authorised witness's address:</div>
          <div class="sign">Authorised witness's occupation:</div>
          <div class="sign">Date</div>
        </div>
        <h4>and by another person:</h4>
        <div class="sign-container">
          <div class="sign">Other witness's signature:</div>
          <div class="sign">Other witness's full name:</div>
          <div class="sign">Other witness's address:</div>
          <div class="sign">Date</div>
        </div>
      </section>
    </template>
    <section v-if="hasSecondaries">
      <h3>ACCEPTANCE OF APPOINTMENT AS SUBSTITUTE ENDURING GUARDIAN</h3>
      <p>
        I,
        <strong>{{ secondaryAttorneys[0].directoryPerson.fullName }}</strong>
        accept the appointment as a substitute enduring guardian.
      </p>
      <div class="sign-container">
        <div class="sign">Signature</div>
        <div class="sign">Date</div>
      </div>
      <h4>Witnessed by a person authorised to witness Statutory Declarations:</h4>
      <div class="sign-container">
        <div class="sign">Authorised witness's signature:</div>
        <div class="sign">Authorised witness's full name:</div>
        <div class="sign">Authorised witness's address:</div>
        <div class="sign">Authorised witness's occupation:</div>
        <div class="sign">Date</div>
      </div>
      <h4>and by another person:</h4>
      <div class="sign-container">
        <div class="sign">Other witness's signature:</div>
        <div class="sign">Other witness's full name:</div>
        <div class="sign">Other witness's address:</div>
        <div class="sign">Date</div>
      </div>
    </section>
  </article>
</template>

<script lang="ts">
import poa from '@/hooks/poa';
import { mf, plural, ifPlural } from '@/utils/format';
import { medicalPoaKey, ownerPersonKey } from '@/types/commonTypes';
import { inject } from 'vue';

export default {
  setup() {
    const ownerPerson = inject(ownerPersonKey);
    const medicalPoa = inject(medicalPoaKey);

    const {
      actJointly,
      attorneys,
      hasInclusions,
      hasLimitations,
      hasPreferences,
      hasMultipleAttorneys,
      inclusions,
      hasSecondaries,
      othersMayAct,
      primaryAttorneys,
      secondaryAttorneys,
    } = poa(medicalPoa?.value);

    return {
      actJointly,
      attorneys,
      hasInclusions,
      hasLimitations,
      hasPreferences,
      hasMultipleAttorneys,
      inclusions,
      hasSecondaries,
      othersMayAct,
      primaryAttorneys,
      secondaryAttorneys,
      poa: medicalPoa,
      ownerPerson,
    };
  },
  methods: {
    mf,
    plural,
    ifPlural,
  },
};
</script>
