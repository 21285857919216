<template>
  <article>
    <div class="text-center">
      <h4>SCHEDULE 1</h4>
      <h2>REVOCATION OF AN ENDURING POWER OF ATTORNEY</h2>
    </div>
    <p>
      I revoke all of my previous enduring powers of attorney to the extent that they deal with
      personal care matters and health care matters.
    </p>
    <div class="sign-container">
      <div class="sign-prefixed">
        <div class="sign-prefix">
          {{ ownerPerson?.fullName }}
        </div>
        <div class="sign">Name</div>
      </div>
      <div class="sign">Signature</div>
      <div class="sign">Date</div>
    </div>
    <p>
      <strong>
        <em>
          If this Schedule 1 is executed, the principal must take reasonable steps to tell all
          attorneys affected by the revocation.
        </em>
      </strong>
    </p>
    <p>
      <strong>
        <em>
          If the Power of Attorney was registered, this revocation must also be registered. Please
          see the instructions at the end of this Power of Attorney to register the revocation.
        </em>
      </strong>
    </p>
  </article>
</template>

<script lang="ts">
import { inject } from 'vue';
import { ownerPersonKey } from '@/types/commonTypes';

export default {
  setup() {
    const ownerPerson = inject(ownerPersonKey);

    return { ownerPerson };
  },
};
</script>
