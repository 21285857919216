import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { start: "2" }
const _hoisted_5 = { type: "a" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = ["start"]
const _hoisted_10 = ["start"]
const _hoisted_11 = { key: 0 }
const _hoisted_12 = ["start"]
const _hoisted_13 = ["start"]
const _hoisted_14 = { key: 0 }
const _hoisted_15 = { key: 1 }
const _hoisted_16 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("article", null, [
    _createElementVNode("section", null, [
      _cache[9] || (_cache[9] = _createElementVNode("h2", { class: "text-center" }, "Enduring Power of Guardianship", -1)),
      _createElementVNode("p", null, [
        _cache[0] || (_cache[0] = _createTextVNode(" This Enduring Power of Guardianship is made under the ")),
        _cache[1] || (_cache[1] = _createElementVNode("em", null, "Guardianship and Administration Act 1990", -1)),
        _cache[2] || (_cache[2] = _createTextVNode(" Part 9A on the ")),
        _cache[3] || (_cache[3] = _createElementVNode("span", { class: "underline" }, null, -1)),
        _cache[4] || (_cache[4] = _createTextVNode(" of ")),
        _cache[5] || (_cache[5] = _createElementVNode("span", { class: "wide-underline" }, null, -1)),
        _cache[6] || (_cache[6] = _createTextVNode(" 20 ")),
        _cache[7] || (_cache[7] = _createElementVNode("span", { class: "underline" }, null, -1)),
        _cache[8] || (_cache[8] = _createTextVNode(", by ")),
        _createElementVNode("strong", null, _toDisplayString($setup.ownerPerson?.fullName), 1),
        _createTextVNode(" " + _toDisplayString($setup.ownerPerson?.fullAddress) + ", " + _toDisplayString($setup.ownerPerson?.dateOfBirthFormatted) + ". This Enduring Power of Guardianship has effect, subject to its terms, at any time I am unable to make reasonable judgments in respect of matters relating to my person. ", 1)
      ])
    ]),
    _createElementVNode("section", null, [
      _createElementVNode("h3", null, " APPOINTMENT OF ENDURING " + _toDisplayString($options.plural('GUARDIAN', $setup.primaryAttorneys).toUpperCase()), 1),
      _createElementVNode("ol", null, [
        (!$setup.hasMultipleAttorneys)
          ? (_openBlock(), _createElementBlock("li", _hoisted_1, [
              _cache[11] || (_cache[11] = _createElementVNode("h4", null, "SOLE ENDURING GUARDIAN:", -1)),
              _createElementVNode("p", null, [
                _cache[10] || (_cache[10] = _createTextVNode(" I appoint ")),
                _createElementVNode("strong", null, _toDisplayString($setup.primaryAttorneys[0].directoryPerson.fullName), 1),
                _createTextVNode(" " + _toDisplayString($setup.primaryAttorneys[0].directoryPerson.identifiers) + " to be my Enduring Guardian. ", 1)
              ])
            ]))
          : (_openBlock(), _createElementBlock("li", _hoisted_2, [
              _cache[14] || (_cache[14] = _createElementVNode("h4", null, "JOINT ENDURING GUARDIANS", -1)),
              _createElementVNode("p", null, [
                _cache[12] || (_cache[12] = _createTextVNode(" I appoint ")),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.primaryAttorneys, (attorney, index) => {
                  return (_openBlock(), _createElementBlock("span", {
                    key: attorney.id
                  }, [
                    _createElementVNode("strong", null, _toDisplayString(attorney.directoryPerson.fullName), 1),
                    _createTextVNode(" " + _toDisplayString(attorney.directoryPerson.identifiers) + " " + _toDisplayString(index !== $setup.primaryAttorneys?.length - 1 ? 'and ' : ''), 1)
                  ]))
                }), 128)),
                _cache[13] || (_cache[13] = _createTextVNode(" to be my joint Enduring Guardians. "))
              ])
            ]))
      ])
    ]),
    ($setup.hasSecondaries)
      ? (_openBlock(), _createElementBlock("section", _hoisted_3, [
          _createElementVNode("h3", null, " APPOINTMENT OF SUBSTITUTE ENDURING " + _toDisplayString($options.plural('GUARDIAN', $setup.primaryAttorneys).toUpperCase()), 1),
          _createElementVNode("ol", _hoisted_4, [
            _createElementVNode("li", null, [
              _cache[15] || (_cache[15] = _createTextVNode(" I appoint ")),
              _createElementVNode("strong", null, _toDisplayString($setup.secondaryAttorneys[0].directoryPerson.fullName), 1),
              _createTextVNode(" " + _toDisplayString($setup.secondaryAttorneys[0].directoryPerson.identifiers) + " to be my Substitute Enduring Guardian in substitution of " + _toDisplayString($options.ifPlural('either', '', $setup.primaryAttorneys)) + " ", 1),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.primaryAttorneys, (attorney, index) => {
                return (_openBlock(), _createElementBlock(_Fragment, {
                  key: attorney.id
                }, [
                  _createElementVNode("strong", null, _toDisplayString(attorney.directoryPerson.fullName), 1),
                  _createTextVNode(" " + _toDisplayString(index !== $setup.primaryAttorneys?.length - 1 ? 'or ' : ''), 1)
                ], 64))
              }), 128)),
              _cache[16] || (_cache[16] = _createTextVNode(". "))
            ]),
            _createElementVNode("li", null, [
              _cache[23] || (_cache[23] = _createElementVNode("p", null, " My substitute enduring guardian is to be my enduring guardian in the following circumstances: ", -1)),
              _createElementVNode("ol", _hoisted_5, [
                (!$setup.hasMultipleAttorneys)
                  ? (_openBlock(), _createElementBlock("li", _hoisted_6, [
                      _cache[17] || (_cache[17] = _createTextVNode(" if my guardian ")),
                      _createElementVNode("strong", null, _toDisplayString($setup.primaryAttorneys[0].directoryPerson.fullName), 1),
                      _cache[18] || (_cache[18] = _createTextVNode(" dies or becomes incapacitated, my substitute enduring guardian ")),
                      _createElementVNode("strong", null, _toDisplayString($setup.secondaryAttorneys[0].directoryPerson.fullName), 1),
                      _cache[19] || (_cache[19] = _createTextVNode(" is to be my enduring guardian. "))
                    ]))
                  : (_openBlock(), _createElementBlock("li", _hoisted_7, [
                      _cache[20] || (_cache[20] = _createTextVNode(" if one of my joint enduring guardians ")),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.primaryAttorneys, (attorney, index) => {
                        return (_openBlock(), _createElementBlock("span", {
                          key: attorney.id
                        }, [
                          _createElementVNode("strong", null, _toDisplayString(attorney.directoryPerson.fullName), 1),
                          _createTextVNode(" " + _toDisplayString(index !== $setup.primaryAttorneys?.length - 1 ? 'or ' : ''), 1)
                        ]))
                      }), 128)),
                      _cache[21] || (_cache[21] = _createTextVNode(" dies or becomes incapacitated, the remaining enduring guardian and my substitute enduring guardian ")),
                      _createElementVNode("strong", null, _toDisplayString($setup.secondaryAttorneys[0].directoryPerson.fullName), 1),
                      _cache[22] || (_cache[22] = _createTextVNode(" are to be my joint enduring guardians. "))
                    ]))
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    ($setup.hasMultipleAttorneys)
      ? (_openBlock(), _createElementBlock("section", _hoisted_8, [
          _cache[24] || (_cache[24] = _createElementVNode("h3", null, "DEATH OF JOINT ENDURING GUARDIAN", -1)),
          _createElementVNode("ol", {
            start: 2 + 2 * $setup.hasSecondaries
          }, [
            _createElementVNode("li", null, " If one or more of my joint enduring guardians die, I " + _toDisplayString($setup.othersMayAct ? 'want' : 'do not want') + " the surviving enduring guardian(s) to act. ", 1)
          ], 8, _hoisted_9)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("section", null, [
      _createElementVNode("h3", null, " FUNCTIONS OF ENDURING " + _toDisplayString($options.plural('GUARDIAN', $setup.primaryAttorneys).toUpperCase()), 1),
      _createElementVNode("ol", {
        start: 2 + $setup.hasMultipleAttorneys + 2 * $setup.hasSecondaries
      }, [
        _createElementVNode("li", null, [
          _createTextVNode(" I authorise my enduring guardian(s) to perform in relation to me all of the functions of an enduring guardian, including making all decisions about my health care and lifestyle" + _toDisplayString($setup.hasLimitations ? ', subject to any limitations set out below:' : '.') + " ", 1),
          ($setup.hasLimitations)
            ? (_openBlock(), _createElementBlock("ul", _hoisted_11, [
                _createElementVNode("li", null, _toDisplayString($options.mf('limitations', $setup.poa?.meta)), 1)
              ]))
            : _createCommentVNode("", true)
        ])
      ], 8, _hoisted_10)
    ]),
    _createElementVNode("section", null, [
      _createElementVNode("h3", null, " CIRCUMSTANCES IN WHICH ENDURING " + _toDisplayString($options.plural('GUARDIAN', $setup.primaryAttorneys).toUpperCase()) + " MAY ACT: ", 1),
      _createElementVNode("ol", {
        start: 3 + $setup.hasMultipleAttorneys + 2 * $setup.hasSecondaries
      }, _cache[25] || (_cache[25] = [
        _createElementVNode("li", null, "N/A", -1)
      ]), 8, _hoisted_12)
    ]),
    _createElementVNode("section", null, [
      _createElementVNode("h3", null, " DIRECTIONS ABOUT HOW ENDURING " + _toDisplayString($options.plural('GUARDIAN', $setup.primaryAttorneys).toUpperCase()) + " TO PERFORM FUNCTIONS ", 1),
      _createElementVNode("ol", {
        start: 4 + $setup.hasMultipleAttorneys + 2 * $setup.hasSecondaries
      }, [
        _createElementVNode("li", null, [
          _createTextVNode(" My enduring " + _toDisplayString($options.plural('guardian', $setup.primaryAttorneys)) + " " + _toDisplayString($options.ifPlural('are', 'is', $setup.primaryAttorneys)) + " to perform " + _toDisplayString($options.ifPlural('their', 'his/her', $setup.primaryAttorneys)) + " functions in accordance with the following directions: ", 1),
          _createElementVNode("ul", null, [
            ($setup.hasPreferences)
              ? (_openBlock(), _createElementBlock("li", _hoisted_14, _toDisplayString($options.mf('preferences', $setup.poa?.meta)), 1))
              : (_openBlock(), _createElementBlock("li", _hoisted_15, "N/A"))
          ])
        ])
      ], 8, _hoisted_13)
    ]),
    _createElementVNode("section", null, [
      _cache[27] || (_cache[27] = _createElementVNode("h3", null, "ATTESTATION", -1)),
      _createElementVNode("p", null, [
        _cache[26] || (_cache[26] = _createTextVNode(" Signed by: ")),
        _createElementVNode("strong", null, _toDisplayString($setup.ownerPerson?.fullName), 1)
      ]),
      _cache[28] || (_cache[28] = _createStaticVNode("<div class=\"sign-container\"><div class=\"sign\">Signature</div><div class=\"sign\">Date</div></div><h4>Witnessed by a person authorised to witness Statutory Declarations:</h4><div class=\"sign-container\"><div class=\"sign\">Authorised witness&#39;s signature:</div><div class=\"sign\">Authorised witness&#39;s full name:</div><div class=\"sign\">Authorised witness&#39;s address:</div><div class=\"sign\">Authorised witness&#39;s qualification:</div><div class=\"sign\">Date</div></div><h4>and by another person:</h4><div class=\"sign-container\"><div class=\"sign\">Other witness&#39;s signature:</div><div class=\"sign\">Other witness&#39;s full name:</div><div class=\"sign\">Other witness&#39;s address:</div><div class=\"sign\">Date</div></div>", 5))
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.primaryAttorneys, (attorney) => {
      return (_openBlock(), _createElementBlock("section", {
        key: attorney.id
      }, [
        _cache[31] || (_cache[31] = _createElementVNode("h3", null, "ACCEPTANCE OF APPOINTMENT AS ENDURING GUARDIAN", -1)),
        _createElementVNode("p", null, [
          _cache[29] || (_cache[29] = _createTextVNode(" I, ")),
          _createElementVNode("strong", null, _toDisplayString(attorney.directoryPerson.fullName), 1),
          _cache[30] || (_cache[30] = _createTextVNode(" accept the appointment as an enduring guardian. "))
        ]),
        _cache[32] || (_cache[32] = _createStaticVNode("<div class=\"sign-container\"><div class=\"sign\">Signature</div><div class=\"sign\">Date</div></div><p><strong>Witnessed by a person authorised to witness Statutory Declarations:</strong></p><div class=\"sign-container\"><div class=\"sign\">Authorised witness&#39;s signature:</div><div class=\"sign\">Authorised witness&#39;s full name:</div><div class=\"sign\">Authorised witness&#39;s address:</div><div class=\"sign\">Authorised witness&#39;s occupation:</div><div class=\"sign\">Date</div></div><h4>and by another person:</h4><div class=\"sign-container\"><div class=\"sign\">Other witness&#39;s signature:</div><div class=\"sign\">Other witness&#39;s full name:</div><div class=\"sign\">Other witness&#39;s address:</div><div class=\"sign\">Date</div></div>", 5))
      ]))
    }), 128)),
    ($setup.hasSecondaries)
      ? (_openBlock(), _createElementBlock("section", _hoisted_16, [
          _cache[35] || (_cache[35] = _createElementVNode("h3", null, "ACCEPTANCE OF APPOINTMENT AS SUBSTITUTE ENDURING GUARDIAN", -1)),
          _createElementVNode("p", null, [
            _cache[33] || (_cache[33] = _createTextVNode(" I, ")),
            _createElementVNode("strong", null, _toDisplayString($setup.secondaryAttorneys[0].directoryPerson.fullName), 1),
            _cache[34] || (_cache[34] = _createTextVNode(" accept the appointment as a substitute enduring guardian. "))
          ]),
          _cache[36] || (_cache[36] = _createStaticVNode("<div class=\"sign-container\"><div class=\"sign\">Signature</div><div class=\"sign\">Date</div></div><h4>Witnessed by a person authorised to witness Statutory Declarations:</h4><div class=\"sign-container\"><div class=\"sign\">Authorised witness&#39;s signature:</div><div class=\"sign\">Authorised witness&#39;s full name:</div><div class=\"sign\">Authorised witness&#39;s address:</div><div class=\"sign\">Authorised witness&#39;s occupation:</div><div class=\"sign\">Date</div></div><h4>and by another person:</h4><div class=\"sign-container\"><div class=\"sign\">Other witness&#39;s signature:</div><div class=\"sign\">Other witness&#39;s full name:</div><div class=\"sign\">Other witness&#39;s address:</div><div class=\"sign\">Date</div></div>", 5))
        ]))
      : _createCommentVNode("", true)
  ]))
}