<template>
  <article>
    <div class="text-center">
      <p><em>Powers of Attorney Act 2000</em></p>
      <h2>PARTICULAR ENDURING POWER OF ATTORNEY</h2>
    </div>
    <p>
      <strong>THIS PARTICULAR ENDURING POWER OF ATTORNEY</strong> is made under the
      <em>Powers of Attorney Act 2000</em>.
    </p>
    <ul>
      <li>
        Name of donor: <strong>{{ ownerPerson?.fullName }}</strong>
      </li>
      <li>Address of donor: {{ ownerPerson?.fullAddress.replace('of', '') }}</li>
    </ul>
    <section>
      <h3>APPOINTMENT OF {{ plural('attorney', primaryAttorneys).toUpperCase() }}</h3>
      <ol>
        <li>
          <strong>I APPOINT</strong>
          <ol type="a">
            <li :key="attorney.id" v-for="attorney of primaryAttorneys">
              <strong>{{ attorney.directoryPerson.fullName }}</strong>
              {{ attorney.directoryPerson.identifiers }}
            </li>
          </ol>
          to be my {{ plural('attorney', primaryAttorneys) }}
          <template v-if="hasMultipleAttorneys"> {{ mf('attorney_decisions', poa?.meta) }}</template
          >.
        </li>
        <li>
          <strong>I AUTHORISE</strong> my {{ plural('attorney', primaryAttorneys) }} to do on my
          behalf anything that I may lawfully do, subject to the following limitations and/or
          conditions:
          <ul>
            <li>
              This particular enduring power of attorney will only become operative and have full
              force and effect in the event that I suffer from mental incapacity.
            </li>
            <li v-if="hasLimitations">
              {{ mf('limitations', poa?.meta) }}
            </li>
          </ul>
        </li>
        <li>
          <strong>I DECLARE</strong> that this particular enduring power of attorney will continue
          to operate and have full force and effect despite any subsequent mental incapacity I may
          suffer.
        </li>
      </ol>
    </section>
    <section>
      <h3>ATTESTATION</h3>
      <ol>
        <li>
          <h4>DONOR</h4>
          <div class="sign-container">
            <div class="sign-prefixed">
              <div class="sign-prefix">
                {{ ownerPerson?.fullName }}
              </div>
              <div class="sign">Name</div>
            </div>
            <div class="sign">Signature</div>
            <div class="sign">Date</div>
          </div>
        </li>
        <li>
          <h4>WITNESSES</h4>
          <p>
            We certify that the Donor has signed this particular enduring power of attorney in our
            presence. I certify that I am not a party to this particular enduring power of attorney
            nor a close relative to a party to it.
          </p>
          <div class="sign-container">
            <div class="sign">Signature of first witness</div>
            <div class="sign">Name of first witness</div>
            <div class="sign">Address of first witness</div>
            <div class="sign">Date</div>
          </div>
          <div class="sign-container">
            <div class="sign">Signature of second witness</div>
            <div class="sign">Name of second witness</div>
            <div class="sign">Address of second witness</div>
            <div class="sign">Date</div>
          </div>
        </li>
      </ol>
    </section>
    <section>
      <h3>STATEMENT OF ACCEPTANCE OF A PARTICULAR ENDURING POWER OF ATTORNEY</h3>
      <p>
        {{ ifPlural('We', 'I', primaryAttorneys) }}, the above-named
        {{ plural('attorney', primaryAttorneys) }} under the power created by this particular
        enduring power of attorney on which this acceptance is endorsed accept the appointment and
        acknowledge –
      </p>
      <ol type="a">
        <li>
          that this particular enduring power of attorney is an enduring power of attorney and may
          be exercised by
          {{ ifPlural('us', 'me', primaryAttorneys) }} in the event of any subsequent mental
          incapacity of the donor; and
        </li>
        <li>
          that {{ ifPlural('we', 'I', primaryAttorneys) }} will, by accepting this particular
          enduring power of attorney, be subject to the requirements of the Powers of Attorney Act
          2000.
        </li>
      </ol>
      <div class="sign-container" :key="attorney.id" v-for="attorney of primaryAttorneys">
        <div class="sign-prefixed">
          <div class="sign-prefix">
            {{ attorney.directoryPerson.fullName }}
          </div>
          <div class="sign">Name</div>
        </div>
        <div class="sign">Signature</div>
        <div class="sign">Date</div>
      </div>
    </section>
  </article>
</template>

<script lang="ts">
import poa from '@/hooks/poa';
import { mf, plural, ifPlural } from '@/utils/format';
import { financialPoaKey, ownerPersonKey } from '@/types/commonTypes';
import { inject } from 'vue';

export default {
  setup() {
    const ownerPerson = inject(ownerPersonKey);
    const financialPoa = inject(financialPoaKey);

    const {
      actJointly,
      attorneys,
      hasInclusions,
      hasLimitations,
      hasMultipleAttorneys,
      inclusions,
      hasSecondaries,
      othersMayAct,
      primaryAttorneys,
      secondaryAttorneys,
    } = poa(financialPoa?.value);

    return {
      actJointly,
      attorneys,
      hasInclusions,
      hasLimitations,
      hasMultipleAttorneys,
      inclusions,
      hasSecondaries,
      othersMayAct,
      primaryAttorneys,
      secondaryAttorneys,
      ownerPerson,
      poa: financialPoa,
    };
  },
  methods: {
    mf,
    plural,
    ifPlural,
  },
};
</script>
