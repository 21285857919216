<template>
  <article>
    <h1>ENDURING POWER OF ATTORNEY</h1>
    <p>(Pursuant to section 6 of the Powers of Attorney and Agency Act 1984)</p>
    <section>
      <h3>APPOINTMENT OF {{ plural('Donee', primaryAttorneys).toUpperCase() }}</h3>
      <ol>
        <li>
          I, <strong>{{ ownerPerson?.fullName }}</strong> {{ ownerPerson?.fullAddress }}, do hereby
          nominate, constitute and appoint the following
          {{ ifPlural('people', 'person', primaryAttorneys) }} to be my
          {{ plural('Donee', primaryAttorneys) }}:
          <ol type="a">
            <li :key="attorney.id" v-for="attorney of primaryAttorneys">
              <strong>{{ attorney.directoryPerson.fullName }}</strong>
              {{ attorney.directoryPerson.identifiers }}
            </li>
          </ol>
        </li>
        <li v-if="hasMultipleAttorneys">
          I would like my Donees to act
          {{ mf('attorney_decisions', poa?.meta).replace('or', 'and') }}.
        </li>
      </ol>
    </section>
    <section>
      <h3>COMMENCEMENT</h3>
      <ol :start="2 + hasMultipleAttorneys">
        <li>
          I declare this power to become effective only in the event of my suffering any subsequent
          legal incapacity.
        </li>
      </ol>
    </section>
    <section>
      <h3>AUTHORISATION</h3>
      <ol :start="3 + hasMultipleAttorneys">
        <li>
          I authorise my attorneys to do on my behalf anything I can legally do by an attorney.
        </li>
        <li>
          This authority is subject to the following conditions, limitations, or exclusions.
          <ol type="a">
            <li v-if="hasInclusions">
              I authorise my Donees to provide for the reasonable living and medical expenses for
              the following people:
              <ul>
                <li :key="inclusion.id" v-for="inclusion of inclusions">
                  <strong>{{ inclusion.directoryPerson.fullName }}</strong>
                  {{ inclusion.directoryPerson.identifiers }}
                </li>
              </ul>
            </li>
            <li v-if="hasMultipleAttorneys && actJointly">
              I {{ othersMayAct ? 'do not want' : 'want' }} the appointment under this document to
              be terminated if one of my joint Donees dies, loses capacity or otherwise resigns.
            </li>
            <li v-if="hasLimitations">
              {{ mf('limitations', poa?.meta) }}
            </li>
          </ol>
        </li>
      </ol>
    </section>
    <section>
      <h3>EXECUTION</h3>
      <ol :start="5 + hasMultipleAttorneys">
        <li>
          <p>IN WITNESS WHEREOF, I the Donor have hereto set my hand and seal:</p>
          <div class="sign-container">
            <div class="sign">Date</div>
          </div>
          <p>SIGNED BY THE DONOR AS A DEED</p>
          <div class="sign-container">
            <div class="sign">Signature of {{ ownerPerson?.fullName }}</div>
          </div>
          <p>IN THE PRESENCE OF:</p>
          <div class="sign-container">
            <div class="sign">Signature of witness authorised at law to take affidavits</div>
            <div class="sign">Witness name</div>
          </div>
        </li>
      </ol>
    </section>
    <section>
      <h3>FORM OF ACCEPTANCE</h3>
      <ol :start="6 + hasMultipleAttorneys">
        <li>
          {{ ifPlural('We', 'I', primaryAttorneys) }},
          <ul>
            <li :key="attorney.id" v-for="attorney of primaryAttorneys">
              <strong>{{ attorney.directoryPerson.fullName }}</strong>
              {{ attorney.directoryPerson.identifiers }}
            </li>
          </ul>
          the {{ plural('person', primaryAttorneys) }} appointed to be the
          {{ plural('donee', primaryAttorneys) }}
          of the power of attorney created by the instrument on which this acceptance is endorsed
          accept the appointment and acknowledge:
          <ol>
            <li>
              that the power of attorney is an enduring power of attorney and as such may be
              exercised by
              {{ ifPlural('us', 'me', primaryAttorneys) }} in the event of any subsequent legal
              incapacity of the donor; and
            </li>
            <li>
              that {{ ifPlural('we', 'I', primaryAttorneys) }} will, by accepting this power of
              attorney, be subject to the requirements of the Powers of Attorney and Agency Act
              1984.
            </li>
          </ol>
          <div class="sign-container" :key="attorney.id" v-for="attorney of primaryAttorneys">
            <div class="sign">
              Signed by {{ attorney.directoryPerson.fullName }}
              {{ attorney.directoryPerson.identifiers }}
            </div>
            <div class="sign">Date</div>
          </div>
        </li>
      </ol>
    </section>
  </article>
</template>

<script lang="ts">
import poa from '@/hooks/poa';
import { mf, plural, ifPlural } from '@/utils/format';
import { financialPoaKey, ownerPersonKey } from '@/types/commonTypes';
import { inject } from 'vue';

export default {
  setup() {
    const ownerPerson = inject(ownerPersonKey);
    const financialPoa = inject(financialPoaKey);

    const {
      actJointly,
      attorneys,
      hasInclusions,
      hasLimitations,
      hasMultipleAttorneys,
      inclusions,
      hasSecondaries,
      othersMayAct,
      primaryAttorneys,
      secondaryAttorneys,
    } = poa(financialPoa?.value);

    return {
      actJointly,
      attorneys,
      hasInclusions,
      hasLimitations,
      hasMultipleAttorneys,
      inclusions,
      hasSecondaries,
      othersMayAct,
      primaryAttorneys,
      secondaryAttorneys,
      ownerPerson,
      poa: financialPoa,
    };
  },
  methods: {
    mf,
    plural,
    ifPlural,
  },
};
</script>
