<template>
  <article>
    <h2>ADVANCE CARE DIRECTIVE FORM</h2>
    <section>
      <h3>Part 1: Personal Details</h3>
      <ul>
        <li>
          Name:
          <strong>{{ ownerPerson?.fullName }}</strong>
        </li>
        <li>Address: {{ ownerPerson?.fullAddress.replace('of ', '') }}</li>
        <li v-if="ownerPerson?.defaultPhoneNumber">
          Phone number: {{ ownerPerson?.defaultPhoneNumber }}
        </li>
        <li>Date of birth: {{ ownerPerson?.dateOfBirthFormatted }}</li>
      </ul>
    </section>
    <section>
      <h3>Part 2{{ hasMultipleAttorneys ? 'a' : '' }}: Appointing Substitute Decision-Makers</h3>
      <ol>
        <li :key="attorney.id" v-for="(attorney, index) of primaryAttorneys">
          <p>
            I <strong>appoint</strong>:
            <strong>{{ attorney.directoryPerson.fullName }}</strong>
            {{ attorney.directoryPerson.identifiers }}
          </p>
          <p><em>Your Substitute Decision-Maker must sign below:</em></p>
          <ul>
            <li>
              I, <strong>{{ attorney.directoryPerson.fullName }}</strong> am over 18 years old, and
              I understand and accept my role and the responsibilities of being a Substitute
              Decision-Maker as set out in the Substitute Decision-Maker Guidelines.
              <div class="sign-container">
                <div class="sign">Signature</div>
                <div class="sign">Date</div>
              </div>
            </li>
          </ul>
          {{ index === primaryAttorneys?.length - 1 ? '' : 'AND' }}
        </li>
      </ol>
    </section>
    <section v-if="hasMultipleAttorneys">
      <h3>Part 2b: Conditions Of Appointment</h3>
      <p>
        I want my Substitute Decision-Makers to make decisions
        {{
          mf('attorney_decisions', poa?.meta)
            .replace('jointly', 'together')
            .replace('severally', 'separately')
        }}.
      </p>
    </section>
    <section>
      <h3>Part 3: What Is Important To Me – My Values and Wishes</h3>
      <p>
        {{ hasPreferences ? mf('preferences', poa?.meta) : 'N/A' }}
      </p>
    </section>
    <section>
      <h3>Part 4: Binding Refusals Of Health Care</h3>
      <p>
        I make the following binding refusal/s of particular health care:<br />
        {{ hasLimitations ? mf('limitations', poa?.meta) : 'N/A' }}
      </p>
    </section>
    <section>
      <h3>Part 5: Interpreter Statement</h3>
      <p>N/A</p>
    </section>
    <section>
      <h3>Part 6: Witnessing My Advance Care Directive</h3>
      <p>
        I, <strong>{{ ownerPerson?.fullName }}</strong
        >, do hereby give this Advance Care Directive of my own free will.
      </p>
      <p>
        I certify that I was given the Advance Care Directive Information Statement and that I
        understand the information contained in the Statement.
      </p>
      <div class="sign-container">
        <div class="sign">Signature</div>
        <div class="sign">Date</div>
      </div>
      <h4>Witness statement</h4>
      <div class="sign-container">
        <div class="sign-prefixed">
          <div class="sign-prefix">I,</div>
          <div class="sign">Witness full name</div>
        </div>
      </div>
      <p>
        have read and understood the Information for Witnesses guide and certify that I gave
        <strong>{{ ownerPerson?.fullName }}</strong> the Advance Care Directive Information
        Statement. In my opinion he/she appeared to understand the information and explanation given
        and did not appear to be acting under any form of duress or coercion. He/she signed this
        Advance Care Directive in my presence.
      </p>
      <div class="sign-container">
        <div class="sign">Authorised witness category</div>
        <div class="sign">Phone number</div>
        <div class="sign">Signature</div>
        <div class="sign">Date</div>
      </div>
    </section>
  </article>
</template>

<script lang="ts">
import { inject } from 'vue';
import poa from '@/hooks/poa';
import { mf, plural, ifPlural } from '@/utils/format';
import { medicalPoaKey, ownerPersonKey } from '@/types/commonTypes';

export default {
  setup() {
    const ownerPerson = inject(ownerPersonKey);
    const medicalPoa = inject(medicalPoaKey);

    const {
      actJointly,
      attorneys,
      hasInclusions,
      hasLimitations,
      hasPreferences,
      hasMultipleAttorneys,
      inclusions,
      hasSecondaries,
      othersMayAct,
      primaryAttorneys,
      secondaryAttorneys,
    } = poa(medicalPoa?.value);

    return {
      actJointly,
      attorneys,
      hasInclusions,
      hasLimitations,
      hasPreferences,
      hasMultipleAttorneys,
      inclusions,
      hasSecondaries,
      othersMayAct,
      primaryAttorneys,
      secondaryAttorneys,
      poa: medicalPoa,
      ownerPerson,
    };
  },
  methods: {
    mf,
    plural,
    ifPlural,
  },
};
</script>
