import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("article", null, [
    _cache[9] || (_cache[9] = _createElementVNode("div", { class: "text-center" }, [
      _createElementVNode("h4", null, "Form 2"),
      _createElementVNode("h2", null, "Revocation of appointment of enduring guardian")
    ], -1)),
    _createElementVNode("p", null, [
      _cache[0] || (_cache[0] = _createTextVNode(" I, ")),
      _createElementVNode("strong", null, _toDisplayString($setup.ownerPerson?.fullName), 1),
      _createTextVNode(" " + _toDisplayString($setup.ownerPerson?.fullAddress) + ", hereby revoke the appointment of each of the following people as my enduring guardian: ", 1)
    ]),
    _cache[10] || (_cache[10] = _createStaticVNode("<div class=\"sign-container\"><div class=\"sign-prefixed\"><div class=\"sign-prefix\">·</div><div class=\"sign\"> </div></div></div><div class=\"sign-container\"><div class=\"sign-prefixed\"><div class=\"sign-prefix\">·</div><div class=\"sign\"> </div></div></div><div class=\"sign-container\"><div class=\"sign-prefixed\"><div class=\"sign-prefix\">·</div><div class=\"sign\"> </div></div></div><div class=\"sign-container\"><div class=\"sign-prefixed\"><div class=\"sign-prefix\">·</div><div class=\"sign\"> </div></div></div><p> I understand that this revocation will not be effective unless the enduring guardian is or has been given written notice of the revocation. </p><div class=\"sign-container\"><div class=\"sign\">Signature</div><div class=\"sign\">Date</div></div><h3>Certificate of witness</h3>", 7)),
    _createElementVNode("p", null, [
      _cache[1] || (_cache[1] = _createTextVNode(" I, ")),
      _cache[2] || (_cache[2] = _createElementVNode("span", { class: "wide-underline" }, null, -1)),
      _cache[3] || (_cache[3] = _createTextVNode(" of ")),
      _cache[4] || (_cache[4] = _createElementVNode("span", { class: "wide-underline" }, null, -1)),
      _cache[5] || (_cache[5] = _createTextVNode(", ")),
      _cache[6] || (_cache[6] = _createElementVNode("span", { class: "wide-underline" }, null, -1)),
      _cache[7] || (_cache[7] = _createTextVNode(" certify that ")),
      _createElementVNode("strong", null, _toDisplayString($setup.ownerPerson?.fullName), 1),
      _cache[8] || (_cache[8] = _createTextVNode(" appeared to understand the effect of this instrument and in my presence executed the instrument voluntarily. "))
    ]),
    _cache[11] || (_cache[11] = _createElementVNode("div", { class: "sign-container" }, [
      _createElementVNode("div", { class: "sign" }, "Signature"),
      _createElementVNode("div", { class: "sign" }, "Date")
    ], -1)),
    _cache[12] || (_cache[12] = _createElementVNode("p", null, [
      _createElementVNode("strong", null, "Tick applicable:")
    ], -1)),
    _cache[13] || (_cache[13] = _createElementVNode("ul", { class: "checkboxes" }, [
      _createElementVNode("li", null, "Australian legal practitioner"),
      _createElementVNode("li", null, "Registrar of the Local Court"),
      _createElementVNode("li", null, "overseas-registered foreign lawyer"),
      _createElementVNode("li", null, "justice of the peace"),
      _createElementVNode("li", null, "approved employee of NSW Trustee and Guardian or the Office of the Public Guardian")
    ], -1))
  ]))
}