import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { type: "a" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "sign-container" }
const _hoisted_4 = { class: "sign-prefixed" }
const _hoisted_5 = { class: "sign-prefix" }
const _hoisted_6 = { type: "a" }
const _hoisted_7 = { class: "sign-prefixed" }
const _hoisted_8 = { class: "sign-prefix" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("article", null, [
    _cache[16] || (_cache[16] = _createElementVNode("div", { class: "text-center" }, [
      _createElementVNode("p", null, [
        _createElementVNode("em", null, "Powers of Attorney Act 2000")
      ]),
      _createElementVNode("h2", null, "PARTICULAR ENDURING POWER OF ATTORNEY")
    ], -1)),
    _cache[17] || (_cache[17] = _createElementVNode("p", null, [
      _createElementVNode("strong", null, "THIS PARTICULAR ENDURING POWER OF ATTORNEY"),
      _createTextVNode(" is made under the "),
      _createElementVNode("em", null, "Powers of Attorney Act 2000"),
      _createTextVNode(". ")
    ], -1)),
    _createElementVNode("ul", null, [
      _createElementVNode("li", null, [
        _cache[0] || (_cache[0] = _createTextVNode(" Name of donor: ")),
        _createElementVNode("strong", null, _toDisplayString($setup.ownerPerson?.fullName), 1)
      ]),
      _createElementVNode("li", null, "Address of donor: " + _toDisplayString($setup.ownerPerson?.fullAddress.replace('of', '')), 1)
    ]),
    _createElementVNode("section", null, [
      _createElementVNode("h3", null, "APPOINTMENT OF " + _toDisplayString($options.plural('attorney', $setup.primaryAttorneys).toUpperCase()), 1),
      _createElementVNode("ol", null, [
        _createElementVNode("li", null, [
          _cache[1] || (_cache[1] = _createElementVNode("strong", null, "I APPOINT", -1)),
          _createElementVNode("ol", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.primaryAttorneys, (attorney) => {
              return (_openBlock(), _createElementBlock("li", {
                key: attorney.id
              }, [
                _createElementVNode("strong", null, _toDisplayString(attorney.directoryPerson.fullName), 1),
                _createTextVNode(" " + _toDisplayString(attorney.directoryPerson.identifiers), 1)
              ]))
            }), 128))
          ]),
          _createTextVNode(" to be my " + _toDisplayString($options.plural('attorney', $setup.primaryAttorneys)) + " ", 1),
          ($setup.hasMultipleAttorneys)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createTextVNode(_toDisplayString($options.mf('attorney_decisions', $setup.poa?.meta)), 1)
              ], 64))
            : _createCommentVNode("", true),
          _cache[2] || (_cache[2] = _createTextVNode(". "))
        ]),
        _createElementVNode("li", null, [
          _cache[4] || (_cache[4] = _createElementVNode("strong", null, "I AUTHORISE", -1)),
          _createTextVNode(" my " + _toDisplayString($options.plural('attorney', $setup.primaryAttorneys)) + " to do on my behalf anything that I may lawfully do, subject to the following limitations and/or conditions: ", 1),
          _createElementVNode("ul", null, [
            _cache[3] || (_cache[3] = _createElementVNode("li", null, " This particular enduring power of attorney will only become operative and have full force and effect in the event that I suffer from mental incapacity. ", -1)),
            ($setup.hasLimitations)
              ? (_openBlock(), _createElementBlock("li", _hoisted_2, _toDisplayString($options.mf('limitations', $setup.poa?.meta)), 1))
              : _createCommentVNode("", true)
          ])
        ]),
        _cache[5] || (_cache[5] = _createElementVNode("li", null, [
          _createElementVNode("strong", null, "I DECLARE"),
          _createTextVNode(" that this particular enduring power of attorney will continue to operate and have full force and effect despite any subsequent mental incapacity I may suffer. ")
        ], -1))
      ])
    ]),
    _createElementVNode("section", null, [
      _cache[11] || (_cache[11] = _createElementVNode("h3", null, "ATTESTATION", -1)),
      _createElementVNode("ol", null, [
        _createElementVNode("li", null, [
          _cache[9] || (_cache[9] = _createElementVNode("h4", null, "DONOR", -1)),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, _toDisplayString($setup.ownerPerson?.fullName), 1),
              _cache[6] || (_cache[6] = _createElementVNode("div", { class: "sign" }, "Name", -1))
            ]),
            _cache[7] || (_cache[7] = _createElementVNode("div", { class: "sign" }, "Signature", -1)),
            _cache[8] || (_cache[8] = _createElementVNode("div", { class: "sign" }, "Date", -1))
          ])
        ]),
        _cache[10] || (_cache[10] = _createStaticVNode("<li><h4>WITNESSES</h4><p> We certify that the Donor has signed this particular enduring power of attorney in our presence. I certify that I am not a party to this particular enduring power of attorney nor a close relative to a party to it. </p><div class=\"sign-container\"><div class=\"sign\">Signature of first witness</div><div class=\"sign\">Name of first witness</div><div class=\"sign\">Address of first witness</div><div class=\"sign\">Date</div></div><div class=\"sign-container\"><div class=\"sign\">Signature of second witness</div><div class=\"sign\">Name of second witness</div><div class=\"sign\">Address of second witness</div><div class=\"sign\">Date</div></div></li>", 1))
      ])
    ]),
    _createElementVNode("section", null, [
      _cache[15] || (_cache[15] = _createElementVNode("h3", null, "STATEMENT OF ACCEPTANCE OF A PARTICULAR ENDURING POWER OF ATTORNEY", -1)),
      _createElementVNode("p", null, _toDisplayString($options.ifPlural('We', 'I', $setup.primaryAttorneys)) + ", the above-named " + _toDisplayString($options.plural('attorney', $setup.primaryAttorneys)) + " under the power created by this particular enduring power of attorney on which this acceptance is endorsed accept the appointment and acknowledge – ", 1),
      _createElementVNode("ol", _hoisted_6, [
        _createElementVNode("li", null, " that this particular enduring power of attorney is an enduring power of attorney and may be exercised by " + _toDisplayString($options.ifPlural('us', 'me', $setup.primaryAttorneys)) + " in the event of any subsequent mental incapacity of the donor; and ", 1),
        _createElementVNode("li", null, " that " + _toDisplayString($options.ifPlural('we', 'I', $setup.primaryAttorneys)) + " will, by accepting this particular enduring power of attorney, be subject to the requirements of the Powers of Attorney Act 2000. ", 1)
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.primaryAttorneys, (attorney) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "sign-container",
          key: attorney.id
        }, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, _toDisplayString(attorney.directoryPerson.fullName), 1),
            _cache[12] || (_cache[12] = _createElementVNode("div", { class: "sign" }, "Name", -1))
          ]),
          _cache[13] || (_cache[13] = _createElementVNode("div", { class: "sign" }, "Signature", -1)),
          _cache[14] || (_cache[14] = _createElementVNode("div", { class: "sign" }, "Date", -1))
        ]))
      }), 128))
    ])
  ]))
}