<template>
  <div class="container">
    <article>
      <h1>Instructions for signing your Enduring Guardianship - (TAS)</h1>
      <p>
        Thank you for choosing Safewill. Your Enduring Guardianship is almost complete - follow the
        steps below, provided by our legal advisers, to sign your Enduring Guardianship and have it
        witnessed.
      </p>
      <h4><em>Step 1 – Print your Enduring Guardianship</em></h4>
      <p>
        Print your Enduring Guardianship on A4 paper, read it carefully and ensure that you
        understand it completely. If you do not understand what you are signing, we recommend
        seeking independent legal advice.
      </p>
      <h4><em>Step 2 – Find two people to act as witnesses</em></h4>
      <p>
        Once you have understood your Enduring Guardianship, you will need to find two witnesses to
        sign the document and witness your signing of it. The Enduring Guardianship can only be
        witnessed by someone who:
      </p>
      <ul>
        <li><strong>is</strong> 18 years of age or older; and</li>
        <li>
          <strong>is not</strong>
          <ul>
            <li>someone being appointed as an guardian; or</li>
            <li>a close relative of you or your guardian.</li>
          </ul>
        </li>
      </ul>
      <p>
        The witnesses are required to certify the understanding of both the appointor and the
        guardian(s). They are required to certify that both the appointor and guardian(s) executed
        the document voluntarily and appeared to understand the effect of the Enduring Guardianship.
      </p>
      <h4><em>Step 3 – Signing your Enduring Guardianship</em></h4>
      <p>
        Sign using a blue or black pen. You should sign the Enduring Guardianship first, in the
        presence of both your witnesses. Make sure your witnesses are watching you sign and date the
        document, before signing themselves. Signing the bottom of each page of the document is not
        mandatory, however we recommend doing so to protect against pages being substituted. You
        will then need to arrange for your guardian(s) to sign.
      </p>
      <h4><em>Step 4 – Register your Enduring Guardianship</em></h4>
      <p>
        Your Enduring Guardianship must be registered before it can be legally binding . You will
        need to present your Enduring Guardianship to a Service Tasmania outlet and pay the
        application fee (or apply for a waiver of the fee on the basis of financial hardship).
      </p>
      <h4><em>Step 5 – Keep your Enduring Guardianship safe</em></h4>
      <p>
        It is important to store your Enduring Guardianship in a safe place and provide your
        guardian with a copy. You should also keep a copy with someone you trust like your solicitor
        or doctor.
      </p>
      <h4><em>Note on Revocation</em></h4>
      <p>
        In Tasmania, when you sign a new Enduring Guardianship, any previously made Enduring
        Guardianship will be revoked.
      </p>
    </article>
    <AppointmentOfAttorneys />
  </div>
</template>

<script lang="ts">
import AppointmentOfAttorneys from './components/medical/AppointmentOfAttorneys.vue';

export default {
  components: {
    AppointmentOfAttorneys,
  },
};
</script>
