<template>
  <div class="container">
    <article>
      <h1>Instructions for signing your Advance Personal Plan - Financial (NT)</h1>
      <p>
        Thank you for choosing Safewill. Your Advance Personal Plan is almost complete - follow the
        steps below, provided by our legal advisers, to sign your Advance Personal Plan and have it
        witnessed.
      </p>
      <h4><em>Step 1 – Print your Advance Personal Plan</em></h4>
      <p>
        Print your Advance Personal Plan, read it carefully and ensure that you understand it
        completely. If you do not understand what you are signing, we recommend seeking independent
        legal advice.
      </p>
      <h4><em>Step 2 – Find a suitable witness</em></h4>
      <p>
        Once you have understood your Advance Personal Plan, you will need to find a person to
        witness your signing of it. The Advance Personal Plan can only be witnessed by someone who
        is:
      </p>
      <ul>
        <li>
          a justice of the peace, legal practitioner or police officer and any other Commissioner
          for Oaths;
        </li>
        <li>
          a health practitioner (including doctors, nurses, pharmacists, Aboriginal and Torres
          Strait Islander health practitioners and other health professionals);
        </li>
        <li>an accountant;</li>
        <li>a social worker;</li>
        <li>a chief executive officer of a local government council; or</li>
        <li>a principal of a Northern Territory school.</li>
      </ul>
      <p>
        The witness is required to confirm that you appear to understand the nature and effect of
        the Advance Personal Plan and that you are making the Advance Personal Plan voluntarily.
      </p>
      <h4><em>Step 3 – Signing your Advance Personal Plan</em></h4>
      <p>
        Sign using a blue or black pen. You should first sign the Advance Personal Plan in the
        presence of your witness. Make sure your witness is watching you sign and date the document.
        After this your witness must also sign where indicated. Signing the bottom of each page of
        the document is not mandatory, however we recommend doing so to protect against pages being
        substituted.
      </p>
      <h4><em>Step 4 – Keep your Advance Personal Plan safe</em></h4>
      <p>
        It is important to store your Advance Personal Plan in a safe place and provide your
        decision maker(s) with a copy. You should also keep a copy with someone you trust like your
        solicitor or accountant and consider updating your eHealth record if your Advance Personal
        Plan deals with health care matters.
      </p>
    </article>
    <AppointmentOfAttorneys />
    <section>
      <h2>REVOCATION</h2>
      <p>
        All previous and existing Advance Personal Plans that have not otherwise been revoked will
        continue to operate even after the making of this Advance Personal Plan. However, any
        Advance Personal Plan is revoked to the extent of any inconsistency with a later Advance
        Personal Plan.
      </p>
    </section>
  </div>
</template>

<script lang="ts">
import AppointmentOfAttorneys from './components/financial/AppointmentOfAttorneys.vue';

export default {
  components: {
    AppointmentOfAttorneys,
  },
};
</script>
