import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "text-center" }
const _hoisted_2 = { type: "a" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = ["start"]
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { class: "sign-container" }
const _hoisted_11 = { class: "sign-prefixed" }
const _hoisted_12 = { class: "sign-prefix" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("article", null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[0] || (_cache[0] = _createElementVNode("p", null, [
        _createElementVNode("em", null, "Guardianship and Administration Act 1995 (TAS)"),
        _createTextVNode(" – Form 1 (Section 32)")
      ], -1)),
      _createElementVNode("h2", null, " INSTRUMENT APPOINTING ENDURING " + _toDisplayString($options.plural('GUARDIAN', $setup.primaryAttorneys).toUpperCase()), 1)
    ]),
    _createElementVNode("section", null, [
      _createElementVNode("ol", null, [
        _createElementVNode("li", null, [
          _createElementVNode("p", null, [
            _cache[1] || (_cache[1] = _createTextVNode(" I, ")),
            _createElementVNode("strong", null, _toDisplayString($setup.ownerPerson?.fullName), 1),
            _createTextVNode(" " + _toDisplayString($setup.ownerPerson?.fullAddress) + ", born on " + _toDisplayString($setup.ownerPerson?.dateOfBirthFormatted) + ", ", 1),
            _cache[2] || (_cache[2] = _createElementVNode("strong", null, "appoint", -1)),
            _createTextVNode(" the following " + _toDisplayString($options.ifPlural('people', 'person', $setup.primaryAttorneys)) + " to be my " + _toDisplayString($options.ifPlural('joint', '', $setup.primaryAttorneys)) + " Enduring " + _toDisplayString($options.plural('Guardian', $setup.primaryAttorneys)) + ": ", 1)
          ]),
          _createElementVNode("ol", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.primaryAttorneys, (attorney) => {
              return (_openBlock(), _createElementBlock("li", {
                key: attorney.id
              }, [
                _createElementVNode("strong", null, _toDisplayString(attorney.directoryPerson.fullName), 1),
                _createTextVNode(" " + _toDisplayString(attorney.directoryPerson.identifiers), 1)
              ]))
            }), 128))
          ])
        ]),
        ($setup.hasSecondaries)
          ? (_openBlock(), _createElementBlock("li", _hoisted_3, [
              _createTextVNode(" In the event that " + _toDisplayString($options.ifPlural('one of my joint guardians', 'my guardian', $setup.primaryAttorneys)) + " becomes incapable or unavailable to exercise this appointment, I appoint ", 1),
              _createElementVNode("strong", null, _toDisplayString($setup.secondaryAttorneys[0].directoryPerson.fullName), 1),
              _createTextVNode(" " + _toDisplayString($setup.secondaryAttorneys[0].directoryPerson.identifiers) + " as an alternative to that guardian. ", 1)
            ]))
          : _createCommentVNode("", true),
        ($setup.othersMayAct)
          ? (_openBlock(), _createElementBlock("li", _hoisted_4, " I do not want the death, resignation or incapacity of any of my joint guardians to terminate the appointment of each of my other joint guardians. "))
          : _createCommentVNode("", true)
      ]),
      _cache[3] || (_cache[3] = _createElementVNode("p", null, [
        _createTextVNode(" By executing this instrument appointing an enduring guardian, I hereby revoke any and all former instruments made by me which appoint any enduring guardians or alternative guardians and such instruments shall cease to have effect upon the registration of this instrument made by me pursuant to Part 5 of the "),
        _createElementVNode("em", null, "Guardianship and Administration Act 1995"),
        _createTextVNode(". Subject to any conditions specified below, I authorise my guardian, in the event that I become unable by reason of a disability to make reasonable judgments in respect of matters relating to my personal circumstances to exercise the powers of a guardian under Section 25 of the "),
        _createElementVNode("em", null, "Guardianship and Administration Act 1995"),
        _createTextVNode(". ")
      ], -1))
    ]),
    _createElementVNode("section", null, [
      _cache[8] || (_cache[8] = _createElementVNode("h3", null, "CONDITIONS UPON APPOINTMENT", -1)),
      _createElementVNode("ol", {
        start: 2 + $setup.hasSecondaries + $setup.othersMayAct
      }, [
        _createElementVNode("li", null, [
          _cache[7] || (_cache[7] = _createElementVNode("p", null, " I require my guardian to observe the following conditions in exercising, or, in relation to the exercise of, the powers conferred by this instrument: ", -1)),
          ($setup.hasLimitations || $setup.hasPreferences)
            ? (_openBlock(), _createElementBlock("ul", _hoisted_6, [
                ($setup.hasLimitations)
                  ? (_openBlock(), _createElementBlock("li", _hoisted_7, [
                      _cache[4] || (_cache[4] = _createElementVNode("strong", null, "Limitations:", -1)),
                      _createTextVNode(" " + _toDisplayString($options.mf('limitations', $setup.poa?.meta)), 1)
                    ]))
                  : _createCommentVNode("", true),
                ($setup.hasPreferences)
                  ? (_openBlock(), _createElementBlock("li", _hoisted_8, [
                      _cache[5] || (_cache[5] = _createElementVNode("strong", null, "Preferences:", -1)),
                      _createTextVNode(" " + _toDisplayString($options.mf('preferences', $setup.poa?.meta)), 1)
                    ]))
                  : _createCommentVNode("", true)
              ]))
            : (_openBlock(), _createElementBlock("ul", _hoisted_9, _cache[6] || (_cache[6] = [
                _createElementVNode("li", null, "N/A", -1)
              ])))
        ])
      ], 8, _hoisted_5)
    ]),
    _createElementVNode("section", null, [
      _cache[12] || (_cache[12] = _createElementVNode("h3", null, "SIGNATURES", -1)),
      _cache[13] || (_cache[13] = _createElementVNode("h4", null, "APPOINTOR", -1)),
      _cache[14] || (_cache[14] = _createElementVNode("p", null, [
        _createTextVNode(" This is an appointment of an enduring guardian made under Part 5 of the "),
        _createElementVNode("em", null, "Guardianship Administration Act 1995"),
        _createTextVNode(". I acknowledge that this is a public document and is available for public scrutiny. ")
      ], -1)),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, _toDisplayString($setup.ownerPerson?.fullName), 1),
          _cache[9] || (_cache[9] = _createElementVNode("div", { class: "sign" }, "Name", -1))
        ]),
        _cache[10] || (_cache[10] = _createElementVNode("div", { class: "sign" }, "Signature", -1)),
        _cache[11] || (_cache[11] = _createElementVNode("div", { class: "sign" }, "Date", -1))
      ])
    ]),
    _createElementVNode("section", null, [
      _cache[18] || (_cache[18] = _createElementVNode("h3", null, "WITNESSES", -1)),
      _createElementVNode("p", null, [
        _cache[15] || (_cache[15] = _createTextVNode(" As witnesses we certify that ")),
        _createElementVNode("strong", null, _toDisplayString($setup.ownerPerson?.fullName), 1),
        _cache[16] || (_cache[16] = _createTextVNode(" has signed this instrument freely and voluntarily in our presence and ")),
        _createElementVNode("strong", null, _toDisplayString($setup.ownerPerson?.fullName), 1),
        _cache[17] || (_cache[17] = _createTextVNode(" appears to understand the effect of this instrument. "))
      ]),
      _cache[19] || (_cache[19] = _createStaticVNode("<h4>Signature of witness 1</h4><div class=\"sign-container\"><div class=\"sign\">Signature</div><div class=\"sign\">Name</div><div class=\"sign\">Address</div></div><h4>Signature of witness 2</h4><div class=\"sign-container\"><div class=\"sign\">Signature</div><div class=\"sign\">Name</div><div class=\"sign\">Address</div></div>", 4))
    ]),
    _createElementVNode("section", null, [
      _cache[25] || (_cache[25] = _createElementVNode("h3", null, "ACCEPTANCE OF APPOINTMENT", -1)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.allAttorneys, (attorney) => {
        return (_openBlock(), _createElementBlock("div", {
          key: attorney.id
        }, [
          _createElementVNode("p", null, [
            _cache[20] || (_cache[20] = _createTextVNode(" I, ")),
            _createElementVNode("strong", null, _toDisplayString(attorney.directoryPerson.fullName), 1),
            _cache[21] || (_cache[21] = _createTextVNode(", accept appointment as a guardian under this instrument and undertake to exercise the powers conferred honestly and in accordance with the provisions of the ")),
            _cache[22] || (_cache[22] = _createElementVNode("em", null, "Guardianship and Administration Act 1995", -1)),
            _cache[23] || (_cache[23] = _createTextVNode(". "))
          ]),
          _cache[24] || (_cache[24] = _createElementVNode("div", { class: "sign-container" }, [
            _createElementVNode("div", { class: "sign" }, "Signature"),
            _createElementVNode("div", { class: "sign" }, "Date")
          ], -1))
        ]))
      }), 128))
    ])
  ]))
}