import { registerLocale } from 'i18n-iso-countries';
import { createApp } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';

import AppWrapper from './AppWrapper.vue';
import './index.css';
import routes from './routes';

// eslint-disable-next-line @typescript-eslint/no-require-imports
registerLocale(require('i18n-iso-countries/langs/en.json'));

const app = createApp(AppWrapper);
const router = createRouter({
  routes,
  history: createWebHistory(),
});

app.use(router);
app.mount('#app');
