import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { type: "a" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "checkboxes" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { key: 2 }
const _hoisted_10 = { class: "sign-container" }
const _hoisted_11 = { class: "sign-prefixed" }
const _hoisted_12 = { class: "sign-prefix" }
const _hoisted_13 = { class: "sign-prefixed" }
const _hoisted_14 = { class: "sign-prefix" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_strike = _resolveComponent("strike")!

  return (_openBlock(), _createElementBlock("article", null, [
    _cache[24] || (_cache[24] = _createElementVNode("div", { class: "text-center" }, [
      _createElementVNode("h1", null, "ENDURING POWER OF ATTORNEY"),
      _createElementVNode("p", null, [
        _createElementVNode("em", null, "Form 2 Power of Attorney Act 1998")
      ])
    ], -1)),
    _createElementVNode("section", null, [
      _cache[1] || (_cache[1] = _createElementVNode("h3", null, "SECTION 1: YOUR PERSONAL DETAILS", -1)),
      _createElementVNode("ul", null, [
        _createElementVNode("li", null, [
          _cache[0] || (_cache[0] = _createTextVNode(" Full name: ")),
          _createElementVNode("strong", null, _toDisplayString($setup.ownerPerson?.fullName), 1)
        ]),
        _createElementVNode("li", null, "Address: " + _toDisplayString($setup.ownerPerson?.fullAddress.replace('of', '')), 1),
        ($setup.ownerPerson?.defaultPhoneNumber)
          ? (_openBlock(), _createElementBlock("li", _hoisted_1, " Phone number: " + _toDisplayString($setup.ownerPerson?.defaultPhoneNumber), 1))
          : _createCommentVNode("", true),
        _createElementVNode("li", null, "Email: " + _toDisplayString($setup.ownerPerson?.defaultEmailAddress), 1)
      ])
    ]),
    _createElementVNode("section", null, [
      _cache[2] || (_cache[2] = _createElementVNode("h3", null, "SECTION 2: YOUR VIEWS, WISHES AND PREFERENCES", -1)),
      _createElementVNode("h4", null, " This is what I want my " + _toDisplayString($options.plural('attorney', $setup.primaryAttorneys)) + " to know about when making decisions for me: ", 1),
      _createElementVNode("ul", null, [
        ($setup.hasPreferences)
          ? (_openBlock(), _createElementBlock("li", _hoisted_2, _toDisplayString($options.mf('preferences', $setup.poa?.meta)), 1))
          : (_openBlock(), _createElementBlock("li", _hoisted_3, "N/A"))
      ])
    ]),
    _createElementVNode("section", null, [
      _createElementVNode("h3", null, "SECTION 3: YOUR " + _toDisplayString($options.plural('attorney', $setup.primaryAttorneys).toUpperCase()), 1),
      _createElementVNode("ol", _hoisted_4, [
        _createElementVNode("li", null, [
          _createElementVNode("strong", null, " I appoint the " + _toDisplayString($options.plural('person', $setup.allAttorneys)) + " listed below as my " + _toDisplayString($options.plural('attorney', $setup.allAttorneys)) + ": ", 1),
          _createTextVNode(" " + _toDisplayString($options.ifPlural('(in no particular order)', '', $setup.allAttorneys)) + " ", 1),
          _createElementVNode("ul", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.allAttorneys, (attorney) => {
              return (_openBlock(), _createElementBlock("li", {
                key: attorney.id
              }, [
                _cache[3] || (_cache[3] = _createTextVNode(" Full name: ")),
                _createElementVNode("strong", null, _toDisplayString(attorney.directoryPerson.fullName), 1),
                _createElementVNode("ul", null, [
                  _createElementVNode("li", null, "Address: " + _toDisplayString(attorney.directoryPerson.identifiers.replace('of', '')), 1),
                  (attorney.directoryPerson.defaultEmailAddress)
                    ? (_openBlock(), _createElementBlock("li", _hoisted_5, " Email: " + _toDisplayString(attorney.directoryPerson.defaultEmailAddress), 1))
                    : _createCommentVNode("", true)
                ])
              ]))
            }), 128))
          ])
        ]),
        _createElementVNode("li", null, [
          _createElementVNode("strong", null, " I authorise my " + _toDisplayString($options.plural('attorney', $setup.primaryAttorneys)) + " to exercise power for: ", 1),
          _createElementVNode("ul", _hoisted_6, [
            _cache[6] || (_cache[6] = _createElementVNode("li", { class: "checked" }, [
              _createElementVNode("strong", null, " personal (including health) matters only ")
            ], -1)),
            _createElementVNode("li", null, [
              _createVNode(_component_strike, null, {
                default: _withCtx(() => _cache[4] || (_cache[4] = [
                  _createTextVNode("financial matters only")
                ])),
                _: 1
              })
            ]),
            _createElementVNode("li", null, [
              _createVNode(_component_strike, null, {
                default: _withCtx(() => _cache[5] || (_cache[5] = [
                  _createTextVNode("personal (including health) matters and financial matters.")
                ])),
                _: 1
              })
            ])
          ])
        ]),
        ($setup.hasMultipleAttorneys && !$setup.hasSecondaries)
          ? (_openBlock(), _createElementBlock("li", _hoisted_7, [
              _createElementVNode("strong", null, " I authorise my attorneys to make decisions " + _toDisplayString($options.mf('attorney_decisions', $setup.poa?.meta).replace('or', 'and')) + ".", 1)
            ]))
          : ($setup.hasMultipleAttorneys && $setup.hasSecondaries)
            ? (_openBlock(), _createElementBlock("li", _hoisted_8, [
                _cache[7] || (_cache[7] = _createElementVNode("strong", null, "I have appointed ", -1)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.primaryAttorneys, (attorney, i) => {
                  return (_openBlock(), _createElementBlock(_Fragment, {
                    key: attorney.id
                  }, [
                    _createElementVNode("strong", null, _toDisplayString(attorney.directoryPerson.fullName), 1),
                    _createTextVNode(" " + _toDisplayString(i === $setup.primaryAttorneys?.length - 1 ? '' : ' and '), 1)
                  ], 64))
                }), 128)),
                _createTextVNode(" as my " + _toDisplayString($options.plural('attorney', $setup.primaryAttorneys)) + " for personal (including health) matters to act " + _toDisplayString($options.mf('attorney_decisions', $setup.poa?.meta).replace('or', 'and')) + ". If all of my attorneys are unable to act due to death or lack of capacity or vacation of office, ", 1),
                _createElementVNode("strong", null, "I appoint " + _toDisplayString($setup.secondaryAttorneys[0].directoryPerson.fullName), 1),
                _cache[8] || (_cache[8] = _createTextVNode(" as my successive attorney. "))
              ]))
            : (!$setup.hasMultipleAttorneys && $setup.hasSecondaries)
              ? (_openBlock(), _createElementBlock("li", _hoisted_9, [
                  _cache[9] || (_cache[9] = _createElementVNode("strong", null, "I have appointed ", -1)),
                  _createElementVNode("strong", null, _toDisplayString($setup.primaryAttorneys[0].directoryPerson.fullName), 1),
                  _cache[10] || (_cache[10] = _createTextVNode(" as my attorney for personal (including health) matters. If my attorney is unable to act due to death or lack of capacity or vacation of office ")),
                  _createElementVNode("strong", null, "I appoint " + _toDisplayString($setup.secondaryAttorneys[0].directoryPerson.fullName), 1),
                  _cache[11] || (_cache[11] = _createTextVNode(" as my successive attorney. "))
                ]))
              : _createCommentVNode("", true),
        _createElementVNode("li", null, [
          _createElementVNode("strong", null, " The general terms and instructions for my " + _toDisplayString($options.plural('attorney', $setup.allAttorneys)) + " " + _toDisplayString($options.ifPlural('are', 'is', $setup.allAttorneys)) + " as follows: ", 1),
          _cache[12] || (_cache[12] = _createElementVNode("br", null, null, -1)),
          ($setup.hasLimitations)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createTextVNode(_toDisplayString($options.mf('limitations', $setup.poa?.meta)), 1)
              ], 64))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createTextVNode(" N/A ")
              ], 64))
        ])
      ])
    ]),
    _cache[25] || (_cache[25] = _createStaticVNode("<section><h3>SECTION 4: DECLARATION AND SIGNATURES</h3><h4>PRINCIPAL’S SIGNATURE</h4><p>By signing this document, I confirm that:</p><ul><li>I am making this enduring power of attorney freely and voluntary.</li></ul><p>AND</p><ul><li> I understand the nature and effect of this enduring power of attorney, including: <ul><li> that I may specify or limit my attorney(s)’ power and instruct my attorney(s) about the exercise of the power </li><li>when the power given to my attorney(s) begins</li><li> that once the power for a matter begins, my attorney(s) will have full control and power to make decisions about the matter, subject to any terms or information included in this enduring power of attorney </li><li> that I may revoke this enduring power of attorney at any time if I am capable of making another enduring power of attorney giving the same power </li><li> that the power I am giving to my attorney(s) continues even if I do not have capacity to make decisions about the matter </li><li> that if I am not capable of revoking the enduring power of attorney, I am unable to effectively oversee the use of the power given to my attorney(s) by this document. </li></ul></li></ul></section>", 1)),
    _createElementVNode("section", null, [
      _cache[16] || (_cache[16] = _createElementVNode("h3", null, "ONLY SIGN THIS PART IN FRONT OF AN ELIGIBLE WITNESS", -1)),
      _cache[17] || (_cache[17] = _createElementVNode("h4", null, "Principal’s Signature:", -1)),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, _toDisplayString($setup.ownerPerson?.fullName), 1),
          _cache[13] || (_cache[13] = _createElementVNode("div", { class: "sign" }, "Name", -1))
        ]),
        _cache[14] || (_cache[14] = _createElementVNode("div", { class: "sign" }, "Signature", -1)),
        _cache[15] || (_cache[15] = _createElementVNode("div", { class: "sign" }, "Date", -1))
      ]),
      _cache[18] || (_cache[18] = _createElementVNode("h4", null, "Witness Signature:", -1)),
      _cache[19] || (_cache[19] = _createElementVNode("div", { class: "sign-container" }, [
        _createElementVNode("div", { class: "sign" }, "Signature"),
        _createElementVNode("div", { class: "sign" }, "Date")
      ], -1))
    ]),
    _cache[26] || (_cache[26] = _createStaticVNode("<section><h3>WITNESS CERTIFICATE</h3><p><strong>By signing this document, I certify that</strong> the principal signed this enduring power of attorney in my presence and I am a (tick one box only): </p><ul class=\"checkboxes\"><li>justice of the peace (JP)</li><li>commissioner for declarations</li><li>lawyer</li><li>notary public</li></ul><p>I am not</p><ul><li>the person signing the document for the principal</li><li>an attorney of the principal</li><li>a relation of the principal or relation of an attorney of the principal</li><li> if this enduring power of attorney appoints an attorney for personal matters (including health matters), a paid carer or health provider of the principal. </li></ul><p> At the time of making this enduring power of attorney the principal appears to me to have the capacity to make the enduring power of attorney. The principal appears to: </p><ul><li>understand the nature and effect of this enduring power of attorney</li><li>be capable of making the enduring power of attorney freely and voluntarily.</li></ul><p> This document has <sub class=\"underline\"></sub> pages. </p><div class=\"sign-container\"><div class=\"sign\">Witness signature</div><div class=\"sign\">Witness name</div><div class=\"sign\">Date</div></div></section>", 1)),
    _createElementVNode("section", null, [
      _createElementVNode("h3", null, " SECTION 5: " + _toDisplayString($options.plural('ATTORNEY', $setup.allAttorneys).toUpperCase()) + _toDisplayString($options.ifPlural("'", "'S", $setup.allAttorneys)) + " ACCEPTANCE ", 1),
      _cache[23] || (_cache[23] = _createStaticVNode("<p> In signing this enduring power of attorney I accept the appointment in accordance with the terms of this enduring power of attorney and confirm that: </p><ul><li> I have <strong>read</strong> this enduring power of attorney and I understand that I <strong>must</strong> make decisions and exercise power in accordance with this enduring power of attorney, the <em>Powers of Attorney Act 1998</em> and the <em>Guardianship and Administration Act 2000</em>. </li><li> I understand: <ul><li> in exercising my powers I must apply the general principles and if I exercise powers for health care matters, the health care principles under the <em>Powers of Attorney Act 1998</em> and the <em>Guardianship and Administration Act 2000</em></li><li> the obligations of an attorney under an enduring power of attorney and the consequences of failing to comply with those obligations. </li></ul></li><li> I declare that: <ul><li>I have capacity for the matter that I am appointed for</li><li>I am 18 years or older</li><li> I am not a paid carer for the principal and have not been a paid carer for the principal within the previous three (3) years </li><li>I am not a health provider for the principal</li><li> I am not a service provider for a residential service where the principal is a resident </li><li> if I am appointed for financial matters, I am not bankrupt or taking advantage of the laws of bankruptcy as a debtor under the Bankruptcy Act 1966 (Cwlth) or a similar law of a foreign jurisdiction. </li></ul></li></ul>", 2)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.allAttorneys, (attorney) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "sign-container",
          key: attorney.id
        }, [
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("div", _hoisted_14, _toDisplayString(attorney.directoryPerson.fullName), 1),
            _cache[20] || (_cache[20] = _createElementVNode("div", { class: "sign" }, "Name", -1))
          ]),
          _cache[21] || (_cache[21] = _createElementVNode("div", { class: "sign" }, "Signature", -1)),
          _cache[22] || (_cache[22] = _createElementVNode("div", { class: "sign" }, "Date", -1))
        ]))
      }), 128))
    ])
  ]))
}