<template>
  <article>
    <div class="text-center">
      <p><em>Guardianship and Administration Act 1995 (TAS)</em> – Form 1 (Section 32)</p>
      <h2>
        INSTRUMENT APPOINTING ENDURING
        {{ plural('GUARDIAN', primaryAttorneys).toUpperCase() }}
      </h2>
    </div>
    <section>
      <ol>
        <li>
          <p>
            I, <strong>{{ ownerPerson?.fullName }}</strong> {{ ownerPerson?.fullAddress }}, born on
            {{ ownerPerson?.dateOfBirthFormatted }}, <strong>appoint</strong> the following
            {{ ifPlural('people', 'person', primaryAttorneys) }} to be my
            {{ ifPlural('joint', '', primaryAttorneys) }} Enduring
            {{ plural('Guardian', primaryAttorneys) }}:
          </p>
          <ol type="a">
            <li :key="attorney.id" v-for="attorney of primaryAttorneys">
              <strong>{{ attorney.directoryPerson.fullName }}</strong>
              {{ attorney.directoryPerson.identifiers }}
            </li>
          </ol>
        </li>
        <li v-if="hasSecondaries">
          In the event that
          {{ ifPlural('one of my joint guardians', 'my guardian', primaryAttorneys) }}
          becomes incapable or unavailable to exercise this appointment, I appoint
          <strong>{{ secondaryAttorneys[0].directoryPerson.fullName }}</strong>
          {{ secondaryAttorneys[0].directoryPerson.identifiers }} as an alternative to that
          guardian.
        </li>
        <li v-if="othersMayAct">
          I do not want the death, resignation or incapacity of any of my joint guardians to
          terminate the appointment of each of my other joint guardians.
        </li>
      </ol>
      <p>
        By executing this instrument appointing an enduring guardian, I hereby revoke any and all
        former instruments made by me which appoint any enduring guardians or alternative guardians
        and such instruments shall cease to have effect upon the registration of this instrument
        made by me pursuant to Part 5 of the
        <em>Guardianship and Administration Act 1995</em>. Subject to any conditions specified
        below, I authorise my guardian, in the event that I become unable by reason of a disability
        to make reasonable judgments in respect of matters relating to my personal circumstances to
        exercise the powers of a guardian under Section 25 of the
        <em>Guardianship and Administration Act 1995</em>.
      </p>
    </section>
    <section>
      <h3>CONDITIONS UPON APPOINTMENT</h3>
      <ol :start="2 + hasSecondaries + othersMayAct">
        <li>
          <p>
            I require my guardian to observe the following conditions in exercising, or, in relation
            to the exercise of, the powers conferred by this instrument:
          </p>
          <ul v-if="hasLimitations || hasPreferences">
            <li v-if="hasLimitations">
              <strong>Limitations:</strong> {{ mf('limitations', poa?.meta) }}
            </li>
            <li v-if="hasPreferences">
              <strong>Preferences:</strong> {{ mf('preferences', poa?.meta) }}
            </li>
          </ul>
          <ul v-else>
            <li>N/A</li>
          </ul>
        </li>
      </ol>
    </section>
    <section>
      <h3>SIGNATURES</h3>
      <h4>APPOINTOR</h4>
      <p>
        This is an appointment of an enduring guardian made under Part 5 of the
        <em>Guardianship Administration Act 1995</em>. I acknowledge that this is a public document
        and is available for public scrutiny.
      </p>
      <div class="sign-container">
        <div class="sign-prefixed">
          <div class="sign-prefix">
            {{ ownerPerson?.fullName }}
          </div>
          <div class="sign">Name</div>
        </div>
        <div class="sign">Signature</div>
        <div class="sign">Date</div>
      </div>
    </section>
    <section>
      <h3>WITNESSES</h3>
      <p>
        As witnesses we certify that <strong>{{ ownerPerson?.fullName }}</strong> has signed this
        instrument freely and voluntarily in our presence and
        <strong>{{ ownerPerson?.fullName }}</strong> appears to understand the effect of this
        instrument.
      </p>
      <h4>Signature of witness 1</h4>
      <div class="sign-container">
        <div class="sign">Signature</div>
        <div class="sign">Name</div>
        <div class="sign">Address</div>
      </div>
      <h4>Signature of witness 2</h4>
      <div class="sign-container">
        <div class="sign">Signature</div>
        <div class="sign">Name</div>
        <div class="sign">Address</div>
      </div>
    </section>
    <section>
      <h3>ACCEPTANCE OF APPOINTMENT</h3>
      <div :key="attorney.id" v-for="attorney of allAttorneys">
        <p>
          I, <strong>{{ attorney.directoryPerson.fullName }}</strong
          >, accept appointment as a guardian under this instrument and undertake to exercise the
          powers conferred honestly and in accordance with the provisions of the
          <em>Guardianship and Administration Act 1995</em>.
        </p>
        <div class="sign-container">
          <div class="sign">Signature</div>
          <div class="sign">Date</div>
        </div>
      </div>
    </section>
  </article>
</template>

<script lang="ts">
import poa from '@/hooks/poa';
import { mf, plural, ifPlural } from '@/utils/format';
import { medicalPoaKey, ownerPersonKey } from '@/types/commonTypes';
import { computed, inject } from 'vue';

export default {
  setup() {
    const ownerPerson = inject(ownerPersonKey);
    const medicalPoa = inject(medicalPoaKey);

    const {
      actJointly,
      attorneys,
      hasInclusions,
      hasLimitations,
      hasPreferences,
      hasMultipleAttorneys,
      inclusions,
      hasSecondaries,
      othersMayAct,
      primaryAttorneys,
      secondaryAttorneys,
    } = poa(medicalPoa?.value);

    const allAttorneys = computed(() => {
      return [
        ...(primaryAttorneys?.value ?? []),
        ...(hasSecondaries.value ? secondaryAttorneys?.value ?? [] : []),
      ];
    });

    return {
      actJointly,
      attorneys,
      hasInclusions,
      hasLimitations,
      hasPreferences,
      hasMultipleAttorneys,
      inclusions,
      hasSecondaries,
      othersMayAct,
      primaryAttorneys,
      secondaryAttorneys,
      allAttorneys,
      poa: medicalPoa,
      ownerPerson,
    };
  },
  methods: {
    mf,
    plural,
    ifPlural,
  },
};
</script>
