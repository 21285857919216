<template>
  <div class="container">
    <article>
      <h1>Instructions for signing your Power of Attorney - Financial (VIC)</h1>
      <p>
        Thank you for choosing Safewill. Your Enduring Power of Attorney is almost complete - follow
        the steps below, provided by our legal advisers, to sign your Power of Attorney and have it
        witnessed.
      </p>
      <h4><em>Step 1 – Print your Enduring Power of Attorney</em></h4>
      <p>
        Print your Enduring Power of Attorney, read it carefully and ensure that you understand it
        completely. If you do not understand what you are signing, we recommend seeking independent
        legal advice.
      </p>
      <h4><em>Step 2 – Find two people to act as witnesses</em></h4>
      <p>
        Once you have understood your Enduring Power of Attorney, you will need to find two
        witnesses to sign the document and to witness your signing of it (all witnesses must be
        present at the same time). The Enduring Power of Attorney can only be witnessed by someone
        who:
      </p>
      <ul>
        <li><strong>is</strong> 18 years of age or older; and</li>
        <li>
          <strong>is not</strong>:
          <ul>
            <li>your relative;</li>
            <li>someone being appointed as an attorney (or a relative of your attorney); or</li>
            <li>your care worker or accommodation provider.</li>
          </ul>
        </li>
      </ul>
      <p>At least one of your witnesses must be:</p>
      <ul>
        <li>a medical practitioner; or</li>
        <li>
          a person authorised to witness an affidavit, for example a Justice of the Peace or an
          Australian solicitor (for a full list see
          <a href="https://www.justice.vic.gov.au/affidavit"
            >https://www.justice.vic.gov.au/affidavit</a
          >).
        </li>
      </ul>
      <p>
        Your witnesses are required to confirm that you appeared to understand the effect of the
        Enduring Power of Attorney and voluntarily signed (as set out in the witness certificates
        below).
      </p>
      <h4><em>Step 3 – Signing your Enduring Power of Attorney</em></h4>
      <p>
        Sign using a blue or black pen. You should first sign the Enduring Power of Attorney in the
        presence of your witness before providing the document to your attorney(s) to sign. Make
        sure your witness is watching you sign and date the document. After this your witness must
        also sign where indicated.
      </p>
      <p>
        You will then need to arrange for your attorney(s) to sign the document to accept their
        appointment.
      </p>
      <h4><em>Step 4 – Keep your Enduring Power of Attorney safe</em></h4>
      <p>
        It is important to store your Enduring Power of Attorney in a safe place and provide your
        attorney with a copy. You should also keep a copy with someone you trust like your solicitor
        or accountant.
      </p>
      <h4><em>Note on Revocation</em></h4>
      <p>
        In Victoria, when you sign a new Enduring Power of Attorney, any previously made Enduring
        Powers of Attorneys will be revoked.
      </p>
    </article>
    <AppointmentOfAttorneys />
  </div>
</template>

<script lang="ts">
import AppointmentOfAttorneys from './components/financial/AppointmentOfAttorneys.vue';

export default {
  components: {
    AppointmentOfAttorneys,
  },
};
</script>
