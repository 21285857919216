<template>
  <div class="container">
    <article>
      <h1>Instructions for signing your Enduring Power of Attorney - Healthcare (ACT)</h1>
      <p>
        Thank you for choosing Safewill. Your Enduring Power of Attorney is almost complete - follow
        the steps below, provided by our legal advisers, to sign your Power of Attorney and have it
        witnessed.
      </p>
      <h4><em>Step 1 – Print your Enduring Power of Attorney</em></h4>
      <p>
        Print your Enduring Power of Attorney, read it carefully and ensure that you understand it
        completely. If you do not understand what you are signing, we recommend seeking independent
        legal advice.
      </p>
      <h4><em>Step 2 – Find two people to act as witnesses</em></h4>
      <p>
        Once you have understood your Enduring Power of Attorney, you will need to find two
        witnesses to sign the document and witness your signing of it. Both of your witnesses must
        be someone who is at least 18 years old who has not been appointed as an attorney. Only one
        witness may be your relative or a relative of one of your attorneys.
      </p>
      <p>
        At least one of your witnesses must also be authorised to witness a statutory declaration,
        which includes:
      </p>
      <ul>
        <li>an Australian legal practitioner</li>
        <li>Justice of the Peace</li>
        <li>Police officers</li>
        <li>
          Licensed or registered dentists, pharmacists, nurses, vets, and medical practitioners
        </li>
      </ul>
      <p>
        A full list of authorised witnesses is available here:
        <a
          href="https://www.ag.gov.au/legal-system/statutory-declarations/who-can-witness-your-statutory-declaration#list"
          >www.ag.gov.au/legal-system/statutory-declarations/who-can-witness-your-statutory-declaration#list</a
        >
      </p>
      <h4><em>Step 3 – Signing your Enduring Power of Attorney</em></h4>
      <p>
        All boxes that appear under ‘Functions’ and ‘Direction, Limitations and Conditions’ will
        need to be initialled.
      </p>
      <p>
        Sign using a blue or black pen. You should first sign the Enduring Power of Attorney in the
        presence of your witnesses. Your witnesses are then required to sign the witness
        certificates included in the document, confirming that you signed the Enduring Power of
        Attorney voluntarily and appeared to understand its nature and effect. Signing the bottom of
        each page of the document is not mandatory, however we recommend doing so to protect against
        pages being substituted. You will then need to arrange for your attorney(s) to sign.
      </p>
      <h4><em>Step 4 – Keep your Enduring Power of Attorney safe</em></h4>
      <p>
        It is important to store your Enduring Power of Attorney in a safe place and provide your
        attorney with a copy. You should also keep a copy with someone you trust like your solicitor
        or accountant.
      </p>
      <h4><em>Note on Revocation</em></h4>
      <p>
        By signing Schedule 1 of this Enduring Power of Attorney, you will revoke any previously
        made Enduring Powers of Attorney to the extent they deal with personal and health care
        matters.
      </p>
      <p>
        If you do not want to revoke any earlier Enduring Power of Attorney document, you do not
        need to sign Schedule 1. However, please note that in the ACT, signing a later Enduring
        Power of Attorney will automatically revoke an earlier Power of Attorney, to the extent of
        an inconsistency between the documents. If you have an existing Power of Attorney and are
        unclear about the effect of signing a new Power of Attorney, we recommend seeking legal
        advice.
      </p>
    </article>
    <AppointmentOfAttorneys />
    <Revocation />
    <article>
      <div class="text-center">
        <h4>SCHEDULE 2 TO THE ENDURING POWER OF ATTORNEY</h4>
        <h2>OBLIGATIONS OF THE ATTORNEY UNDER AN ENDURING POWER OF ATTORNEY</h2>
      </div>
      <section>
        <h3>PART 1</h3>
        <h4>Your obligations as attorney include the following:</h4>
        <ol>
          <li>
            Unless expressly authorised by this enduring power of attorney, you must avoid
            transactions which result, or may result, in conflict between your duty to the
            principal; and either the interests of you, or your relative, business associate or
            close friend of the attorney; or another duty you may have.
          </li>
          <li>
            You must notify other attorneys when you resign as attorney or your authority is
            revoked. You must notify other attorneys and anyone who has had dealings with you as the
            principal’s attorney, about any court or guardianship tribunal matter that has effect on
            your authorisation.
          </li>
          <li>
            If the principal’s decision-making capacity is impaired:
            <ol type="a">
              <li>you must, to the maximum extent possible, comply with the general</li>
              <li>
                principles set out in Schedule 1 to the
                <em>Powers of Attorney Act 2006</em> (an extract of which is part of this schedule);
              </li>
              <li>
                if you are an attorney for property matters, you must:
                <ol type="i">
                  <li>
                    keep accurate records and accounts of all dealings and transactions made under
                    this power of attorney, and
                  </li>
                  <li>
                    keep the principal’s property separate from yours, unless the property is owned
                    jointly by you and the principal;
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            if you are an attorney for medical research matters, you must make decisions about
            medical research matters in accordance with part 4.3A of the
            <em>Powers of Attorney Act 2006</em>; and
          </li>
          <li>
            you must not ask for medical treatment
            <em>to be withheld or withdrawn from the principal, unless you have been expressly</em>
            authorised to consent to the withholding or withdrawal of treatment under this enduring
            power of attorney, and you have consulted a doctor about the nature of the principal’s
            illness, any alternative forms of treatment available, and the consequences of the
            principal remaining untreated. Your decision should be on the basis of what the
            principal would ask for if the principal could make a rational judgment, and were to
            give serious consideration to the principal’s own health and wellbeing.
          </li>
        </ol>
        <p>
          <em
            >(In the event that the principal’s decision-making capacity becomes impaired, you have
            the right to all the information that the principal would have been entitled to if the
            principal had decision-making capacity. If you are in doubt about your responsibilities
            as attorney you may seek advice or assistance from the Public Trustee and Guardian.)</em
          >
        </p>
      </section>
      <section>
        <h3>PART 2</h3>
        <p>
          <strong>General principles for enduring powers of attorney</strong><br />
          (Schedule 1 to the Powers of Attorney Act 2006 (section 44))
        </p>
        <ol>
          <li>
            <h4>Access to family members and relatives</h4>
            <ol type="a">
              <li>
                An individual’s wish and need to have access to family members and relatives, and
                for them to have access to the individual, must be recognised and taken into
                account.
              </li>
              <li>
                An individual’s wish to involve family members and relatives in decisions affecting
                the individual’s life, property, health and finance must be recognised and taken
                into account.
              </li>
            </ol>
          </li>
          <li>
            <h4>Human worth and dignity</h4>
            <p>
              An individual with impaired decision-making capacity has an inherent right to respect
              for the individual’s human worth and dignity as an individual.
            </p>
          </li>
          <li>
            <h4>Role as a member of society</h4>
            <ol type="a">
              <li>An individual has a right to be a valued member of society.</li>
              <li>
                Because of this right, it is important to encourage and support the individual to
                perform social roles valued in society.
              </li>
            </ol>
          </li>
          <li>
            <h4>Participation in community life</h4>
            <p>
              It is important to encourage and support an individual to live a life in the general
              community, and to take part in activities enjoyed by the community.
            </p>
          </li>
          <li>
            <h4>Quality of life</h4>
            <p>
              An individual’s need and wish to have a reasonable quality of life must be recognised
              and taken into account.
            </p>
          </li>
          <li>
            <h4>Participation in decision-making</h4>
            <ol type="a">
              <li>
                An individual has a right to take part in decisions affecting the individual’s life
                to the greatest extent practicable.
              </li>
              <li>
                Without limiting subsection (1), an individual also has a right to take part in
                decisions affecting the individual’s property and finance to the greatest extent
                practicable.
              </li>
              <li>
                <p>
                  The right of the individual to make the individual’s own decisions must be
                  preserved to the greatest extent practicable.
                </p>
                <p>
                  <strong
                    ><em
                      >Examples of preserving individual’s right to make own decisions</em
                    ></strong
                  >
                </p>
                <ol type="i">
                  <li>
                    <em
                      >The individual must be given any necessary support, and access to any
                      necessary information, to allow the individual to take part in decisions
                      affecting the individual’s life to the greatest extent practicable.</em
                    >
                  </li>
                  <li>
                    <em
                      >To the greatest extent practicable, the individual’s views and wishes must be
                      sought and taken into account before exercising power in relation to the
                      individual.</em
                    >
                  </li>
                  <li>
                    <em
                      >Power in relation to the individual must be exercised in the way that is
                      least restrictive of the individual’s rights.</em
                    >
                  </li>
                </ol>
                <p>
                  <em
                    >Note: An example is part of the Act, is not exhaustive and may extend, but does
                    not limit, the meaning of the provision in which it appears (see Legislation
                    Act, s 126 and s 132).</em
                  >
                </p>
              </li>
              <li>
                If an individual’s wishes or needs cannot be expressed by the individual, the person
                exercising power in relation to the individual must try to work out, as far as
                possible, from the individual’s past actions, what the individual’s wishes and needs
                would be if the individual could express them and take those wishes and needs into
                account.
              </li>
              <li>
                However, a person exercising a function in relation to an individual must do so in a
                way consistent with the individual’s proper care and protection.
              </li>
              <li>
                An individual’s views and wishes may be expressed orally, in writing or in another
                way, including, for example, by conduct.
              </li>
            </ol>
          </li>
          <li>
            <h4>Individual taken to be able to make decisions</h4>
            <p>
              An individual must not be treated as unable to take part in making a decision only
              because the individual makes unwise decisions.
            </p>
          </li>
          <li>
            <h4>Maintenance of existing supportive relationships</h4>
            <p>
              The importance of maintaining an individual’s existing supportive relationships must
              be taken into account.
            </p>
          </li>
          <li>
            <h4>Maintenance of environment and values</h4>
            <ol type="a">
              <li>
                The importance of maintaining an individual’s cultural and linguistic environment,
                and set of values (including any religious beliefs) must be taken into account.
              </li>
              <li>
                For an individual who is a member of an Aboriginal community or a Torres Strait
                Islander, this means the importance of maintaining the individual’s Aboriginal or
                Torres Strait Islander cultural and linguistic environment, and set of values
                (including Aboriginal tradition or Island custom) must be taken into account.
              </li>
              <li>
                <p>In this section:</p>
                <h4>Aboriginal tradition—</h4>
                <ol type="i">
                  <li>
                    means the body of traditions, observances, customs and beliefs of Aboriginal
                    people generally, or of a particular community or group of Aboriginal people;
                    and
                  </li>
                  <li>
                    (b) includes any traditions, observances, customs and beliefs mentioned in
                    paragraph (a) that relate to particular people, areas, objects or relationships.
                  </li>
                </ol>
                <h4>Island custom, known in the Torres Strait as Ailan Kastom—</h4>
                <ol type="i">
                  <li>
                    means the body of customs, traditions, observances and beliefs of Torres Strait
                    Islanders generally, or of a particular community or group of Torres Strait
                    Islanders; and
                  </li>
                  <li>
                    (b) includes any traditions, observances, customs and beliefs mentioned in
                    paragraph (a) that relate to particular people, areas, objects or relationships.
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <h4>Confidentiality</h4>
            <p>
              An individual’s right to confidentiality of information about the individual must be
              respected.
            </p>
          </li>
          <li>
            <h4>Health care and medical research</h4>
            <p>
              An individual is entitled to have decisions about a health care matter or a medical
              research matter made by an attorney—
            </p>
            <ol type="a">
              <li>
                in the way least restrictive of the individual’s rights and freedom of action; and
              </li>
              <li>
                only if the exercise of power—
                <ol type="i">
                  <li>
                    is, in the attorney’s opinion, necessary and appropriate to maintain or promote
                    the individual’s health and wellbeing; or
                  </li>
                  <li>is, in all the circumstances, in the individual’s best interests.</li>
                </ol>
              </li>
            </ol>
          </li>
        </ol>
        <p>
          An individual’s wishes in relation to a health care matter or a medical research matter,
          and any information provided by the individual’s health care provider, must be taken into
          account when an attorney decides what is appropriate in the exercise of power for a health
          care matter or a medical research matter.
        </p>
      </section>
    </article>
    <article>
      <div class="text-center">
        <h4>SCHEDULE 3 TO THE ENDURING POWER OF ATTORNEY</h4>
        <h2>UNDERSTANDING NATURE AND EFFECT OF MAKING POWERS OF ATTORNEY</h2>
        <p><em>(Powers of Attorney Act 2006, section 17)</em></p>
      </div>
      <p>
        Understanding the nature and effect of making a power of attorney includes understanding:
      </p>
      <ol>
        <li>
          that the principal may, in the power of attorney, state or limit the power to be given to
          an attorney;
        </li>
        <li>
          that the principal may, in the power of attorney, instruct the attorney about the exercise
          of the power;
        </li>
        <li>when the power under the power of attorney can be exercised;</li>
        <li>
          that, if the power under a power of attorney can be exercised for a matter, the attorney
          has the power to make decisions in relation to, and will have full control over, the
          matter subject to terms or information about exercising the power that are included in the
          power of attorney;
        </li>
        <li>
          that the principal may revoke the power of attorney at any time the principal is capable
          of making the power of attorney;
        </li>
        <li>
          <p>for enduring powers of attorney only—</p>
          <ol type="a">
            <li>
              that the power given by the principal continues even if the principal becomes a person
              with impaired decision-making capacity; and
            </li>
            <li>
              that, at any time the principal is not capable of revoking the power of attorney, the
              principal cannot effectively oversee the use of the power.
            </li>
          </ol>
        </li>
      </ol>
      <p>
        <em
          >(A person has decision-making capacity if the person can make decisions in relation to
          the person’s affairs and understands the nature and effect of the decisions (see Powers of
          Attorney Act 2006, s 9 (1).)</em
        >
      </p>
    </article>
    <article>
      <h4>Revoking previous Enduring Power of Attorney where Registered</h4>
      <p>
        In ACT, if you have registered the Enduring Power of Attorney you are revoking, you must
        lodge, at Access Canberra:
      </p>
      <ol>
        <li>
          the signed Revocation Form (Schedule 1 - Revocation of An Enduring Power of Attorney) and
        </li>
        <li>
          a completed and signed Application to Register a Revocation of a Power of Attorney which
          you can find here:<br />
          <a href="https://www.legislation.act.gov.au/View/af/2018-37/current/PDF/2018-37.PDF"
            >https://www.legislation.act.gov.au/View/af/2018-37/current/PDF/2018-37.PDF</a
          >
        </li>
      </ol>
      <p>
        To find the location of Access Canberra for lodging, please visit the Access Canberra
        website <a href="http://accesscanberra.act.gov.au">http://accesscanberra.act.gov.au</a>.
        Please note there may be fees associated with lodgement.
      </p>
      <p>
        You should keep a copy of the completed and signed Revocation for your own record keeping
        purposes.
      </p>
    </article>
  </div>
</template>

<script lang="ts">
import AppointmentOfAttorneys from './components/medical/AppointmentOfAttorneys.vue';
import Revocation from './components/medical/Revocation.vue';

export default {
  components: {
    AppointmentOfAttorneys,
    Revocation,
  },
};
</script>
