<template>
  <article>
    <section>
      <h2 class="text-center">ENDURING POWER OF ATTORNEY</h2>
      <h3>DONOR</h3>
      <ol>
        <li>
          This Enduring Power of Attorney is made on the
          <span class="underline"></span> of <span class="wide-underline"></span> 20
          <span class="underline"></span>, by <strong>{{ ownerPerson?.fullName }}</strong
          >, born {{ ownerPerson?.dateOfBirthFormatted }}, {{ ownerPerson?.fullAddress }} under
          section 104 of the <em>Guardianship and Administration Act 1990</em>.
        </li>
      </ol>
    </section>
    <section>
      <h3>APPOINTMENT OF ATTORNEY</h3>
      <ol start="2">
        <li>
          <p>I APPOINT</p>
          <ol type="a">
            <li :key="attorney.id" v-for="attorney of primaryAttorneys">
              <strong>{{ attorney.directoryPerson.fullName }}</strong>
              {{ attorney.directoryPerson.identifiers }}
            </li>
          </ol>
          <p>
            <template v-if="hasMultipleAttorneys">
              {{ mf('attorney_decisions', poa?.meta) }}
            </template>
            to be my {{ ifPlural('', 'sole', primaryAttorneys) }}
            {{ plural('attorney', primaryAttorneys) }}.
          </p>
        </li>
        <li v-if="hasSecondaries">
          <p>
            I APPOINT <strong>{{ secondaryAttorneys[0].directoryPerson.fullName }}</strong>
            {{ secondaryAttorneys[0].directoryPerson.identifiers }}
            <template v-if="hasMultipleAttorneys">
              {{ mf('attorney_decisions', poa?.meta) }}
            </template>
            to be my attorney in substitution of:
            <template :key="attorney.id" v-for="(attorney, index) of primaryAttorneys">
              <strong>{{ attorney.directoryPerson.fullName }}</strong>
              {{ index === primaryAttorneys?.length - 1 ? '' : ' or ' }}
            </template>
            on the occurrence of the following events or circumstances:
          </p>
          <ol type="a">
            <li>
              {{ ifPlural('either of my attorneys are', 'my attorney is', primaryAttorneys) }}
              unable to act due to death or lack of capacity, or
            </li>
            <li>
              {{ ifPlural('either attorney', 'my attorney', primaryAttorneys) }}
              is temporarily unable to act due to illness or personal commitments.
            </li>
          </ol>
        </li>
      </ol>
    </section>
    <section>
      <h3>AUTHORISATION</h3>
      <ol :start="3 + hasSecondaries">
        <li>
          <p>
            I AUTHORISE my {{ plural('attorney', primaryAttorneys) }} to do on my behalf anything
            that I can lawfully do by an attorney.
          </p>
          <p>
            <em
              >Note: An attorney cannot be authorised to make personal, lifestyle or medical
              treatment decisions.</em
            >
          </p>
        </li>
      </ol>
    </section>
    <section>
      <h3>CONDITIONS, LIMITATIONS AND RESTRICTIONS</h3>
      <ol :start="4 + hasSecondaries">
        <li>
          <p>
            The authority of my attorney(s) is subject to the following CONDITIONS or RESTRICTIONS:
          </p>
          <ul>
            <li v-if="hasLimitations">{{ mf('limitations', poa?.meta) }}</li>
            <li v-else>NIL</li>
          </ul>
        </li>
        <li>
          <p>
            My attorney may provide for the reasonable living and medical expenses for the following
            people:
          </p>
          <ol type="a" v-if="hasInclusions">
            <li :key="inclusion.id" v-for="inclusion of inclusions">
              <strong>{{ inclusion.directoryPerson.fullName }}</strong>
              {{ inclusion.directoryPerson.identifiers }}
            </li>
          </ol>
          <ul v-else>
            <li>NIL</li>
          </ul>
        </li>
      </ol>
    </section>
    <section>
      <h3>COMMENCEMENT</h3>
      <p>
        I DECLARE that this power of attorney will be in force only during any period when a
        declaration by the State Administrative Tribunal that I do not have legal capacity is in
        force under section 106 of the
        <em>Guardianship and Administration Act 1990</em>.
      </p>
    </section>
    <section>
      <h3>EXECUTION</h3>
      <ol :start="6 + hasSecondaries">
        <li>
          <h4>SIGNED AS A DEED by:</h4>
          <div class="sign-container">
            <div class="sign-prefixed">
              <div class="sign-prefix">
                {{ ownerPerson?.fullName }}
              </div>
              <div class="sign">Name</div>
            </div>
            <div class="sign">Signature</div>
            <div class="sign">Date</div>
          </div>
        </li>
        <li>
          <h4>WITNESSED by:</h4>
          <div class="sign-container">
            <div class="sign">Signature of Witness 1</div>
            <div class="sign">Name of Witness 1</div>
            <div class="sign">Address of Witness 1</div>
            <div class="sign">Occupation of Witness 1</div>
          </div>
          <div class="sign-container">
            <div class="sign">Signature of Witness 2</div>
            <div class="sign">Name of Witness 2</div>
            <div class="sign">Address of Witness 2</div>
            <div class="sign">Occupation of Witness 2</div>
          </div>
        </li>
        <li>
          <h4>ACCEPTANCE OF ENDURING POWER OF ATTORNEY</h4>
          <p>
            The person(s) or agency nominated as attorney(s) is/are required to indicate their
            willingness to accept the power vested in them under this enduring power of attorney and
            the legal obligations which go with that power. The attorney(s) must sign the Statement
            of Acceptance in the space provided as soon as possible after the document conferring
            the authority has been completed.
          </p>
          <p>
            <strong> {{ ifPlural('We', 'I', primaryAttorneys) }} </strong>,
            <template :key="attorney.id" v-for="attorney of primaryAttorneys">
              <strong>{{ attorney.directoryPerson.fullName }}</strong
              >,
            </template>
            the {{ plural('person', primaryAttorneys) }} appointed to be the
            <strong>{{ plural('attorney', primaryAttorneys) }}</strong> under paragraph 2 of the
            instrument on which this acceptance is endorsed [or to which this acceptance is
            annexed].
          </p>
          <template v-if="hasSecondaries">
            <h4>AND</h4>
            <p>
              <strong>I, {{ secondaryAttorneys[0].directoryPerson.fullName }}</strong
              >, the person appointed to be the <strong>substitute attorney</strong> under paragraph
              3 of the instrument on which this acceptance is endorsed [or to which this acceptance
              is annexed].
            </p>
          </template>
          <p>Accept the appointment and acknowledge:</p>
          <ul>
            <li>that the power of attorney is an enduring power of attorney</li>
            <li>
              that I/we will, by accepting this power of attorney, be subject to the provisions of
              Part 9 of the
              <em>Guardianship and Administration Act 1990</em>;
            </li>
            <li>
              the enduring power of attorney will be in force only during any period when a
              declaration by the State Administrative Tribunal that the Donor does not have legal
              capacity is in force under Section 106 of the
              <em>Guardianship and Administration Act 1990</em>.
            </li>
          </ul>
          <h4>SIGNED</h4>
          <div class="sign-container" :key="attorney.id" v-for="attorney of primaryAttorneys">
            <div class="sign-prefixed">
              <div class="sign-prefix">
                {{ attorney.directoryPerson.fullName }}
              </div>
              <div class="sign">Attorney appointed under paragraph 2</div>
            </div>
            <div class="sign">Signature</div>
            <div class="sign">Date</div>
          </div>
          <template v-if="hasSecondaries">
            <div class="sign-container">
              <div class="sign-prefixed">
                <div class="sign-prefix">
                  {{ secondaryAttorneys[0].directoryPerson.fullName }}
                </div>
                <div class="sign">Substitute attorney appointed under paragraph 3</div>
              </div>
              <div class="sign">Signature</div>
              <div class="sign">Date</div>
            </div>
          </template>
        </li>
      </ol>
    </section>
  </article>
</template>

<script lang="ts">
import poa from '@/hooks/poa';
import { mf, plural, ifPlural } from '@/utils/format';
import { financialPoaKey, ownerPersonKey } from '@/types/commonTypes';
import { inject } from 'vue';

export default {
  setup() {
    const ownerPerson = inject(ownerPersonKey);
    const financialPoa = inject(financialPoaKey);

    const {
      actJointly,
      attorneys,
      hasInclusions,
      hasLimitations,
      hasMultipleAttorneys,
      inclusions,
      hasSecondaries,
      othersMayAct,
      primaryAttorneys,
      secondaryAttorneys,
    } = poa(financialPoa?.value);

    return {
      actJointly,
      attorneys,
      hasInclusions,
      hasLimitations,
      hasMultipleAttorneys,
      inclusions,
      hasSecondaries,
      othersMayAct,
      primaryAttorneys,
      secondaryAttorneys,
      ownerPerson,
      poa: financialPoa,
    };
  },
  methods: {
    mf,
    plural,
    ifPlural,
  },
};
</script>
