<template>
  <div class="container">
    <article>
      <h1>Instructions for signing your Power of Attorney - Financial (TAS)</h1>
      <p>
        Thank you for choosing Safewill. Your Enduring Power of Attorney is almost complete - follow
        the steps below, provided by our legal advisers, to sign your Power of Attorney and have it
        witnessed.
      </p>
      <h4><em>Step 1 – Print your Enduring Power of Attorney</em></h4>
      <p>
        Print your Enduring Power of Attorney on A4 paper, read it carefully and ensure that you
        understand it completely. If you do not understand what you are signing, we recommend
        seeking independent legal advice.
      </p>
      <h4><em>Step 2 – Find two people to act as witnesses</em></h4>
      <p>
        Once you have understood your Enduring Power of Attorney, you will need to find two
        witnesses to sign the document and witness your signing of it. The Enduring Power of
        Attorney can only be witnessed by someone who:
      </p>
      <ul>
        <li><strong>is</strong> 18 years of age or older; and</li>
        <li>
          <strong>is not</strong>:
          <ul>
            <li>someone being appointed as an attorney; or</li>
            <li>a close relative of you or your attorney.</li>
          </ul>
        </li>
      </ul>
      <h4><em>Step 3 – Signing your Enduring Power of Attorney</em></h4>
      <p>
        Sign using a blue or black pen. You should sign the Enduring Power of Attorney first, in the
        presence of both your witnesses. Make sure your witnesses are watching you sign and date the
        document, before signing themselves. Signing the bottom of each page of the document is not
        mandatory, however we recommend doing so to protect against pages being substituted. You
        will then need to arrange for your attorney(s) to sign.
      </p>
      <h4><em>Step 4 – Register your Enduring Power of Attorney</em></h4>
      <p>
        Your Enduring Power of Attorney
        <strong>must be registered before it can be legally binding</strong>. You will need to lodge
        your Enduring Power of Attorney with the Land Titles Office and pay any relevant fees. We
        have included instructions to assist you at the end of this document. If you still have
        questions, we recommend contacting the Land Titles Office for further details.
      </p>
      <h4><em>Step 5 – Keep your Enduring Power of Attorney safe</em></h4>
      <p>
        It is important to store your Enduring Power of Attorney in a safe place and provide your
        attorney(s) with a copy. You should also keep a copy with someone you trust like your
        solicitor or accountant.
      </p>
      <h4><em>Note on Revocation</em></h4>
      <p>
        In Tasmania, signing a new Enduring Power of Attorney does not automatically revoke an old
        Enduring Power of Attorney. As such <strong>if you</strong>:
      </p>
      <ul>
        <li>
          have an existing Enduring Power of Attorney that you would like to revoke before making
          this Enduring Power of Attorney; <strong>or</strong>
        </li>
        <li>
          would like to change anything in this Enduring Power of Attorney document after it is
          signed;
        </li>
      </ul>
      <p>
        please follow the instructions on revocation included at the end of this Enduring Power of
        Attorney. Please also note that an Enduring Power of Attorney can only be revoked by the
        Donor while they have mental capacity.
      </p>
    </article>
    <AppointmentOfAttorneys />
    <article>
      <h2>Registering your Enduring Power of Attorney</h2>
      <p>
        Your Enduring Power of Attorney
        <strong>must be registered before it can be legally binding</strong>.
      </p>
      <h4><em>Step 1 - Complete Form 5</em></h4>
      <p>
        Enclosed at the end of this document is the Form 5 Registration Application. To complete
        this Form:
      </p>
      <ul>
        <li>The Type of Instrument is a “Power of Attorney”</li>
        <li>
          Insert the number of pages in the document (not including the signing instructions or any
          of the end instruction pages)
        </li>
        <li>Insert the name of the Donor (i.e. the person who made the Power of Attorney)</li>
        <li>Insert the Name(s) of Attorney(s) appointed in the Power of Attorney</li>
        <li>There is no Identification Number - leave as NA</li>
      </ul>
      <p>
        Sign and date the Form, write in your full name, your postal address and the capacity in
        which you are lodging the Power of Attorney (i.e. donor).
      </p>
      <h4><em>Step 2 - Complete Lodgement Form</em></h4>
      <p>Enclosed at the end of this document is the Lodgement Form. To complete this Form:</p>
      <ul>
        <li>Write in your full name and postal address in Panel A</li>
        <li>
          Leave Panel B blank, unless you wish for the original Power of Attorney to be posted to
          someone other than yourself
        </li>
        <li>
          In the table provided:
          <ul>
            <li>Write ‘Power of Attorney’ in the ‘Particulars’ column</li>
            <li>
              Enter the prescribed fee (as at November 2021 it is $149.32) in the ‘Fee’ column
            </li>
            <li>
              Write ‘Registration of Power of Attorney’ in the ‘Particulars of Remittance’ column
            </li>
            <li>Enter the prescribed fee again in the far-right ‘$’ column</li>
            <li>
              Assuming no other documents are being lodged at this time, enter the prescribed fee
              next to ‘Total Fees’ and ‘Total’
            </li>
          </ul>
        </li>
      </ul>
      <h4><em>Step 3 - Arrange for payment of the fee</em></h4>
      <p>
        The fee for registering your Power of Attorney in Tasmania is $149.32 (as at November 2021).
        You may pay this fee either by:
      </p>
      <ul>
        <li>Enclosing a bank cheque or money order with your documents for lodgement; or</li>
        <li>
          Enclosing a written request that the Land Titles Office contact you via telephone to pay
          via credit card. If you chose this option, include the name and telephone number of the
          cardholder. Visa and Mastercard are accepted.
        </li>
      </ul>
      <h4><em>Step 4 - Lodge the paperwork</em></h4>
      <p>To lodge the registration of your Power of Attorney, you may either:</p>
      <ul>
        <li>Hand-deliver the documents to 134 Macquarie Street, Hobart (Lands Building); or</li>
        <li>
          Post the documents to:<br />
          Land Titles Office, GPO Box 541, Hobart, TAS, 7001
        </li>
      </ul>
      <p>
        When lodging the registration of your Power of Attorney, ensure you have the following
        documents collated for lodgement:
      </p>
      <ul>
        <li>The original signed, dated and witnessed Power of Attorney document;</li>
        <li>A completed Lodgement Form;</li>
        <li>An original, signed and dated Form 5 Registration Application; and</li>
        <li>Payment of the prescribed fee (as set out above).</li>
      </ul>
    </article>
    <article>
      <h2>Revocation of Existing Enduring Power of Attorney</h2>
      <p>
        If you would like to revoke your existing Enduring Power of Attorney, please follow these
        steps.
      </p>
      <h4><em>Step 1 - Find a witness</em></h4>
      <p>
        You (Donor) will need to find one person over the age of 18 to witness and sign the
        Revocation of Enduring Power of Attorney and witness your signing of it.
      </p>
      <h4><em>Step 2 - Complete and sign the Revocation of Power of Attorney</em></h4>
      <p>
        The Revocation forms can be found here:<br />
        <a
          href="https://dpipwe.tas.gov.au/Documents/Deed%20of%20Revocation%20of%20Power%20of%20Attorney.pdf"
          >https://dpipwe.tas.gov.au/Documents/Deed%20of%20Revocation%20of%20Power%20of%20Attorney.pdf</a
        >
      </p>
      <p>
        You must sign the Deed of Revocation of Enduring Power of Attorney in the presence of your
        witness. Make sure your witness is watching you sign and date the document. After this your
        witness must also sign where indicated.
      </p>
      <p>
        If you have registered the Enduring Power of Attorney, you must then complete and sign Form
        5 – Registration Application and the Land Titles Office Lodgement Form.
      </p>
      <h4><em>Step 3 - Give written notice of the Revocation</em></h4>
      <p>
        After you have signed your Revocation of the Enduring Power of Attorney and it has been
        witnessed, you must, as soon as practicable, give notice of the Revocation. You can do this
        by writing a letter enclosing a copy of the completed and signed Revocation to:
      </p>
      <ul>
        <li>all of your attorney(s) whose appointment has been revoked; and</li>
        <li>anyone else who may be aware of or has dealings with the revoked Power of Attorney.</li>
      </ul>
      <p>
        If you fail to give notice, your attorney(s) may legally continue to make decisions on your
        behalf under the old power of attorney.
      </p>
      <h4><em>Step 4 - Registration</em></h4>
      <p>
        In Tasmania, if you have not registered the Enduring Power of Attorney that you have just
        revoked, you do not need to register the Revocation form.
      </p>
      <p>
        However, If you have registered the original Enduring Power of Attorney , you must lodge at
        the TAS Land Titles Office: the completed and signed Revocation forms
      </p>
      <p>
        To find the location of the TAS Land Titles Office for lodging, please visit the TAS Land
        Titles Office website:<br />
        <a href="https://dpipwe.tas.gov.au/land-tasmania/land-titles-office"
          >https://dpipwe.tas.gov.au/land-tasmania/land-titles-office</a
        >
      </p>
      <p>Please note there may be fees associated with the lodgement.</p>
      <p>
        You should keep a copy of the completed and signed Revocation for your own record keeping
        purposes.
      </p>
    </article>
    <article>
      <img
        src="/tas/tas-registration-application.png"
        alt="Tasmania registration application"
        style="display: block; margin: 0 auto; max-height: 100%; max-width: 100%"
      />
    </article>
    <article>
      <img
        src="/tas/tas-lodgement-form.png"
        alt="Tasmania lodgement form"
        style="display: block; margin: 0 auto; max-height: 100%; max-width: 100%"
      />
    </article>
    <article></article>
  </div>
</template>

<script lang="ts">
import AppointmentOfAttorneys from './components/financial/AppointmentOfAttorneys.vue';

export default {
  components: {
    AppointmentOfAttorneys,
  },
};
</script>
