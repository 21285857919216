<template>
  <div class="container">
    <article>
      <h1>Instructions for signing your Medical Treatment Decision Maker - (VIC)</h1>
      <p>
        Thank you for choosing Safewill. Your Medical Treatment Decision Maker is almost complete -
        follow the steps below, provided by our legal advisers, to sign your form and have it
        witnessed.
      </p>
      <h4>
        <em>Step 1 – Print your Appointment of Medical Treatment Decision Maker</em>
      </h4>
      <p>
        Print your form, read it carefully and ensure that you understand it completely. If you do
        not understand what you are signing, we recommend seeking independent legal advice.
      </p>
      <h4><em>Step 2 – Find two people to act as witnesses</em></h4>
      <p>
        Once you have understood your appointment form, you will need to find two adult witnesses
        (that you have not appointed as your decision maker) to sign the document and witness your
        signing of it. At least one of your witnesses must be:
      </p>
      <ul>
        <li>a registered medical practitioner; or</li>
        <li>
          a person authorised to witness an affidavit, for example a Justice of the Peace or an
          Australian solicitor (for a full list see
          <a href="https://www.justice.vic.gov.au/affidavit"
            >https://www.justice.vic.gov.au/affidavit</a
          >).
        </li>
      </ul>
      <p>
        Your witnesses are required to confirm that you appeared to have decision-making capacity,
        understood the effect of the document and that you signed voluntarily (as set out in the
        witness certificates below).
      </p>
      <h4>
        <em>Step 3 – Signing your Appointment of Medical Treatment Decision Maker</em>
      </h4>
      <p>
        Sign using a blue or black pen. You should first sign the appointment form in the presence
        of your witnesses before the witnesses sign the document themselves. Signing every page of
        the document is not compulsory but may provide protection against pages being substituted.
        The form must then be provided to your decision maker(s) to sign and accept their
        appointment in front of a witness.
      </p>
      <h4>
        <em>Step 4 – Keep your Appointment of Medical Treatment Decision Maker safe</em>
      </h4>
      <p>
        It is important to store your appointment form in a safe place and provide your attorney
        with a copy. You should also keep a copy with someone you trust like your solicitor or
        health practitioner. It may also be uploaded on MyHealth Record.
      </p>
      <h4><em>Note on Revocation</em></h4>
      <p>
        In Victoria, signing a new Appointment of Medical Treatment Decision Maker does not
        automatically revoke an old Appointment of Medical Treatment Decision Maker. As such,
        <strong>if you</strong>:
      </p>
      <ul>
        <li>
          have an existing Appointment of Medical Treatment Decision Maker that you would like to
          revoke before making this Appointment of Medical Treatment Decision Maker;
          <strong>or</strong>
        </li>
        <li>
          would like to change anything in this Appointment of Medical Treatment Decision Maker
          after it is signed;
        </li>
      </ul>
      <p>
        please follow the instructions on revocation included at the end of this Appointment of
        Medical Treatment Decision Maker. Please also note that an Appointment of Medical Treatment
        Decision Maker can only be revoked by a person while they have mental capacity.
      </p>
    </article>
    <AppointmentOfAttorneys />
    <article>
      <h2>Revocation of Existing Appointment of Medical Treatment Decision Maker</h2>
      <p>
        If you would like to revoke your existing Appointment of Medical Treatment Decision Maker,
        please follow these steps.
      </p>
      <h4><em>Step 1 - Find a witness</em></h4>
      <p>
        You will need to find two adult witnesses (not appointed under the document) to sign the
        revocation and witness your signing of it. At least one of your witnesses must be:
      </p>
      <ul>
        <li>a registered medical practitioner; or</li>
        <li>
          a person authorised to witness an affidavit, for example a Justice of the Peace or an
          Australian solicitor (for a full list see
          <a href="https://www.justice.vic.gov.au/affidavit"
            >https://www.justice.vic.gov.au/affidavit</a
          >).
        </li>
      </ul>
      <p>
        Your witnesses are required to confirm that you appeared to have decision-making capacity,
        understood the effect of the document and that you signed voluntarily.
      </p>
      <h4>
        <em>Step 2 - Complete and sign the Revocation of Medical Treatment Decision Maker </em>
      </h4>
      <p>
        The Revocation form can be found here:<br />
        <a
          href="https://www.health.vic.gov.au/sites/default/files/migrated/files/collections/forms-and-templates/a/advance-care-planning-forms/revocation-of-medical-treatment-decision-maker.pdf"
          >https://www.health.vic.gov.au/sites/default/files/migrated/files/collections/forms-and-templates/a/advance-care-planning-forms/revocation-of-medical-treatment-decision-maker.pdf</a
        >
      </p>
      <p>
        You must sign the Revocation of Medical Treatment Decision Maker in the presence of your
        witnesses. Make sure both of your witnesses are watching you sign and date the document.
        After this your witnesses must also sign where indicated.
      </p>
      <h4><em>Step 3 - Give written notice of the Revocation </em></h4>
      <p>
        After you have signed your Revocation of Medical Treatment Decision Maker and it has been
        witnessed, it is recommended that you inform your medical treatment decision maker that
        their appointment has been revoked. It is recommended you also inform people who know of the
        appointment, such as your doctor.
      </p>
    </article>
  </div>
</template>

<script lang="ts">
import AppointmentOfAttorneys from './components/medical/AppointmentOfAttorneys.vue';

export default {
  components: {
    AppointmentOfAttorneys,
  },
};
</script>
