<template>
  <article>
    <section>
      <h2 class="text-center">
        APPOINTMENT OF ENDURING
        {{ plural('GUARDIAN', primaryAttorneys).toUpperCase() }}
      </h2>
      <ol>
        <li>
          <p>
            I, <strong>{{ ownerPerson?.fullName }}</strong> {{ ownerPerson?.fullAddress }},
            <strong>appoint</strong> the following
            {{ ifPlural('people', 'person', primaryAttorneys) }} to be my Enduring
            {{ plural('Guardian', primaryAttorneys) }}:
          </p>
          <ol type="a">
            <li :key="attorney.id" v-for="attorney of primaryAttorneys">
              <strong>{{ attorney.directoryPerson.fullName }}</strong>
              {{ attorney.directoryPerson.identifiers }}
            </li>
          </ol>
        </li>
        <li v-if="hasMultipleAttorneys">
          I would like my Enduring {{ plural('Guardian', primaryAttorneys) }} to act
          {{ mf('attorney_decisions', poa?.meta) }}
        </li>
        <li v-if="hasMultipleAttorneys && actJointly">
          The death, resignation or incapacity of any of my joint Enduring Guardians
          {{ othersMayAct ? 'does not' : 'does' }} terminate the appointment of each of my other
          joint enduring guardians.
        </li>
        <li v-if="hasSecondaries">
          If {{ ifPlural('all of my', 'my', primaryAttorneys) }} Enduring
          {{ plural('Guardian', primaryAttorneys) }} appointed in clause 1
          {{ ifPlural('die, resign', 'dies, resigns', primaryAttorneys) }} or otherwise cannot act,
          I appoint the following person to be my substitute Enduring Guardian:
          <ol type="a">
            <li>
              <strong>{{ secondaryAttorneys[0].directoryPerson.fullName }}</strong>
              {{ secondaryAttorneys[0].directoryPerson.identifiers }}
            </li>
          </ol>
        </li>
      </ol>
    </section>
    <section>
      <h3>FUNCTIONS</h3>
      <ol :start="2 + hasMultipleAttorneys + (hasMultipleAttorneys && actJointly) + hasSecondaries">
        <li>
          <p>
            Should I become incapable of making my own personal decisions, I authorise my Enduring
            {{ plural('Guardian', primaryAttorneys) }}:
          </p>
          <ol type="a">
            <li>to decide where I live,</li>
            <li>to decide what health care I receive,</li>
            <li>to decide what other kinds of personal services I receive,</li>
            <li>
              to consent to the carrying out of medical or dental treatment on me (in accordance
              with Part 5 of the <em>Guardianship Act 1987</em>);
            </li>
          </ol>
          <p>subject to any limits set out below.</p>
        </li>
      </ol>
    </section>
    <section>
      <h3>LIMITS</h3>
      <ol :start="3 + hasMultipleAttorneys + (hasMultipleAttorneys && actJointly) + hasSecondaries">
        <li>
          <p>
            I place the following limits on the authority of my Enduring
            {{ plural('Guardian', primaryAttorneys) }}:
          </p>
          <ul>
            <li v-if="hasLimitations">{{ mf('limitations', poa?.meta) }}</li>
            <li v-else>NIL</li>
          </ul>
        </li>
      </ol>
    </section>
    <section>
      <h3>DIRECTIONS</h3>
      <ol :start="4 + hasMultipleAttorneys + (hasMultipleAttorneys && actJointly) + hasSecondaries">
        <li>
          <p>
            The functions of my Enduring
            {{ plural('Guardian', primaryAttorneys) }} must be exercised in accordance with the
            following directions:
          </p>
          <ul>
            <li v-if="hasPreferences">{{ mf('preferences', poa?.meta) }}</li>
            <li v-else>NIL</li>
          </ul>
        </li>
      </ol>
    </section>
    <section>
      <h3>ATTESTATION</h3>
      <ol :start="5 + hasMultipleAttorneys + (hasMultipleAttorneys && actJointly) + hasSecondaries">
        <li>
          <h4>APPOINTOR'S SIGNATURE</h4>
          <div class="sign-container">
            <div class="sign-prefixed">
              <div class="sign-prefix">
                {{ ownerPerson?.fullName }}
              </div>
              <div class="sign">Name</div>
            </div>
            <div class="sign">Signature</div>
            <div class="sign">Date</div>
          </div>
        </li>
        <li>
          <h4>WITNESS CERTIFICATE</h4>
          <div class="sign-container">
            <div class="sign-prefixed">
              <div class="sign-prefix">I,</div>
              <div class="sign">Witness full name</div>
            </div>
            <div class="sign-prefixed">
              <div class="sign-prefix">Of</div>
              <div class="sign">Witness address</div>
            </div>
            <div class="sign-prefixed">
              <div class="sign">Phone number</div>
            </div>
          </div>
          <p>Occupation <em>[tick the appropriate category]</em>:</p>
          <ul class="checkboxes">
            <li>Australian legal practitioner</li>
            <li>Registrar of the NSW Local Court</li>
            <li>Overseas-registered foreign lawyer</li>
            <li>Approved employee of NSW Trustee & Guardian</li>
          </ul>
          <p>
            Certify that <strong>{{ ownerPerson?.fullName }}</strong> appeared to understand the
            effect of this instrument and voluntarily executed the instrument in my presence.
          </p>
          <div class="sign-container">
            <div class="sign">Signature</div>
            <div class="sign">Date</div>
          </div>
        </li>
        <template :key="attorney.id" v-for="attorney of primaryAttorneys">
          <li>
            <h4>ACCEPTANCE BY ENDURING GUARDIAN</h4>
            <p>I accept my appointment as Enduring Guardian.</p>
            <div class="sign-container">
              <div class="sign-prefixed">
                <div class="sign-prefix">
                  {{ attorney.directoryPerson.fullName }}
                </div>
                <div class="sign">Name</div>
              </div>
              <div class="sign">Signature</div>
              <div class="sign">Date</div>
            </div>
          </li>
          <li>
            <h4>WITNESS CERTIFICATE</h4>
            <div class="sign-container">
              <div class="sign-prefixed">
                <div class="sign-prefix">I,</div>
                <div class="sign">Witness full name</div>
              </div>
              <div class="sign-prefixed">
                <div class="sign-prefix">Of</div>
                <div class="sign">Witness address</div>
              </div>
              <div class="sign-prefixed">
                <div class="sign">Phone number</div>
              </div>
            </div>
            <p>Occupation <em>[tick the appropriate category]</em>:</p>
            <ul class="checkboxes">
              <li>Australian legal practitioner</li>
              <li>Registrar of the NSW Local Court</li>
              <li>Overseas-registered foreign lawyer</li>
              <li>Approved employee of NSW Trustee & Guardian</li>
            </ul>
            <p>
              Certify that
              <strong>{{ attorney.directoryPerson.fullName }}</strong>
              appeared to understand the effect of this instrument and voluntarily executed the
              instrument in my presence.
            </p>
            <div class="sign-container">
              <div class="sign">Signature</div>
              <div class="sign">Date</div>
            </div>
          </li>
        </template>
        <template v-if="hasSecondaries">
          <li>
            <h4>SUBSTITUTE ENDURING GUARDIAN’S ACCEPTANCE</h4>
            <p>I accept my appointment as Substitute Enduring Guardian.</p>
            <div class="sign-container">
              <div class="sign-prefixed">
                <div class="sign-prefix">
                  {{ secondaryAttorneys[0].directoryPerson.fullName }}
                </div>
                <div class="sign">Name</div>
              </div>
              <div class="sign">Signature</div>
              <div class="sign">Date</div>
            </div>
          </li>
          <li>
            <h4>SUBSTITUTE ENDURING GUARDIAN’S WITNESS CERTIFICATE</h4>
            <div class="sign-container">
              <div class="sign-prefixed">
                <div class="sign-prefix">I,</div>
                <div class="sign">Witness full name</div>
              </div>
              <div class="sign-prefixed">
                <div class="sign-prefix">Of</div>
                <div class="sign">Witness address</div>
              </div>
              <div class="sign-prefixed">
                <div class="sign">Phone number</div>
              </div>
            </div>
            <p>Occupation <em>[tick the appropriate category]</em>:</p>
            <ul class="checkboxes">
              <li>Australian legal practitioner</li>
              <li>Registrar of the NSW Local Court</li>
              <li>Overseas-registered foreign lawyer</li>
              <li>Approved employee of NSW Trustee & Guardian</li>
            </ul>
            <p>
              Certify that
              <strong>{{ secondaryAttorneys[0].directoryPerson.fullName }}</strong>
              appeared to understand the effect of this instrument and voluntarily executed the
              instrument in my presence.
            </p>
            <div class="sign-container">
              <div class="sign">Signature</div>
              <div class="sign">Date</div>
            </div>
          </li>
        </template>
      </ol>
    </section>
  </article>
</template>

<script lang="ts">
import poa from '@/hooks/poa';
import { mf, plural, ifPlural } from '@/utils/format';
import { medicalPoaKey, ownerPersonKey } from '@/types/commonTypes';
import { inject } from 'vue';

export default {
  setup() {
    const ownerPerson = inject(ownerPersonKey);
    const medicalPoa = inject(medicalPoaKey);

    const {
      actJointly,
      attorneys,
      hasInclusions,
      hasLimitations,
      hasPreferences,
      hasMultipleAttorneys,
      inclusions,
      hasSecondaries,
      othersMayAct,
      primaryAttorneys,
      secondaryAttorneys,
    } = poa(medicalPoa?.value);

    return {
      actJointly,
      attorneys,
      hasInclusions,
      hasLimitations,
      hasPreferences,
      hasMultipleAttorneys,
      inclusions,
      hasSecondaries,
      othersMayAct,
      primaryAttorneys,
      secondaryAttorneys,
      poa: medicalPoa,
      ownerPerson,
    };
  },
  methods: {
    mf,
    plural,
    ifPlural,
  },
};
</script>
