import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { type: "a" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["start"]
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { type: "a" }
const _hoisted_6 = ["start"]
const _hoisted_7 = ["start"]
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = ["start"]
const _hoisted_11 = { class: "sign-container" }
const _hoisted_12 = { class: "sign-prefixed" }
const _hoisted_13 = { class: "sign-prefix" }
const _hoisted_14 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_strike = _resolveComponent("strike")!

  return (_openBlock(), _createElementBlock("article", null, [
    _createElementVNode("section", null, [
      _cache[9] || (_cache[9] = _createElementVNode("h2", null, "APPOINTMENT OF ATTORNEYS", -1)),
      _createElementVNode("ol", null, [
        _createElementVNode("li", null, [
          _cache[0] || (_cache[0] = _createTextVNode(" I, ")),
          _createElementVNode("strong", null, _toDisplayString($setup.ownerPerson?.fullName), 1),
          _createTextVNode(" " + _toDisplayString($setup.ownerPerson?.fullAddress) + ", ", 1),
          _cache[1] || (_cache[1] = _createElementVNode("strong", null, "appoint", -1)),
          _cache[2] || (_cache[2] = _createTextVNode(": ")),
          _createElementVNode("ol", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.primaryAttorneys, (attorney) => {
              return (_openBlock(), _createElementBlock("li", {
                key: attorney.id
              }, [
                _createElementVNode("strong", null, _toDisplayString(attorney.directoryPerson.fullName), 1),
                _createTextVNode(" " + _toDisplayString(attorney.directoryPerson.identifiers), 1)
              ]))
            }), 128))
          ]),
          _cache[3] || (_cache[3] = _createTextVNode(" to be my ")),
          ($setup.hasMultipleAttorneys)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createTextVNode(_toDisplayString($options.mf('attorney_decisions', $setup.poa?.meta).replaceAll('ly', '').replace('or', 'and')), 1)
              ], 64))
            : _createCommentVNode("", true),
          _createTextVNode(" " + _toDisplayString($options.plural('attorney', $setup.primaryAttorneys)) + ". ", 1)
        ]),
        ($setup.hasSecondaries)
          ? (_openBlock(), _createElementBlock("li", _hoisted_2, [
              _cache[4] || (_cache[4] = _createTextVNode(" I ")),
              _cache[5] || (_cache[5] = _createElementVNode("strong", null, "appoint", -1)),
              _cache[6] || (_cache[6] = _createTextVNode("  ")),
              _createElementVNode("strong", null, _toDisplayString($setup.secondaryAttorneys[0].directoryPerson.fullName), 1),
              _createTextVNode(" " + _toDisplayString($setup.secondaryAttorneys[0].directoryPerson.identifiers) + " as ", 1),
              _cache[7] || (_cache[7] = _createElementVNode("strong", null, "alternative attorney", -1)),
              _cache[8] || (_cache[8] = _createTextVNode(" for: ")),
              _createElementVNode("ul", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.primaryAttorneys, (attorney) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: attorney.id
                  }, [
                    _createElementVNode("strong", null, _toDisplayString(attorney.directoryPerson.fullName), 1),
                    _createTextVNode(" " + _toDisplayString(attorney.directoryPerson.identifiers), 1)
                  ]))
                }), 128))
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("section", null, [
      _cache[19] || (_cache[19] = _createElementVNode("h3", null, "AUTHORISATION", -1)),
      _createElementVNode("ol", {
        start: 2 + $setup.hasSecondaries
      }, [
        _createElementVNode("li", null, [
          _cache[13] || (_cache[13] = _createTextVNode(" I ")),
          _cache[14] || (_cache[14] = _createElementVNode("strong", null, "authorise", -1)),
          _cache[15] || (_cache[15] = _createTextVNode(" my attorney(s) to do anything on my behalf that I can lawfully do by an attorney for ")),
          _createElementVNode("ul", null, [
            _createElementVNode("li", null, [
              _createVNode(_component_strike, null, {
                default: _withCtx(() => _cache[10] || (_cache[10] = [
                  _createTextVNode("personal matters only")
                ])),
                _: 1
              })
            ]),
            _cache[12] || (_cache[12] = _createElementVNode("li", null, [
              _createElementVNode("strong", null, "financial matters only")
            ], -1)),
            _createElementVNode("li", null, [
              _createVNode(_component_strike, null, {
                default: _withCtx(() => _cache[11] || (_cache[11] = [
                  _createTextVNode("the following specified matters")
                ])),
                _: 1
              })
            ])
          ])
        ]),
        ($setup.hasInclusions)
          ? (_openBlock(), _createElementBlock("li", _hoisted_4, [
              _cache[16] || (_cache[16] = _createTextVNode(" I ")),
              _cache[17] || (_cache[17] = _createElementVNode("strong", null, "authorise", -1)),
              _cache[18] || (_cache[18] = _createTextVNode(" my attorney(s) to provide for the needs of the following people as is reasonable: ")),
              _createElementVNode("ol", _hoisted_5, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.inclusions, (inclusion) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: inclusion.id
                  }, [
                    _createElementVNode("strong", null, _toDisplayString(inclusion.directoryPerson.fullName), 1),
                    _createTextVNode(" " + _toDisplayString(inclusion.directoryPerson.identifiers), 1)
                  ]))
                }), 128))
              ])
            ]))
          : _createCommentVNode("", true)
      ], 8, _hoisted_3)
    ]),
    _createElementVNode("section", null, [
      _cache[21] || (_cache[21] = _createElementVNode("h3", null, "COMMENCEMENT", -1)),
      _createElementVNode("ol", {
        start: 3 + $setup.hasSecondaries + $setup.hasInclusions
      }, _cache[20] || (_cache[20] = [
        _createElementVNode("li", null, " The powers under this Enduring Power of Attorney for financial matters are exercisable when I cease to have decision making capacity for the matter(s). ", -1)
      ]), 8, _hoisted_6)
    ]),
    _createElementVNode("section", null, [
      _cache[23] || (_cache[23] = _createElementVNode("h3", null, "CONDITIONS AND INSTRUCTIONS", -1)),
      _createElementVNode("ol", {
        start: 4 + $setup.hasSecondaries + $setup.hasInclusions
      }, [
        _createElementVNode("li", null, [
          _cache[22] || (_cache[22] = _createTextVNode(" The exercise of power under this enduring power of attorney is subject to the following conditions or instructions: ")),
          _createElementVNode("ul", null, [
            ($setup.hasLimitations)
              ? (_openBlock(), _createElementBlock("li", _hoisted_8, _toDisplayString($options.mf('limitations', $setup.poa?.meta)), 1))
              : (_openBlock(), _createElementBlock("li", _hoisted_9, "NIL"))
          ])
        ])
      ], 8, _hoisted_7)
    ]),
    _createElementVNode("section", null, [
      _cache[37] || (_cache[37] = _createElementVNode("h3", null, "EXECUTION", -1)),
      _createElementVNode("ol", {
        start: 5 + $setup.hasSecondaries + $setup.hasInclusions
      }, [
        _createElementVNode("li", null, [
          _cache[27] || (_cache[27] = _createElementVNode("h4", null, "PRINCIPAL", -1)),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("div", _hoisted_13, _toDisplayString($setup.ownerPerson?.fullName), 1),
              _cache[24] || (_cache[24] = _createElementVNode("div", { class: "sign" }, "Name", -1))
            ]),
            _cache[25] || (_cache[25] = _createElementVNode("div", { class: "sign" }, "Signature", -1)),
            _cache[26] || (_cache[26] = _createElementVNode("div", { class: "sign" }, "Date", -1))
          ])
        ]),
        _cache[36] || (_cache[36] = _createStaticVNode("<li><h4>CERTIFICATE OF WITNESSES</h4><p><strong>Witnessed</strong> by:</p><div class=\"sign-container\"><div class=\"sign\">Name of first witness:</div><div class=\"sign\">Address of first witness:</div></div><div class=\"sign-container\"><div class=\"sign\">Name of second witness:</div><div class=\"sign\">Address of second witness:</div></div><p><strong>Each witness certifies</strong> that:</p><p> the principal appeared to sign this instrument freely and voluntarily in my presence; and </p><ul><li> at that time, the principal appeared to me to have decision making capacity in relation to the making of this enduring power of attorney; and </li><li>I am not an attorney under this enduring power of attorney; and</li><li> I am not a relative of the principal or an attorney under this enduring power of attorney; and </li><li>I am not a care worker or accommodation provider for the principal.</li></ul><h4>Signed</h4><div class=\"sign-container\"><div class=\"sign\">First witness (signature)</div><div class=\"sign\"> Qualification <em>[if first witness is acting as medical practitioner or person authorised to witness affidavits]</em></div></div><div class=\"sign-container\"><div class=\"sign\">Second witness (signature)</div><div class=\"sign\"> Qualification <em>[if second witness is acting as medical practitioner or person authorised to witness affidavits]</em></div></div><div class=\"sign-container\"><div class=\"sign\">Date</div></div></li>", 1)),
        _createElementVNode("li", null, [
          _cache[31] || (_cache[31] = _createElementVNode("h4", null, "STATEMENT OF ACCEPTANCE OF APPOINTMENT - ATTORNEYS", -1)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.primaryAttorneys, (attorney) => {
            return (_openBlock(), _createElementBlock("section", {
              key: attorney.id
            }, [
              _createElementVNode("p", null, [
                _cache[28] || (_cache[28] = _createTextVNode(" Name of attorney: ")),
                _createElementVNode("strong", null, _toDisplayString(attorney.directoryPerson.fullName), 1),
                _cache[29] || (_cache[29] = _createElementVNode("br", null, null, -1)),
                _createTextVNode(" Address of attorney: " + _toDisplayString(attorney.directoryPerson.fullAddress), 1)
              ]),
              _cache[30] || (_cache[30] = _createStaticVNode("<p><strong>I accept my appointment as attorney</strong> under this enduring power of attorney and state that: </p><ul><li> I am eligible under Part 3 of the <strong>Powers of Attorney Act 2014</strong> to act as an attorney under an enduring power of attorney; and </li><li> I understand the obligations of an attorney under an enduring power of attorney and under the <strong>Powers of Attorney Act 2014</strong> and the consequences of failing to comply with those obligations; and </li><li> I undertake to act in accordance with the provisions of the <strong>Powers of Attorney Act 2014</strong> that relate to enduring powers of attorney. </li></ul><p><em>Cross out the statement that does not apply:</em></p><ul><li> I have disclosed to the principal that I have been convicted or found guilty of an offence involving dishonesty </li><li>I have not been convicted or found guilty of an offence involving dishonesty</li></ul><div class=\"sign-container\"><div class=\"sign\">Signature</div><div class=\"sign\">Date</div></div><p><strong>Witnessed</strong> by:</p><div class=\"sign-container\"><div class=\"sign\">Name of witness</div><div class=\"sign\">Address of witness</div></div><p>I witnessed the signing of the statement of acceptance by the attorney.</p><h4>Signed:</h4><div class=\"sign-container\"><div class=\"sign\">Signature of witness</div><div class=\"sign\">Date</div></div><p> Note: Each attorney must sign a statement of acceptance and it must be witnessed separately in the enduring power of attorney. </p>", 11))
            ]))
          }), 128))
        ]),
        ($setup.hasSecondaries)
          ? (_openBlock(), _createElementBlock("li", _hoisted_14, [
              _cache[34] || (_cache[34] = _createElementVNode("h4", null, "STATEMENT OF ACCEPTANCE OF APPOINTMENT – ALTERNATIVE ATTORNEY", -1)),
              _createElementVNode("p", null, [
                _cache[32] || (_cache[32] = _createTextVNode(" Name of alternative attorney: ")),
                _createElementVNode("strong", null, _toDisplayString($setup.secondaryAttorneys[0].directoryPerson.fullName), 1),
                _cache[33] || (_cache[33] = _createElementVNode("br", null, null, -1)),
                _createTextVNode(" Address of alternative attorney: " + _toDisplayString($setup.secondaryAttorneys[0].directoryPerson.fullAddress), 1)
              ]),
              _cache[35] || (_cache[35] = _createStaticVNode("<p> I accept my appointment as an alternative attorney under this enduring power of attorney and state that: </p><ul><li> I am eligible under Part 3 of the <strong>Powers of Attorney Act 2014</strong> to act as an attorney under an enduring power of attorney; and </li><li> I understand the obligations of an attorney under an enduring power of attorney and under the <strong>Powers of Attorney Act 2014</strong> and the consequences of failing to comply with those obligations; and </li><li> I undertake to act in accordance with the provisions of the <strong>Powers of Attorney Act 2014</strong> that relate to enduring powers of attorney; and </li><li> I understand the circumstances in which the alternative attorney is authorised to act under the <strong>Powers of Attorney Act 2014</strong>; and </li><li> I am prepared to act in the place of the attorney for whom I am appointed, if still eligible to act as attorney, when authorised to do so under the <strong>Powers of Attorney Act 2014</strong>. </li></ul><p><em>Cross out the statement that does not apply:</em></p><ul><li> I have disclosed to the principal that I have been convicted or found guilty of an offence involving dishonesty </li><li>I have not been convicted or found guilty of an offence involving dishonesty</li></ul><div class=\"sign-container\"><div class=\"sign\">Signature</div><div class=\"sign\">Date</div></div><section><p><strong>Witnessed</strong> by:</p><div class=\"sign-container\"><div class=\"sign\">Name of witness</div><div class=\"sign\">Address of witness</div></div><p> I witnessed the signing of the statement of acceptance by the alternative attorney. </p><h4>Signed:</h4><div class=\"sign-container\"><div class=\"sign\">Signature of witness</div><div class=\"sign\">Date</div></div></section>", 6))
            ]))
          : _createCommentVNode("", true)
      ], 8, _hoisted_10)
    ])
  ]))
}