<template>
  <div class="container">
    <article>
      <h1>Instructions for signing your Enduring Guardianship (WA)</h1>
      <p>
        Thank you for choosing Safewill. Your Enduring Guardianship is almost complete – follow the
        steps below, provided by our legal advisers, to sign your Enduring Guardianship and have it
        witnessed.
      </p>
      <h4><em>Step 1 – Print your Enduring Guardianship</em></h4>
      <p>
        Print your Enduring Guardianship, read it carefully and ensure that you understand it
        completely. If you do not understand what you are signing, we recommend seeking independent
        legal advice.
      </p>
      <h4><em>Step 2 – Find two people to act as witnesses</em></h4>
      <p>
        Once you have understood your Enduring Guardianship, you will need to find two witnesses.
        The first witness must be someone who is authorised by law to take statutory declarations.
        In Western Australia, this includes:
      </p>
      <ul>
        <li>Accountants</li>
        <li>Medical practitioners</li>
        <li>Australian lawyers</li>
        <li>Justice of the Peace</li>
        <li>Real estate agents</li>
      </ul>
      <p>
        See the full list of authorised witnesses here:
        <a
          href="https://www.wa.gov.au/government/publications/authorised-witnesses-statutory-declarations"
          >www.wa.gov.au/government/publications/authorised-witnesses-statutory-declarations</a
        >
      </p>
      <p>The second witness can be anyone who is:</p>
      <ul>
        <li>18 years of age or older; and</li>
        <li>not being appointed as a guardian or a substitute guardian.</li>
      </ul>
      <h4><em>Step 3 – Signing your Enduring Guardianship</em></h4>
      <p>
        Sign using a blue or black pen. You should first sign and date the Enduring Guardianship in
        the presence of both your witnesses, before the witnesses sign the document themselves.
        Signing the bottom of each page of the document is not mandatory, however we recommend doing
        so to protect against pages being substituted. After this, you will need to provide the
        document to your guardians to sign and accept their appointment, also in the presence of two
        witnesses.
      </p>
      <h4><em>Step 4 – Keep your Enduring Guardianship safe</em></h4>
      <p>
        It is important to store your Enduring Guardianship in a safe place and provide your
        guardian with a copy. You should also keep a copy with someone you trust like your solicitor
        or health practitioner.
      </p>
      <h4><em>Note on Revocation</em></h4>
      <p>
        In Western Australia, signing a new Enduring Guardianship does not automatically revoke an
        old Enduring Guardianship. As such <strong>if you</strong>:
      </p>
      <ul>
        <li>
          have an existing Enduring Guardianship that you would like to revoke before making this
          Enduring Guardianship; <strong>or</strong>
        </li>
        <li>
          would like to change anything in this Enduring Guardianship document after it is signed;
        </li>
      </ul>
      <p>
        please follow the instructions on revocation included at the end of this Enduring
        Guardianship. Please also note that an Enduring Guardianship can only be revoked by a person
        while they have mental capacity.
      </p>
    </article>
    <AppointmentOfAttorneys />
    <article>
      <h2>Revocation of an Existing Enduring Guardian</h2>
      <p>If you would like to revoke your existing Enduring Guardian, please follow these steps.</p>
      <h4><em>Step 1 - Find a witness</em></h4>
      <p>
        You (principal) will need to find one person over the age of 18 to witness and sign the
        Revocation of Enduring Guardian and witness your signing of it.
      </p>
      <h4><em>Step 2 - Endorse and sign the Revocation of Enduring Guardian</em></h4>
      <p>You will need to:</p>
      <ul>
        <li>write the word ‘REVOKED’ on the signed original Enduring Power of Attorney;</li>
        <li>sign next to the word ‘REVOKED’ in the presence of your witness;</li>
        <li>
          ensure your witness signs next to your signature and states their full name, address and
          occupation;
        </li>
        <li>ensure that you both date the Revocation.</li>
      </ul>
      <h4><em>Step 3 - Give written notice of the Revocation </em></h4>
      <p>
        After you have signed your Revocation of Enduring Guardian and it has been witnessed, you
        must, as soon as practicable, give notice of the Revocation. You can do this by writing a
        letter enclosing a copy of the completed and signed Revocation of Enduring Guardian to:
      </p>
      <ul>
        <li>all of your enduring guardian(s) whose appointment has been revoked; and</li>
        <li>
          anyone else who may be aware of or has dealings with the revoked Enduring Guardian
          document.
        </li>
      </ul>
      <p>
        You should also request that all copies of the old Enduring Guardian are returned to you so
        they can be destroyed.
      </p>
    </article>
  </div>
</template>

<script lang="ts">
import AppointmentOfAttorneys from './components/medical/AppointmentOfAttorneys.vue';

export default {
  components: {
    AppointmentOfAttorneys,
  },
};
</script>
