import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "text-center" }
const _hoisted_2 = { type: "a" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 2 }
const _hoisted_6 = { type: "a" }
const _hoisted_7 = ["start"]
const _hoisted_8 = ["start"]
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = ["start"]
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { key: 1 }
const _hoisted_14 = ["start"]
const _hoisted_15 = { class: "sign-container" }
const _hoisted_16 = { class: "sign-prefixed" }
const _hoisted_17 = { class: "sign-prefix" }
const _hoisted_18 = { class: "sign-container" }
const _hoisted_19 = { class: "sign-prefixed" }
const _hoisted_20 = { class: "sign-prefix" }
const _hoisted_21 = { class: "sign-container" }
const _hoisted_22 = { class: "sign-prefixed" }
const _hoisted_23 = { class: "sign-prefix" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("article", null, [
    _createElementVNode("section", null, [
      _createElementVNode("h2", _hoisted_1, " APPOINTMENT OF ENDURING " + _toDisplayString($options.plural('GUARDIAN', $setup.primaryAttorneys).toUpperCase()), 1),
      _createElementVNode("ol", null, [
        _createElementVNode("li", null, [
          _createElementVNode("p", null, [
            _cache[0] || (_cache[0] = _createTextVNode(" I, ")),
            _createElementVNode("strong", null, _toDisplayString($setup.ownerPerson?.fullName), 1),
            _createTextVNode(" " + _toDisplayString($setup.ownerPerson?.fullAddress) + ", ", 1),
            _cache[1] || (_cache[1] = _createElementVNode("strong", null, "appoint", -1)),
            _createTextVNode(" the following " + _toDisplayString($options.ifPlural('people', 'person', $setup.primaryAttorneys)) + " to be my Enduring " + _toDisplayString($options.plural('Guardian', $setup.primaryAttorneys)) + ": ", 1)
          ]),
          _createElementVNode("ol", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.primaryAttorneys, (attorney) => {
              return (_openBlock(), _createElementBlock("li", {
                key: attorney.id
              }, [
                _createElementVNode("strong", null, _toDisplayString(attorney.directoryPerson.fullName), 1),
                _createTextVNode(" " + _toDisplayString(attorney.directoryPerson.identifiers), 1)
              ]))
            }), 128))
          ])
        ]),
        ($setup.hasMultipleAttorneys)
          ? (_openBlock(), _createElementBlock("li", _hoisted_3, " I would like my Enduring " + _toDisplayString($options.plural('Guardian', $setup.primaryAttorneys)) + " to act " + _toDisplayString($options.mf('attorney_decisions', $setup.poa?.meta)), 1))
          : _createCommentVNode("", true),
        ($setup.hasMultipleAttorneys && $setup.actJointly)
          ? (_openBlock(), _createElementBlock("li", _hoisted_4, " The death, resignation or incapacity of any of my joint Enduring Guardians " + _toDisplayString($setup.othersMayAct ? 'does not' : 'does') + " terminate the appointment of each of my other joint enduring guardians. ", 1))
          : _createCommentVNode("", true),
        ($setup.hasSecondaries)
          ? (_openBlock(), _createElementBlock("li", _hoisted_5, [
              _createTextVNode(" If " + _toDisplayString($options.ifPlural('all of my', 'my', $setup.primaryAttorneys)) + " Enduring " + _toDisplayString($options.plural('Guardian', $setup.primaryAttorneys)) + " appointed in clause 1 " + _toDisplayString($options.ifPlural('die, resign', 'dies, resigns', $setup.primaryAttorneys)) + " or otherwise cannot act, I appoint the following person to be my substitute Enduring Guardian: ", 1),
              _createElementVNode("ol", _hoisted_6, [
                _createElementVNode("li", null, [
                  _createElementVNode("strong", null, _toDisplayString($setup.secondaryAttorneys[0].directoryPerson.fullName), 1),
                  _createTextVNode(" " + _toDisplayString($setup.secondaryAttorneys[0].directoryPerson.identifiers), 1)
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("section", null, [
      _cache[4] || (_cache[4] = _createElementVNode("h3", null, "FUNCTIONS", -1)),
      _createElementVNode("ol", {
        start: 2 + $setup.hasMultipleAttorneys + ($setup.hasMultipleAttorneys && $setup.actJointly) + $setup.hasSecondaries
      }, [
        _createElementVNode("li", null, [
          _createElementVNode("p", null, " Should I become incapable of making my own personal decisions, I authorise my Enduring " + _toDisplayString($options.plural('Guardian', $setup.primaryAttorneys)) + ": ", 1),
          _cache[2] || (_cache[2] = _createElementVNode("ol", { type: "a" }, [
            _createElementVNode("li", null, "to decide where I live,"),
            _createElementVNode("li", null, "to decide what health care I receive,"),
            _createElementVNode("li", null, "to decide what other kinds of personal services I receive,"),
            _createElementVNode("li", null, [
              _createTextVNode(" to consent to the carrying out of medical or dental treatment on me (in accordance with Part 5 of the "),
              _createElementVNode("em", null, "Guardianship Act 1987"),
              _createTextVNode("); ")
            ])
          ], -1)),
          _cache[3] || (_cache[3] = _createElementVNode("p", null, "subject to any limits set out below.", -1))
        ])
      ], 8, _hoisted_7)
    ]),
    _createElementVNode("section", null, [
      _cache[5] || (_cache[5] = _createElementVNode("h3", null, "LIMITS", -1)),
      _createElementVNode("ol", {
        start: 3 + $setup.hasMultipleAttorneys + ($setup.hasMultipleAttorneys && $setup.actJointly) + $setup.hasSecondaries
      }, [
        _createElementVNode("li", null, [
          _createElementVNode("p", null, " I place the following limits on the authority of my Enduring " + _toDisplayString($options.plural('Guardian', $setup.primaryAttorneys)) + ": ", 1),
          _createElementVNode("ul", null, [
            ($setup.hasLimitations)
              ? (_openBlock(), _createElementBlock("li", _hoisted_9, _toDisplayString($options.mf('limitations', $setup.poa?.meta)), 1))
              : (_openBlock(), _createElementBlock("li", _hoisted_10, "NIL"))
          ])
        ])
      ], 8, _hoisted_8)
    ]),
    _createElementVNode("section", null, [
      _cache[6] || (_cache[6] = _createElementVNode("h3", null, "DIRECTIONS", -1)),
      _createElementVNode("ol", {
        start: 4 + $setup.hasMultipleAttorneys + ($setup.hasMultipleAttorneys && $setup.actJointly) + $setup.hasSecondaries
      }, [
        _createElementVNode("li", null, [
          _createElementVNode("p", null, " The functions of my Enduring " + _toDisplayString($options.plural('Guardian', $setup.primaryAttorneys)) + " must be exercised in accordance with the following directions: ", 1),
          _createElementVNode("ul", null, [
            ($setup.hasPreferences)
              ? (_openBlock(), _createElementBlock("li", _hoisted_12, _toDisplayString($options.mf('preferences', $setup.poa?.meta)), 1))
              : (_openBlock(), _createElementBlock("li", _hoisted_13, "NIL"))
          ])
        ])
      ], 8, _hoisted_11)
    ]),
    _createElementVNode("section", null, [
      _cache[33] || (_cache[33] = _createElementVNode("h3", null, "ATTESTATION", -1)),
      _createElementVNode("ol", {
        start: 5 + $setup.hasMultipleAttorneys + ($setup.hasMultipleAttorneys && $setup.actJointly) + $setup.hasSecondaries
      }, [
        _createElementVNode("li", null, [
          _cache[10] || (_cache[10] = _createElementVNode("h4", null, "APPOINTOR'S SIGNATURE", -1)),
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("div", _hoisted_16, [
              _createElementVNode("div", _hoisted_17, _toDisplayString($setup.ownerPerson?.fullName), 1),
              _cache[7] || (_cache[7] = _createElementVNode("div", { class: "sign" }, "Name", -1))
            ]),
            _cache[8] || (_cache[8] = _createElementVNode("div", { class: "sign" }, "Signature", -1)),
            _cache[9] || (_cache[9] = _createElementVNode("div", { class: "sign" }, "Date", -1))
          ])
        ]),
        _createElementVNode("li", null, [
          _cache[13] || (_cache[13] = _createStaticVNode("<h4>WITNESS CERTIFICATE</h4><div class=\"sign-container\"><div class=\"sign-prefixed\"><div class=\"sign-prefix\">I,</div><div class=\"sign\">Witness full name</div></div><div class=\"sign-prefixed\"><div class=\"sign-prefix\">Of</div><div class=\"sign\">Witness address</div></div><div class=\"sign-prefixed\"><div class=\"sign\">Phone number</div></div></div><p>Occupation <em>[tick the appropriate category]</em>:</p><ul class=\"checkboxes\"><li>Australian legal practitioner</li><li>Registrar of the NSW Local Court</li><li>Overseas-registered foreign lawyer</li><li>Approved employee of NSW Trustee &amp; Guardian</li></ul>", 4)),
          _createElementVNode("p", null, [
            _cache[11] || (_cache[11] = _createTextVNode(" Certify that ")),
            _createElementVNode("strong", null, _toDisplayString($setup.ownerPerson?.fullName), 1),
            _cache[12] || (_cache[12] = _createTextVNode(" appeared to understand the effect of this instrument and voluntarily executed the instrument in my presence. "))
          ]),
          _cache[14] || (_cache[14] = _createElementVNode("div", { class: "sign-container" }, [
            _createElementVNode("div", { class: "sign" }, "Signature"),
            _createElementVNode("div", { class: "sign" }, "Date")
          ], -1))
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.primaryAttorneys, (attorney) => {
          return (_openBlock(), _createElementBlock(_Fragment, {
            key: attorney.id
          }, [
            _createElementVNode("li", null, [
              _cache[18] || (_cache[18] = _createElementVNode("h4", null, "ACCEPTANCE BY ENDURING GUARDIAN", -1)),
              _cache[19] || (_cache[19] = _createElementVNode("p", null, "I accept my appointment as Enduring Guardian.", -1)),
              _createElementVNode("div", _hoisted_18, [
                _createElementVNode("div", _hoisted_19, [
                  _createElementVNode("div", _hoisted_20, _toDisplayString(attorney.directoryPerson.fullName), 1),
                  _cache[15] || (_cache[15] = _createElementVNode("div", { class: "sign" }, "Name", -1))
                ]),
                _cache[16] || (_cache[16] = _createElementVNode("div", { class: "sign" }, "Signature", -1)),
                _cache[17] || (_cache[17] = _createElementVNode("div", { class: "sign" }, "Date", -1))
              ])
            ]),
            _createElementVNode("li", null, [
              _cache[22] || (_cache[22] = _createStaticVNode("<h4>WITNESS CERTIFICATE</h4><div class=\"sign-container\"><div class=\"sign-prefixed\"><div class=\"sign-prefix\">I,</div><div class=\"sign\">Witness full name</div></div><div class=\"sign-prefixed\"><div class=\"sign-prefix\">Of</div><div class=\"sign\">Witness address</div></div><div class=\"sign-prefixed\"><div class=\"sign\">Phone number</div></div></div><p>Occupation <em>[tick the appropriate category]</em>:</p><ul class=\"checkboxes\"><li>Australian legal practitioner</li><li>Registrar of the NSW Local Court</li><li>Overseas-registered foreign lawyer</li><li>Approved employee of NSW Trustee &amp; Guardian</li></ul>", 4)),
              _createElementVNode("p", null, [
                _cache[20] || (_cache[20] = _createTextVNode(" Certify that ")),
                _createElementVNode("strong", null, _toDisplayString(attorney.directoryPerson.fullName), 1),
                _cache[21] || (_cache[21] = _createTextVNode(" appeared to understand the effect of this instrument and voluntarily executed the instrument in my presence. "))
              ]),
              _cache[23] || (_cache[23] = _createElementVNode("div", { class: "sign-container" }, [
                _createElementVNode("div", { class: "sign" }, "Signature"),
                _createElementVNode("div", { class: "sign" }, "Date")
              ], -1))
            ])
          ], 64))
        }), 128)),
        ($setup.hasSecondaries)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createElementVNode("li", null, [
                _cache[27] || (_cache[27] = _createElementVNode("h4", null, "SUBSTITUTE ENDURING GUARDIAN’S ACCEPTANCE", -1)),
                _cache[28] || (_cache[28] = _createElementVNode("p", null, "I accept my appointment as Substitute Enduring Guardian.", -1)),
                _createElementVNode("div", _hoisted_21, [
                  _createElementVNode("div", _hoisted_22, [
                    _createElementVNode("div", _hoisted_23, _toDisplayString($setup.secondaryAttorneys[0].directoryPerson.fullName), 1),
                    _cache[24] || (_cache[24] = _createElementVNode("div", { class: "sign" }, "Name", -1))
                  ]),
                  _cache[25] || (_cache[25] = _createElementVNode("div", { class: "sign" }, "Signature", -1)),
                  _cache[26] || (_cache[26] = _createElementVNode("div", { class: "sign" }, "Date", -1))
                ])
              ]),
              _createElementVNode("li", null, [
                _cache[31] || (_cache[31] = _createStaticVNode("<h4>SUBSTITUTE ENDURING GUARDIAN’S WITNESS CERTIFICATE</h4><div class=\"sign-container\"><div class=\"sign-prefixed\"><div class=\"sign-prefix\">I,</div><div class=\"sign\">Witness full name</div></div><div class=\"sign-prefixed\"><div class=\"sign-prefix\">Of</div><div class=\"sign\">Witness address</div></div><div class=\"sign-prefixed\"><div class=\"sign\">Phone number</div></div></div><p>Occupation <em>[tick the appropriate category]</em>:</p><ul class=\"checkboxes\"><li>Australian legal practitioner</li><li>Registrar of the NSW Local Court</li><li>Overseas-registered foreign lawyer</li><li>Approved employee of NSW Trustee &amp; Guardian</li></ul>", 4)),
                _createElementVNode("p", null, [
                  _cache[29] || (_cache[29] = _createTextVNode(" Certify that ")),
                  _createElementVNode("strong", null, _toDisplayString($setup.secondaryAttorneys[0].directoryPerson.fullName), 1),
                  _cache[30] || (_cache[30] = _createTextVNode(" appeared to understand the effect of this instrument and voluntarily executed the instrument in my presence. "))
                ]),
                _cache[32] || (_cache[32] = _createElementVNode("div", { class: "sign-container" }, [
                  _createElementVNode("div", { class: "sign" }, "Signature"),
                  _createElementVNode("div", { class: "sign" }, "Date")
                ], -1))
              ])
            ], 64))
          : _createCommentVNode("", true)
      ], 8, _hoisted_14)
    ])
  ]))
}