<template>
  <Suspense>
    <App />
    <template #fallback>loading data...</template>
  </Suspense>
</template>

<script lang="ts">
import App from './App.vue';

export default { name: 'AppWrapper', components: { App } };
</script>
