<template>
  <article>
    <div class="text-center">
      <h1>ENDURING POWER OF ATTORNEY</h1>
      <p><em>Powers of Attorney Act 2006</em></p>
    </div>
    <section>
      <h3>APPOINTOR AND ATTORNEY</h3>
      <ol type="1">
        <li>
          I, <strong>{{ ownerPerson?.fullName }}</strong>
          {{ ownerPerson?.fullAddress }} (Appointor/Principal), <strong>appoint</strong> the
          following as my {{ plural('attorney', primaryAttorneys) }}:
          <ol type="a">
            <li :key="attorney.id" v-for="attorney of primaryAttorneys">
              <strong>{{ attorney.directoryPerson.fullName }}</strong>
              {{ attorney.directoryPerson.identifiers }}
            </li>
          </ol>
        </li>
      </ol>
    </section>
    <section v-if="hasSecondaries">
      <h3>SUBSTITUTE ATTORNEY</h3>
      <ol type="1" start="2">
        <li>
          If
          {{ ifPlural('all of my attorneys are ', 'my attorney is', primaryAttorneys) }}
          unwilling or unable to accept their appointment or to continue in their appointment, I
          appoint the following as my
          {{ plural('attorney', primaryAttorneys) }}:
          <ol type="a">
            <li :key="attorney.id" v-for="attorney of secondaryAttorneys">
              <strong>{{ attorney.directoryPerson.fullName }}</strong>
              {{ attorney.directoryPerson.identifiers }}
            </li>
          </ol>
        </li>
      </ol>
    </section>
    <section v-if="hasMultipleAttorneys">
      <h3>MULTIPLE ATTORNEYS</h3>
      <ol type="1" :start="1 + hasSecondaries + hasMultipleAttorneys">
        <li>
          I appoint my attorneys to act
          {{
            mf('attorney_decisions', poa?.meta)
              .replace('jointly', 'together')
              .replace('or', 'and')
              .replace('severally', 'separately')
          }}.
        </li>
      </ol>
    </section>
    <section>
      <h3>AUTHORITY TO SOMEONE ELSE</h3>
      <ol type="1" :start="2 + hasSecondaries + hasMultipleAttorneys">
        <li>
          My {{ plural('attorney', primaryAttorneys) }} must not authorise anyone else to exercise
          my {{ plural('attorney', primaryAttorneys)
          }}{{ ifPlural("'", "'s", primaryAttorneys) }} powers.
        </li>
      </ol>
    </section>
    <section>
      <h3>FUNCTIONS</h3>
      <ol type="1" :start="3 + hasSecondaries + hasMultipleAttorneys">
        <li>
          I authorise my {{ plural('attorney', primaryAttorneys) }} to do, on my behalf, anything
          that I can lawfully do in relation to the matters specified below
          <ul class="checkboxes">
            <li class="checked">property matters (includes financial matters)</li>
            <li><strike>personal care matters</strike></li>
            <li><strike>health care matters</strike></li>
            <li>
              <strike
                >medical research matters (This authorisation must be carried out in accordance with
                part 4.3A of the <em>Powers of Attorney Act 2006</em>)</strike
              >
            </li>
          </ul>
        </li>
      </ol>
    </section>
    <section>
      <h3>DIRECTIONS, LIMITATIONS AND CONDITIONS</h3>
      <ol type="1" :start="4 + hasSecondaries + hasMultipleAttorneys">
        <li>
          My {{ plural('attorney', primaryAttorneys) }} shall only exercise power under the section
          above, subject to the following directions, limitations and conditions:
          <ul class="checkboxes">
            <li v-if="hasLimitations" class="checked">
              property matters (includes financial matters)<br />
              {{ mf('limitations', poa?.meta) }}
            </li>
            <li v-else>
              <strike>property matters (includes financial matters)</strike>
            </li>
            <li><strike>personal care matters</strike></li>
            <li><strike>health care matters</strike></li>
            <li><strike>medical research matters</strike></li>
          </ul>
        </li>
      </ol>
    </section>
    <section>
      <h3>COMMENCEMENT</h3>
      <ol type="1" :start="5 + hasSecondaries + hasMultipleAttorneys">
        <li>
          My attorneys' power in relation to my property (including financial) matters comes into
          effect only when I become a person with impaired decision-making capacity.
        </li>
      </ol>
    </section>
    <section>
      <h3>STATEMENT OF UNDERSTANDING</h3>
      <ol type="1" :start="6 + hasSecondaries + hasMultipleAttorneys">
        <li>
          <strong>PRINCIPAL</strong>
        </li>
      </ol>
      <p>
        I fully understand that, by making this enduring power of attorney, I authorise my
        {{ plural('attorney', primaryAttorneys) }} to act on my behalf in accordance with the terms
        set out in this enduring power of attorney. I also understand the nature and effect of
        making an enduring power of attorney as set out in Schedule 3 to this document.
      </p>
      <div class="sign-container">
        <div class="sign-prefixed">
          <div class="sign-prefix">
            {{ ownerPerson?.fullName }}
          </div>
          <div class="sign">Name</div>
        </div>
        <div class="sign">Signature</div>
        <div class="sign">Date</div>
      </div>
    </section>
    <section>
      <h3>CERTIFICATE OF WITNESSES</h3>
      <ol type="1" :start="7 + hasSecondaries + hasMultipleAttorneys">
        <li>
          <strong>WITNESS</strong>
        </li>
      </ol>
      <p>
        (Note: Refer to the signing instructions for information about who can be a witness. The
        witnesses must sign in the presence of each other and the appointor.)
      </p>
      <h4>WITNESS 1</h4>
      <p>I, witness 1 described below:</p>
      <ol type="i">
        <li>am an adult;</li>
        <li>am authorised to witness the signing of a statutory declaration;</li>
        <li>am not appointed as attorney under this enduring power of attorney; and</li>
        <li>did not sign this enduring power of attorney for the principal.</li>
      </ol>
      <p>I certify that:</p>
      <ol type="a">
        <li>
          the principal signed this enduring power of attorney voluntarily in my presence; and
        </li>
        <li>
          at the time the principal signed this enduring power of attorney, the principal appeared
          to me to understand the nature and effect of making it as set out in Schedule 3 to this
          document.
        </li>
      </ol>
      <div class="sign-container">
        <div class="sign">Signature of witness 1</div>
        <div class="sign">Name</div>
        <div class="sign">Qualification</div>
        <div class="sign">Address</div>
        <div class="sign">Date</div>
      </div>
    </section>
    <section>
      <h4>WITNESS 2</h4>
      <p>I, witness 2 described below:</p>
      <ol type="i">
        <li>am an adult;</li>
        <li>am authorised to witness the signing of a statutory declaration;</li>
        <li>am not appointed as attorney under this enduring power of attorney; and</li>
        <li>did not sign this enduring power of attorney for the principal.</li>
      </ol>
      <p>I certify that:</p>
      <ol type="a">
        <li>
          the principal signed this enduring power of attorney voluntarily in my presence; and
        </li>
        <li>
          at the time the principal signed this enduring power of attorney, the principal appeared
          to me to understand the nature and effect of making it as set out in Schedule 3 to this
          document.
        </li>
      </ol>
      <div class="sign-container">
        <div class="sign">Signature of witness 2</div>
        <div class="sign">Name</div>
        <div class="sign">Qualification</div>
        <div class="sign">Address</div>
        <div class="sign">Date</div>
      </div>
    </section>
    <section>
      <h3>ACCEPTANCE BY {{ plural('ATTORNEY', primaryAttorneys).toUpperCase() }} OF APPOINTMENT</h3>
      <p>
        I have read this enduring power of attorney which appoints me as attorney for the principal.
        I understand that by signing this acceptance of my appointment, I undertake the
        responsibility of exercising the powers which I have been given, including the
        responsibilities and obligations set out in Schedule 2 to this document.
      </p>
      <p>I accept my appointment as Attorney:</p>
      <template :key="attorney.id" v-for="attorney of primaryAttorneys">
        <div class="sign-container">
          <div class="sign-prefixed">
            <div class="sign-prefix">
              {{ attorney.directoryPerson.fullName }}
            </div>
            <div class="sign">Name</div>
          </div>
          <div class="sign">Signature</div>
          <div class="sign">Date</div>
        </div>
      </template>
      <template :key="attorney.id" v-for="attorney of secondaryAttorneys">
        <div class="sign-container">
          <div class="sign-prefixed">
            <div class="sign-prefix">
              {{ attorney.directoryPerson.fullName }}
            </div>
            <div class="sign">Name</div>
          </div>
          <div class="sign">Signature</div>
          <div class="sign">Date</div>
        </div>
      </template>
    </section>
  </article>
</template>

<script lang="ts">
import poa from '@/hooks/poa';
import { mf, plural, ifPlural } from '@/utils/format';
import { financialPoaKey, ownerPersonKey } from '@/types/commonTypes';
import { inject } from 'vue';

export default {
  setup() {
    const ownerPerson = inject(ownerPersonKey);
    const financialPoa = inject(financialPoaKey);

    const {
      actJointly,
      attorneys,
      hasInclusions,
      hasLimitations,
      hasMultipleAttorneys,
      inclusions,
      hasSecondaries,
      othersMayAct,
      primaryAttorneys,
      secondaryAttorneys,
    } = poa(financialPoa?.value);

    return {
      actJointly,
      attorneys,
      hasInclusions,
      hasLimitations,
      hasMultipleAttorneys,
      inclusions,
      hasSecondaries,
      othersMayAct,
      primaryAttorneys,
      secondaryAttorneys,
      ownerPerson,
      poa: financialPoa,
    };
  },
  methods: {
    mf,
    plural,
    ifPlural,
  },
};
</script>
