<template>
  <div class="container">
    <article>
      <h1>Instructions for signing your Power of Attorney - Financial (WA)</h1>
      <p>
        Thank you for choosing Safewill. Your Enduring Power of Attorney is almost complete - follow
        the steps below, provided by our legal advisers, to sign your Power of Attorney and have it
        witnessed.
      </p>
      <h4><em>Step 1 – Print your Enduring Power of Attorney</em></h4>
      <p>
        Print your Enduring Power of Attorney, read it carefully and ensure that you understand it
        completely. If you do not understand what you are signing, we recommend seeking independent
        legal advice.
      </p>
      <h4><em>Step 2 – Find two people to act as witnesses</em></h4>
      <p>
        Once you have understood your Enduring Power of Attorney, you will need to find two
        witnesses. The first witness must be someone who is authorised by law to take declarations.
        In Western Australia, this includes:
      </p>
      <ul>
        <li>Accountants</li>
        <li>Medical practitioners</li>
        <li>Australian lawyers</li>
        <li>Justice of the Peace</li>
        <li>Real estate agents</li>
      </ul>
      <p>
        See the full list of authorised witnesses here:<br />
        <a
          href="https://www.wa.gov.au/government/publications/authorised-witnesses-statutory-declarations"
          >www.wa.gov.au/government/publications/authorised-witnesses-statutory-declarations</a
        >
      </p>
      <p>The second witness can be anyone who is:</p>
      <ul>
        <li>18 years of age or older; and</li>
        <li>not being appointed as an attorney or a substitute attorney.</li>
      </ul>
      <h4><em>Step 3 – Signing your Enduring Power of Attorney</em></h4>
      <p>
        Sign using a blue or black pen. You should first sign the Enduring Power of Attorney in the
        presence of both your witnesses, before the witnesses sign the document themselves. Signing
        the bottom of each page of the document is not mandatory, however we recommend doing so to
        protect against pages being substituted. After this, you will need to provide the document
        to your attorneys to sign and accept their appointment.
      </p>
      <h4><em>Step 4 – Keep your Enduring Power of Attorney safe</em></h4>
      <p>
        It is important to store your Enduring Power of Attorney in a safe place and provide your
        attorney with a copy. You should also keep a copy with someone you trust like your solicitor
        or accountant.
      </p>
      <h4><em>Note on Revocation</em></h4>
      <p>
        In Western Australia, signing a new Enduring Power of Attorney does not automatically revoke
        an old Enduring Power of Attorney. As such, <strong>if you</strong>:
      </p>
      <ul>
        <li>
          have an existing Enduring Power of Attorney that you would like to revoke before making
          this Enduring Power of Attorney; <strong>or</strong>
        </li>
        <li>
          would like to change anything in this Enduring Power of Attorney document after it is
          signed;
        </li>
      </ul>
      <p>
        please follow the instructions on revocation included at the end of this Enduring Power of
        Attorney. Please also note that an Enduring Power of Attorney can only be revoked by the
        Donor while they have mental capacity.
      </p>
    </article>
    <AppointmentOfAttorneys />
    <article>
      <h2>Revocation of an Existing Enduring Power of Attorney</h2>
      <p>
        If you would like to revoke your existing Enduring Power of Attorney, please follow these
        steps.
      </p>
      <h4><em>Step 1 - Find a witness</em></h4>
      <p>
        You (Donor) will need to find one person over the age of 18 to witness and sign the
        Revocation of Enduring Power of Attorney and witness your signing of it.
      </p>
      <h4><em>Step 2 - Endorse and sign the Revocation of Enduring Power of Attorney </em></h4>
      <p>You will need to:</p>
      <ul>
        <li>write the word ‘REVOKED’ on the signed original Enduring Power of Attorney;</li>
        <li>sign next to the word ‘REVOKED’ in the presence of your witness;</li>
        <li>
          ensure your witness signs next to your signature and states their full name, address and
          occupation;
        </li>
        <li>ensure that you both date the Revocation.</li>
      </ul>
      <h4><em>Step 3 - Give written notice of the Revocation </em></h4>
      <p>
        After you have signed your Revocation of Power of Attorney and it has been witnessed, you
        must, as soon as practicable, give notice of the Revocation. You can do this by writing a
        letter enclosing a copy of the completed and signed Revocation to:
      </p>
      <ul>
        <li>all of your attorney(s) whose appointment has been revoked; and</li>
        <li>
          anyone else who may be aware of or has dealings with the revoked Advanced Personal Plan or
          Power of Attorney.
        </li>
      </ul>
      <p>
        If you fail to give notice, your attorney(s) may continue to make decisions on your behalf
        under the old power of attorney document. You should also request that all copies of the old
        Power of Attorney are returned to you so they can be destroyed.
      </p>
      <h4><em>Step 4 - Registration of Revocation</em></h4>
      <p>
        In Western Australia, if you have not registered the Enduring Power of Attorney that you
        have just revoked, you do not need to register the Revocation of Enduring Power of Attorney.
      </p>
      <p>
        However, if you have registered the Power of Attorney, you must lodge the revoked Power of
        Attorney at WA Landgate:
      </p>
      <p>
        To find the location of the at WA Landgate for lodging, please visit the at WA Landgate
        website
        <a href="https://www0.landgate.wa.gov.au/contact-us"
          >https://www0.landgate.wa.gov.au/contact-us</a
        >. Please note there may be fees associated with the lodgement.
      </p>
      <p>
        You should keep a copy of the completed and signed Revocation for your own record keeping
        purposes.
      </p>
    </article>
  </div>
</template>

<script lang="ts">
import AppointmentOfAttorneys from './components/financial/AppointmentOfAttorneys.vue';

export default {
  components: {
    AppointmentOfAttorneys,
  },
};
</script>
