import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { start: "2" }
const _hoisted_2 = { type: "a" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { type: "a" }
const _hoisted_5 = ["start"]
const _hoisted_6 = ["start"]
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = {
  key: 0,
  type: "a"
}
const _hoisted_10 = { key: 1 }
const _hoisted_11 = ["start"]
const _hoisted_12 = { class: "sign-container" }
const _hoisted_13 = { class: "sign-prefixed" }
const _hoisted_14 = { class: "sign-prefix" }
const _hoisted_15 = { class: "sign-prefixed" }
const _hoisted_16 = { class: "sign-prefix" }
const _hoisted_17 = {
  key: 1,
  class: "sign-container"
}
const _hoisted_18 = { class: "sign-prefixed" }
const _hoisted_19 = { class: "sign-prefix" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("article", null, [
    _createElementVNode("section", null, [
      _cache[9] || (_cache[9] = _createElementVNode("h2", { class: "text-center" }, "ENDURING POWER OF ATTORNEY", -1)),
      _cache[10] || (_cache[10] = _createElementVNode("h3", null, "DONOR", -1)),
      _createElementVNode("ol", null, [
        _createElementVNode("li", null, [
          _cache[0] || (_cache[0] = _createTextVNode(" This Enduring Power of Attorney is made on the ")),
          _cache[1] || (_cache[1] = _createElementVNode("span", { class: "underline" }, null, -1)),
          _cache[2] || (_cache[2] = _createTextVNode(" of ")),
          _cache[3] || (_cache[3] = _createElementVNode("span", { class: "wide-underline" }, null, -1)),
          _cache[4] || (_cache[4] = _createTextVNode(" 20 ")),
          _cache[5] || (_cache[5] = _createElementVNode("span", { class: "underline" }, null, -1)),
          _cache[6] || (_cache[6] = _createTextVNode(", by ")),
          _createElementVNode("strong", null, _toDisplayString($setup.ownerPerson?.fullName), 1),
          _createTextVNode(", born " + _toDisplayString($setup.ownerPerson?.dateOfBirthFormatted) + ", " + _toDisplayString($setup.ownerPerson?.fullAddress) + " under section 104 of the ", 1),
          _cache[7] || (_cache[7] = _createElementVNode("em", null, "Guardianship and Administration Act 1990", -1)),
          _cache[8] || (_cache[8] = _createTextVNode(". "))
        ])
      ])
    ]),
    _createElementVNode("section", null, [
      _cache[15] || (_cache[15] = _createElementVNode("h3", null, "APPOINTMENT OF ATTORNEY", -1)),
      _createElementVNode("ol", _hoisted_1, [
        _createElementVNode("li", null, [
          _cache[11] || (_cache[11] = _createElementVNode("p", null, "I APPOINT", -1)),
          _createElementVNode("ol", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.primaryAttorneys, (attorney) => {
              return (_openBlock(), _createElementBlock("li", {
                key: attorney.id
              }, [
                _createElementVNode("strong", null, _toDisplayString(attorney.directoryPerson.fullName), 1),
                _createTextVNode(" " + _toDisplayString(attorney.directoryPerson.identifiers), 1)
              ]))
            }), 128))
          ]),
          _createElementVNode("p", null, [
            ($setup.hasMultipleAttorneys)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createTextVNode(_toDisplayString($options.mf('attorney_decisions', $setup.poa?.meta)), 1)
                ], 64))
              : _createCommentVNode("", true),
            _createTextVNode(" to be my " + _toDisplayString($options.ifPlural('', 'sole', $setup.primaryAttorneys)) + " " + _toDisplayString($options.plural('attorney', $setup.primaryAttorneys)) + ". ", 1)
          ])
        ]),
        ($setup.hasSecondaries)
          ? (_openBlock(), _createElementBlock("li", _hoisted_3, [
              _createElementVNode("p", null, [
                _cache[12] || (_cache[12] = _createTextVNode(" I APPOINT ")),
                _createElementVNode("strong", null, _toDisplayString($setup.secondaryAttorneys[0].directoryPerson.fullName), 1),
                _createTextVNode(" " + _toDisplayString($setup.secondaryAttorneys[0].directoryPerson.identifiers) + " ", 1),
                ($setup.hasMultipleAttorneys)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createTextVNode(_toDisplayString($options.mf('attorney_decisions', $setup.poa?.meta)), 1)
                    ], 64))
                  : _createCommentVNode("", true),
                _cache[13] || (_cache[13] = _createTextVNode(" to be my attorney in substitution of: ")),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.primaryAttorneys, (attorney, index) => {
                  return (_openBlock(), _createElementBlock(_Fragment, {
                    key: attorney.id
                  }, [
                    _createElementVNode("strong", null, _toDisplayString(attorney.directoryPerson.fullName), 1),
                    _createTextVNode(" " + _toDisplayString(index === $setup.primaryAttorneys?.length - 1 ? '' : ' or '), 1)
                  ], 64))
                }), 128)),
                _cache[14] || (_cache[14] = _createTextVNode(" on the occurrence of the following events or circumstances: "))
              ]),
              _createElementVNode("ol", _hoisted_4, [
                _createElementVNode("li", null, _toDisplayString($options.ifPlural('either of my attorneys are', 'my attorney is', $setup.primaryAttorneys)) + " unable to act due to death or lack of capacity, or ", 1),
                _createElementVNode("li", null, _toDisplayString($options.ifPlural('either attorney', 'my attorney', $setup.primaryAttorneys)) + " is temporarily unable to act due to illness or personal commitments. ", 1)
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("section", null, [
      _cache[17] || (_cache[17] = _createElementVNode("h3", null, "AUTHORISATION", -1)),
      _createElementVNode("ol", {
        start: 3 + $setup.hasSecondaries
      }, [
        _createElementVNode("li", null, [
          _createElementVNode("p", null, " I AUTHORISE my " + _toDisplayString($options.plural('attorney', $setup.primaryAttorneys)) + " to do on my behalf anything that I can lawfully do by an attorney. ", 1),
          _cache[16] || (_cache[16] = _createElementVNode("p", null, [
            _createElementVNode("em", null, "Note: An attorney cannot be authorised to make personal, lifestyle or medical treatment decisions.")
          ], -1))
        ])
      ], 8, _hoisted_5)
    ]),
    _createElementVNode("section", null, [
      _cache[21] || (_cache[21] = _createElementVNode("h3", null, "CONDITIONS, LIMITATIONS AND RESTRICTIONS", -1)),
      _createElementVNode("ol", {
        start: 4 + $setup.hasSecondaries
      }, [
        _createElementVNode("li", null, [
          _cache[18] || (_cache[18] = _createElementVNode("p", null, " The authority of my attorney(s) is subject to the following CONDITIONS or RESTRICTIONS: ", -1)),
          _createElementVNode("ul", null, [
            ($setup.hasLimitations)
              ? (_openBlock(), _createElementBlock("li", _hoisted_7, _toDisplayString($options.mf('limitations', $setup.poa?.meta)), 1))
              : (_openBlock(), _createElementBlock("li", _hoisted_8, "NIL"))
          ])
        ]),
        _createElementVNode("li", null, [
          _cache[20] || (_cache[20] = _createElementVNode("p", null, " My attorney may provide for the reasonable living and medical expenses for the following people: ", -1)),
          ($setup.hasInclusions)
            ? (_openBlock(), _createElementBlock("ol", _hoisted_9, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.inclusions, (inclusion) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: inclusion.id
                  }, [
                    _createElementVNode("strong", null, _toDisplayString(inclusion.directoryPerson.fullName), 1),
                    _createTextVNode(" " + _toDisplayString(inclusion.directoryPerson.identifiers), 1)
                  ]))
                }), 128))
              ]))
            : (_openBlock(), _createElementBlock("ul", _hoisted_10, _cache[19] || (_cache[19] = [
                _createElementVNode("li", null, "NIL", -1)
              ])))
        ])
      ], 8, _hoisted_6)
    ]),
    _cache[46] || (_cache[46] = _createElementVNode("section", null, [
      _createElementVNode("h3", null, "COMMENCEMENT"),
      _createElementVNode("p", null, [
        _createTextVNode(" I DECLARE that this power of attorney will be in force only during any period when a declaration by the State Administrative Tribunal that I do not have legal capacity is in force under section 106 of the "),
        _createElementVNode("em", null, "Guardianship and Administration Act 1990"),
        _createTextVNode(". ")
      ])
    ], -1)),
    _createElementVNode("section", null, [
      _cache[45] || (_cache[45] = _createElementVNode("h3", null, "EXECUTION", -1)),
      _createElementVNode("ol", {
        start: 6 + $setup.hasSecondaries
      }, [
        _createElementVNode("li", null, [
          _cache[25] || (_cache[25] = _createElementVNode("h4", null, "SIGNED AS A DEED by:", -1)),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("div", _hoisted_14, _toDisplayString($setup.ownerPerson?.fullName), 1),
              _cache[22] || (_cache[22] = _createElementVNode("div", { class: "sign" }, "Name", -1))
            ]),
            _cache[23] || (_cache[23] = _createElementVNode("div", { class: "sign" }, "Signature", -1)),
            _cache[24] || (_cache[24] = _createElementVNode("div", { class: "sign" }, "Date", -1))
          ])
        ]),
        _cache[44] || (_cache[44] = _createStaticVNode("<li><h4>WITNESSED by:</h4><div class=\"sign-container\"><div class=\"sign\">Signature of Witness 1</div><div class=\"sign\">Name of Witness 1</div><div class=\"sign\">Address of Witness 1</div><div class=\"sign\">Occupation of Witness 1</div></div><div class=\"sign-container\"><div class=\"sign\">Signature of Witness 2</div><div class=\"sign\">Name of Witness 2</div><div class=\"sign\">Address of Witness 2</div><div class=\"sign\">Occupation of Witness 2</div></div></li>", 1)),
        _createElementVNode("li", null, [
          _cache[39] || (_cache[39] = _createElementVNode("h4", null, "ACCEPTANCE OF ENDURING POWER OF ATTORNEY", -1)),
          _cache[40] || (_cache[40] = _createElementVNode("p", null, " The person(s) or agency nominated as attorney(s) is/are required to indicate their willingness to accept the power vested in them under this enduring power of attorney and the legal obligations which go with that power. The attorney(s) must sign the Statement of Acceptance in the space provided as soon as possible after the document conferring the authority has been completed. ", -1)),
          _createElementVNode("p", null, [
            _createElementVNode("strong", null, _toDisplayString($options.ifPlural('We', 'I', $setup.primaryAttorneys)), 1),
            _cache[27] || (_cache[27] = _createTextVNode(", ")),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.primaryAttorneys, (attorney) => {
              return (_openBlock(), _createElementBlock(_Fragment, {
                key: attorney.id
              }, [
                _createElementVNode("strong", null, _toDisplayString(attorney.directoryPerson.fullName), 1),
                _cache[26] || (_cache[26] = _createTextVNode(", "))
              ], 64))
            }), 128)),
            _createTextVNode(" the " + _toDisplayString($options.plural('person', $setup.primaryAttorneys)) + " appointed to be the ", 1),
            _createElementVNode("strong", null, _toDisplayString($options.plural('attorney', $setup.primaryAttorneys)), 1),
            _cache[28] || (_cache[28] = _createTextVNode(" under paragraph 2 of the instrument on which this acceptance is endorsed [or to which this acceptance is annexed]. "))
          ]),
          ($setup.hasSecondaries)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _cache[32] || (_cache[32] = _createElementVNode("h4", null, "AND", -1)),
                _createElementVNode("p", null, [
                  _createElementVNode("strong", null, "I, " + _toDisplayString($setup.secondaryAttorneys[0].directoryPerson.fullName), 1),
                  _cache[29] || (_cache[29] = _createTextVNode(", the person appointed to be the ")),
                  _cache[30] || (_cache[30] = _createElementVNode("strong", null, "substitute attorney", -1)),
                  _cache[31] || (_cache[31] = _createTextVNode(" under paragraph 3 of the instrument on which this acceptance is endorsed [or to which this acceptance is annexed]. "))
                ])
              ], 64))
            : _createCommentVNode("", true),
          _cache[41] || (_cache[41] = _createElementVNode("p", null, "Accept the appointment and acknowledge:", -1)),
          _cache[42] || (_cache[42] = _createElementVNode("ul", null, [
            _createElementVNode("li", null, "that the power of attorney is an enduring power of attorney"),
            _createElementVNode("li", null, [
              _createTextVNode(" that I/we will, by accepting this power of attorney, be subject to the provisions of Part 9 of the "),
              _createElementVNode("em", null, "Guardianship and Administration Act 1990"),
              _createTextVNode("; ")
            ]),
            _createElementVNode("li", null, [
              _createTextVNode(" the enduring power of attorney will be in force only during any period when a declaration by the State Administrative Tribunal that the Donor does not have legal capacity is in force under Section 106 of the "),
              _createElementVNode("em", null, "Guardianship and Administration Act 1990"),
              _createTextVNode(". ")
            ])
          ], -1)),
          _cache[43] || (_cache[43] = _createElementVNode("h4", null, "SIGNED", -1)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.primaryAttorneys, (attorney) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "sign-container",
              key: attorney.id
            }, [
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("div", _hoisted_16, _toDisplayString(attorney.directoryPerson.fullName), 1),
                _cache[33] || (_cache[33] = _createElementVNode("div", { class: "sign" }, "Attorney appointed under paragraph 2", -1))
              ]),
              _cache[34] || (_cache[34] = _createElementVNode("div", { class: "sign" }, "Signature", -1)),
              _cache[35] || (_cache[35] = _createElementVNode("div", { class: "sign" }, "Date", -1))
            ]))
          }), 128)),
          ($setup.hasSecondaries)
            ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                _createElementVNode("div", _hoisted_18, [
                  _createElementVNode("div", _hoisted_19, _toDisplayString($setup.secondaryAttorneys[0].directoryPerson.fullName), 1),
                  _cache[36] || (_cache[36] = _createElementVNode("div", { class: "sign" }, "Substitute attorney appointed under paragraph 3", -1))
                ]),
                _cache[37] || (_cache[37] = _createElementVNode("div", { class: "sign" }, "Signature", -1)),
                _cache[38] || (_cache[38] = _createElementVNode("div", { class: "sign" }, "Date", -1))
              ]))
            : _createCommentVNode("", true)
        ])
      ], 8, _hoisted_11)
    ])
  ]))
}