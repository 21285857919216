<template>
  <article>
    <div class="text-center">
      <h4>Form 2</h4>
      <h2>Revocation of appointment of enduring guardian</h2>
    </div>
    <p>
      I, <strong>{{ ownerPerson?.fullName }}</strong> {{ ownerPerson?.fullAddress }}, hereby revoke
      the appointment of each of the following people as my enduring guardian:
    </p>
    <div class="sign-container">
      <div class="sign-prefixed">
        <div class="sign-prefix">&middot;</div>
        <div class="sign">&nbsp;</div>
      </div>
    </div>
    <div class="sign-container">
      <div class="sign-prefixed">
        <div class="sign-prefix">&middot;</div>
        <div class="sign">&nbsp;</div>
      </div>
    </div>
    <div class="sign-container">
      <div class="sign-prefixed">
        <div class="sign-prefix">&middot;</div>
        <div class="sign">&nbsp;</div>
      </div>
    </div>
    <div class="sign-container">
      <div class="sign-prefixed">
        <div class="sign-prefix">&middot;</div>
        <div class="sign">&nbsp;</div>
      </div>
    </div>
    <p>
      I understand that this revocation will not be effective unless the enduring guardian is or has
      been given written notice of the revocation.
    </p>
    <div class="sign-container">
      <div class="sign">Signature</div>
      <div class="sign">Date</div>
    </div>
    <h3>Certificate of witness</h3>
    <p>
      I,
      <span class="wide-underline"></span> of <span class="wide-underline"></span>,
      <span class="wide-underline"></span> certify that
      <strong>{{ ownerPerson?.fullName }}</strong> appeared to understand the effect of this
      instrument and in my presence executed the instrument voluntarily.
    </p>
    <div class="sign-container">
      <div class="sign">Signature</div>
      <div class="sign">Date</div>
    </div>
    <p><strong>Tick applicable:</strong></p>
    <ul class="checkboxes">
      <li>Australian legal practitioner</li>
      <li>Registrar of the Local Court</li>
      <li>overseas-registered foreign lawyer</li>
      <li>justice of the peace</li>
      <li>approved employee of NSW Trustee and Guardian or the Office of the Public Guardian</li>
    </ul>
  </article>
</template>

<script lang="ts">
import { inject } from 'vue';
import { ownerPersonKey } from '@/types/commonTypes';

export default {
  setup() {
    const ownerPerson = inject(ownerPersonKey);

    return { ownerPerson };
  },
};
</script>
