<template>
  <article>
    <section>
      <h2>
        APPOINTMENT OF MEDICAL TREATMENT DECISION
        {{ plural('MAKER', primaryAttorneys).toUpperCase() }}
      </h2>
      <p>
        made under the
        <em>Medical Treatment Planning and Decisions Act 2016</em> (Vic.)
      </p>
      <p>
        Your medical treatment decision maker has legal authority to make medical treatment
        decisions on your behalf, if you do not have decision-making capacity to make the decision.
        Your medical treatment decision maker is the first person you list below who is reasonably
        available, and willing and able to make the decision. Only adults can appoint a medical
        treatment decision maker.
      </p>
    </section>
    <section>
      <h3>PART 1: Personal details</h3>
      <ul>
        <li>
          Your full name:
          <strong>{{ ownerPerson?.fullName }}</strong>
        </li>
        <li>Date of birth: {{ ownerPerson?.dateOfBirthFormatted }}</li>
        <li>Address: {{ ownerPerson?.fullAddress.replace('of ', '') }}</li>
        <li v-if="ownerPerson?.defaultPhoneNumber">
          Phone number: {{ ownerPerson?.defaultPhoneNumber }}
        </li>
      </ul>
    </section>
    <section>
      <h3>PART 2: Medical treatment decision maker details</h3>
      <p>
        I <strong>revoke</strong> any other previous appointment of a medical treatment decision
        maker however described.
      </p>
      <p>
        I <strong>appoint</strong> as my medical treatment
        {{ plural('decision-maker', attorneys) }}:
      </p>
      <h4>Medical treatment decision maker 1</h4>
      <ul>
        <li>
          Full name:
          <strong>{{ primaryAttorneys[0].directoryPerson.fullName }}</strong>
        </li>
        <li>
          Date of birth:
          {{ primaryAttorneys[0].directoryPerson.dateOfBirthFormatted }}
        </li>
        <li>
          Address:
          {{ primaryAttorneys[0].directoryPerson.fullAddress.replace('of ', '') }}
        </li>
      </ul>
      <template v-if="hasSecondaries">
        <h4>Medical treatment decision maker 2</h4>
        <ul>
          <li>
            Full name:
            <strong>{{ secondaryAttorneys[0].directoryPerson.fullName }}</strong>
          </li>
          <li>
            Date of birth:
            {{ secondaryAttorneys[0].directoryPerson.dateOfBirthFormatted }}
          </li>
          <li>
            Address:
            {{ secondaryAttorneys[0].directoryPerson.fullAddress.replace('of ', '') }}
          </li>
        </ul>
      </template>
    </section>
    <section>
      <h3>PART 3: Any limitations or conditions</h3>
      <p>
        {{ hasLimitations ? mf('limitations', poa?.meta) : 'N/A' }}
      </p>
      <p>
        I would {{ hasLimitations ? 'also' : '' }} like my appointed decision maker(s) to consider
        the following preferences:
      </p>
      <p>{{ hasPreferences ? mf('preferences', poa?.meta) : 'N/A' }}</p>
    </section>
    <section>
      <h3>PART 4: Witnessing</h3>
      <div class="sign-container">
        <div class="sign-prefixed">
          <div class="sign-prefix">
            {{ ownerPerson?.fullName }}
          </div>
          <div class="sign">Name</div>
        </div>
        <div class="sign">Signature</div>
        <div class="sign">Date</div>
      </div>
      <h4>WITNESS CERTIFICATES</h4>
      <p>Each witness certifies that:</p>
      <ul>
        <li>
          at the time of signing the document, the person making this appointment appears to have
          decision-making capacity and appears to understand the nature and consequences of making
          the appointment and revoking any previous appointment; and
        </li>
        <li>
          at the time of signing the document, the person making this appointment appeared to sign
          the document freely and voluntarily; and
        </li>
        <li>
          the person signed the document in my presence and in the presence of a second witness; and
        </li>
        <li>I am not the person’s medical treatment decision maker under this appointment.</li>
      </ul>
      <h4>Witness 1 – Authorised witness:</h4>
      <div class="sign-container">
        <div class="sign">Name of authorised witness</div>
        <div class="sign">Qualification of authorised witness</div>
        <div class="sign">Signature of authorised witness</div>
        <div class="sign">Date</div>
      </div>
      <h4>Witness 2 – Adult witness:</h4>
      <div class="sign-container">
        <div class="sign">Name of adult witness</div>
        <div class="sign">Signature of adult witness</div>
        <div class="sign">Date</div>
      </div>
    </section>
    <section>
      <h3>PART 5: Interpreter statement</h3>
      <p>N/A</p>
    </section>
    <section>
      <h3>PART 6: Statement of acceptance</h3>
      <h4>Medical treatment decision maker 1</h4>
      <p>I accept my appointment as medical treatment decision maker and state that:</p>
      <ul>
        <li>I understand the obligations of an appointed medical treatment decision maker; and</li>
        <li>
          I undertake to act in accordance with any known preferences and values of the person
          making the appointment; and
        </li>
        <li>
          I undertake to promote the personal and social wellbeing of the person making the
          appointment, having regard to the need to respect the person’s individuality; and
        </li>
        <li>
          I have read and understand any advance care directive that the person has given before, or
          at the same time as, this appointment.
        </li>
      </ul>
      <div class="sign-container">
        <div class="sign-prefixed">
          <div class="sign-prefix">
            {{ primaryAttorneys[0].directoryPerson.fullName }}
          </div>
          <div class="sign">Name of medical treatment decision maker</div>
        </div>
        <div class="sign">Signature</div>
        <div class="sign">Date</div>
      </div>
    </section>
    <section>
      <h4>Witnessed by an adult</h4>
      <p>I certify that I witnessed the signing of this statement of acceptance.</p>
      <div class="sign-container">
        <div class="sign">Name of adult witness</div>
        <div class="sign">Signature of adult witness</div>
        <div class="sign">Date</div>
      </div>
    </section>
    <section v-if="hasSecondaries">
      <h4>Medical treatment decision maker 2</h4>
      <p>I accept my appointment as medical treatment decision maker and state that:</p>
      <ul>
        <li>I understand the obligations of an appointed medical treatment decision maker; and</li>
        <li>
          I undertake to act in accordance with any known preferences and values of the person
          making the appointment; and
        </li>
        <li>
          I undertake to promote the personal and social wellbeing of the person making the
          appointment, having regard to the need to respect the person’s individuality; and
        </li>
        <li>
          I have read and understand any advance care directive that the person has given before, or
          at the same time as, this appointment.
        </li>
      </ul>
      <div class="sign-container">
        <div class="sign-prefixed">
          <div class="sign-prefix">
            {{ secondaryAttorneys[0].directoryPerson.fullName }}
          </div>
          <div class="sign">Name of medical treatment decision maker</div>
        </div>
        <div class="sign">Signature</div>
        <div class="sign">Date</div>
      </div>
      <h4>Witnessed by an adult</h4>
      <p>I certify that I witnessed the signing of this statement of acceptance.</p>
      <div class="sign-container">
        <div class="sign">Name of adult witness</div>
        <div class="sign">Signature of adult witness</div>
        <div class="sign">Date</div>
      </div>
    </section>
  </article>
</template>

<script lang="ts">
import { inject } from 'vue';
import poa from '@/hooks/poa';
import { mf, plural, ifPlural } from '@/utils/format';
import { medicalPoaKey, ownerPersonKey } from '@/types/commonTypes';

export default {
  setup() {
    const ownerPerson = inject(ownerPersonKey);
    const medicalPoa = inject(medicalPoaKey);

    const {
      actJointly,
      attorneys,
      hasInclusions,
      hasLimitations,
      hasPreferences,
      hasMultipleAttorneys,
      inclusions,
      hasSecondaries,
      othersMayAct,
      primaryAttorneys,
      secondaryAttorneys,
    } = poa(medicalPoa?.value);

    return {
      actJointly,
      attorneys,
      hasInclusions,
      hasLimitations,
      hasPreferences,
      hasMultipleAttorneys,
      inclusions,
      hasSecondaries,
      othersMayAct,
      primaryAttorneys,
      secondaryAttorneys,
      poa: medicalPoa,
      ownerPerson,
    };
  },
  methods: {
    mf,
    plural,
    ifPlural,
  },
};
</script>
